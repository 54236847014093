import {Country} from '@hconnect/apiclient'
import {dateFormatter, Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, IconButton} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {DetailsField} from '../../../components/DetailsField/DetailsField'
import {Products} from '../../../components/Products/Products'
import {Announcement} from '../Announcements'

import {Countries, IsActive} from './Announcements.components'

interface DetailsType {
  announcement: Announcement
  t: TFunction
  handleClose: () => void
  handleEdit: (event: React.MouseEvent<HTMLButtonElement>, announcement: Announcement) => void
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>, announcementId: string) => void
  isLoading: boolean
  author?: string
  countries?: Country[]
}
export const AnnouncementDetails: React.FC<DetailsType> = ({
  announcement,
  t,
  countries,
  handleClose,
  handleEdit,
  handleDelete,
  isLoading,
  author
}) => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  return (
    <Box display="flex" flexDirection="column" data-test-id="announcement-details">
      <Box data-test-id="announcement-details-actions" style={{marginBottom: '40px'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {!isMobile && (
            <Typography style={{fontWeight: 600, fontSize: '16px', color: '#54708C'}}>
              {t('announcements.detailsTitle')}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start',
              width: isMobile ? '100%' : 'auto',
              gap: isMobile ? '16px' : 0
            }}
          >
            <Box mr={2}>
              <IconButton
                data-test-id="delete-announcement-button"
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleDelete(event, announcement.messageId)
                }
                style={{boxShadow: 'none'}}
              >
                <DeleteOutlineIcon style={{color: '#016AD4'}} />
              </IconButton>
            </Box>
            <Box mr={2}>
              <IconButton
                data-test-id="edit-announcement-button"
                size="small"
                color="primary"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleEdit(event, announcement)
                }
                style={{boxShadow: 'none'}}
              >
                <ModeEditOutlineIcon style={{color: '#016AD4'}} />
              </IconButton>
            </Box>
            {!isMobile && (
              <Box>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  style={{marginRight: '-24px', boxShadow: 'none'}}
                >
                  <CloseIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box data-test-id="announcement-details-validation" style={{marginBottom: '36px'}}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: '24px'
          }}
        >
          <IsActive
            validFrom={announcement.validFrom}
            validTo={announcement.validTo}
            t={t}
            isLoading={isLoading}
          />
          <Products products={announcement.products} isLoading={isLoading} isDetails={true} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Box data-test-id="announcement-details-created-date">
            <DetailsField
              label={t('announcements.createdDate')}
              value={dateFormatter(announcement.createdOn)}
              gutter
              dataTestId="announcement-details-created-date-field"
              isLoading={isLoading}
            />
          </Box>
          <Box data-test-id="announcement-details-type">
            <DetailsField
              label={t('announcements.type')}
              value={t(`announcements.${announcement.messageType}`)}
              gutter
              dataTestId="announcement-details-type-field"
              isLoading={isLoading}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box data-test-id="announcement-details-author">
            <DetailsField
              label={t('announcements.author')}
              value={author}
              gutter
              dataTestId="announcement-details-author-field"
              isLoading={isLoading}
            />
          </Box>
          <Box data-test-id="announcement-details-scheduled">
            <DetailsField
              label={t('announcements.scheduled')}
              value={`${dateFormatter(announcement.validFrom)} - ${dateFormatter(
                announcement.validTo
              )}`}
              gutter
              dataTestId="announcement-details-type-field"
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
      <Box data-test-id="announcement-details-message">
        <DetailsField
          label={t('announcements.message')}
          value={announcement.message}
          gutter
          dataTestId="announcement-details-message-field"
          isLoading={isLoading}
        />
      </Box>
      <Box data-test-id="announcement-details-countries">
        <DetailsField
          label={t('announcements.countries')}
          gutter
          dataTestId="announcement-details-countries-field"
          isLoading={isLoading}
        >
          {!isLoading && <Countries announcement={announcement} countries={countries} showAll />}
        </DetailsField>
      </Box>
    </Box>
  )
}
