import {TablePagination, TablePaginationProps} from '@mui/material'
import React from 'react'

import {useListViewStyles} from './ListView.styles'

type ListViewPaginationProps = TablePaginationProps

export const ListViewPagination = (props: ListViewPaginationProps) => {
  const {classes} = useListViewStyles()

  const toTablePaginationPage = (p: number): number => p - 1
  const fromTablePaginationPage = (p: number): number => p + 1

  return (
    <TablePagination
      component="div"
      data-test-id="list-view-pagination"
      count={props.count}
      page={toTablePaginationPage(props.page)}
      onPageChange={(event, newPage) => {
        props.onPageChange(event, fromTablePaginationPage(newPage))
      }}
      SelectProps={{
        className: classes.listViewPaginationSelect
      }}
      nextIconButtonProps={{className: classes.listViewPaginationActionIconButton}}
      backIconButtonProps={{className: classes.listViewPaginationActionIconButton}}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      classes={{
        toolbar: classes.listViewPaginationToolbar,
        displayedRows: classes.listViewPaginationDisplayedRows,
        spacer: classes.listViewPaginationSpacer,
        actions: classes.listViewPaginationActions
      }}
    />
  )
}
