import {Attachment} from '@hconnect/common/types'
import {TableColumnType, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {Visibility} from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {Box, IconButton} from '@mui/material'
import React from 'react'

type AttachmentListRowsProps = {
  setShowImagePreview: (file?: File) => void
  files: File[]
  setFiles: (file: File[]) => void
}
export const AttachmentListRows = ({
  setShowImagePreview,
  files,
  setFiles
}: AttachmentListRowsProps) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const rowsPool: {[key: string]: TableColumnType} = {
    fileName: {
      field: 'fileName',
      headerName: t('createMessage.form.attachments.fileName'),
      headerAlign: 'left',
      renderCell: (attachment: File) => (
        <Box style={{wordBreak: 'break-word'}}>{attachment.name}</Box>
      )
    },
    fileSize: {
      field: 'fileSize',
      headerName: t('createMessage.form.attachments.fileSize'),
      headerAlign: 'left',
      renderCell: (attachment: File) => {
        return (
          <Box>
            {(Math.round((attachment.size / (1024 * 1024) + Number.EPSILON) * 100) / 100).toFixed(
              2
            )}
            MB
          </Box>
        )
      }
    },
    action: {
      field: 'action',
      headerName: isMobile ? '' : t('createMessage.form.attachments.action'),
      headerAlign: 'left',
      width: '5%',
      renderCell: (attachment: File) => {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'start',
              alignItems: 'center',
              marginLeft: '-16px'
            }}
          >
            <IconButton
              style={{boxShadow: 'none'}}
              onClick={() => setFiles(files.filter((file) => file.name !== attachment.name))}
              data-test-id={`action-delete-${attachment.name}`}
            >
              <DeleteOutlineIcon style={{color: '#016AD4'}} />
            </IconButton>
            {attachment.type.includes('image') && (
              <IconButton
                style={{boxShadow: 'none'}}
                onClick={() => setShowImagePreview(attachment)}
                data-test-id={`action-preview-${attachment.name}`}
              >
                <Visibility style={{color: '#016AD4'}} />
              </IconButton>
            )}
          </Box>
        )
      }
    },
    expander: {
      field: 'expander',
      width: '100%',
      noColumnSelection: true,
      renderCell: () => {
        return <Box style={{width: '100%'}} />
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.fileName,
    rowsPool.fileSize,
    rowsPool.action
  ]
  return rowOrder(rowsPool)
}
