import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  container: {
    color: 'white'
  },
  addIcon: {
    marginRight: theme.spacing(1)
  },
  chip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    margin: '4px 8px 0 0'
  },
  mobileNumberContainer: {
    marginTop: '4px'
  },
  issue: {
    color: '#ffaf00',
    textTransform: 'uppercase'
  },
  rows: {
    display: 'flex'
  },
  issuesIcon: {
    marginLeft: 5
  },
  createButton: {
    marginBottom: '8px',
    background: 'rgba(255, 255, 255, 0.05)'
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  hourGlassActive: {
    backgroundColor: '#009D93',
    padding: 3,
    border: 'none',
    borderRadius: '50%',
    color: theme.palette.common.white,
    display: 'grid',
    placeItems: 'center'
  },
  hourGlassExpired: {
    backgroundColor: '#54708C',
    padding: 3,
    border: 'none',
    borderRadius: '50%',
    color: theme.palette.common.white,
    display: 'grid',
    placeItems: 'center'
  },
  messageOverflow: {
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  tags: {
    borderRadius: '14px'
  },
  tagMod1: {
    color: '#FFFFFF',
    backgroundColor: '#0F89FF'
  }
}))
