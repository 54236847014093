import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  rolesContainer: {
    margin: `${theme.spacing(2)}px -${theme.spacing(1)}px`
  },
  snackbarContent: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background.default
  },
  titleBar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  backButton: {
    flex: '1 0 100%',
    display: 'flex',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 'bold'
  },
  sectionHeader: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    alignItems: 'flex-end'
  }
}))
