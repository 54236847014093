import {HttpError} from '@hconnect/common/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'

import {api} from '../../../App.store'

export type CreateFeatureParams = {
  name: string
  description: string
  tags: string[]
  constraints: {dataScope: string; type: string; enabled: boolean}[]
}
export const useFeaturesList = (
  setDeleteFeatureDialogData?: (deleteFeatureDialogData?: string) => void
) => {
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {featureName} = useParams<{featureName?: string}>()

  const formatErrorMessage = (errorMessage?: string, edit?: boolean) => {
    if (errorMessage?.includes('exists')) {
      return t('features.form.errorAlreadyExists')
    }

    if (errorMessage?.includes('Invalid scopes')) {
      return errorMessage
    }

    return t(`features.form.${edit ? 'errorUpdateMsg' : 'errorCreateMsg'}`)
  }

  const {mutate: createFeature, isLoading: isCreateFeatureLoading} = useMutation(
    async (params?: CreateFeatureParams) => {
      const response: AxiosResponse = await api.post('/configurations/features', [{...params}])

      return response.data
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('features.form.successCreateMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success',
          id: 'success-feature'
        })
        history.goBack()
      },
      onError: (error: AxiosError<HttpError>) => {
        enqueueSnackbar(formatErrorMessage(error.response?.data.detail), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  const {mutate: deleteFeature, isLoading: isDeleteFeatureLoading} = useMutation(
    async (featureName?: string) => {
      const response: AxiosResponse = await api.delete(`/features/${featureName}`)

      return response.data
    },
    {
      onSuccess: async () => {
        enqueueSnackbar(t('features.form.successDeleteMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success'
        })
        setDeleteFeatureDialogData && setDeleteFeatureDialogData(undefined)
        featureName
          ? history.goBack()
          : await queryClient.invalidateQueries(['features/paginatedFeatures'])
      },
      onError: () => {
        enqueueSnackbar(t('features.form.errorDeleteMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  const {mutate: updateFeature, isLoading: isUpdateFeatureLoading} = useMutation(
    async (params?: CreateFeatureParams) => {
      const response: AxiosResponse = await api.put('configurations/features', [{...params}])

      return response.data
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('features.form.successUpdateMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success'
        })
        history.goBack()
      },
      onError: (error: AxiosError<HttpError>) => {
        enqueueSnackbar(formatErrorMessage(error.response?.data.detail, true), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  return {
    createFeature,
    deleteFeature,
    updateFeature,
    isLoading: isDeleteFeatureLoading || isCreateFeatureLoading || isUpdateFeatureLoading
  }
}
