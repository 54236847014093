import {QueryFunction, useQuery} from '@tanstack/react-query'
import fileDownload from 'js-file-download'

import {api} from '../../../../App.store'

type MessageAttachmentsQueryKey = [string, {id: number}]

type AttachmentResponseParams = {
  fileContents: string
  contentType: string
  fileDownloadName: string
  enableRangeProcessing: boolean
}

const messageAttachmentsFetcher: QueryFunction<AttachmentResponseParams> = async ({queryKey}) => {
  const [base, {id}] = queryKey as MessageAttachmentsQueryKey
  try {
    const response = await api.get<AttachmentResponseParams>(`/notifications/${base}/${id}/file`)
    const byteCharacters = atob(response.data.fileContents)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {type: response.data.contentType})

    fileDownload(blob, response.data.fileDownloadName)
    return response.data
  } catch (e) {
    throw new Error('Error during fetching attachment')
  }
}
export const useAttachments = (id?: number) => {
  return useQuery(['message', {id}], messageAttachmentsFetcher, {enabled: !!id})
}
