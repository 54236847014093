import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../App.store'
import {Audience} from '../../Messages/Audiences/Audiences'
import {AudiencesFilter} from '../../Messages/components/AudienceList'

type AudienceListParams = {
  skip?: number
  limit?: number
  name?: string
  createdOn?: string
  rowsPerPage?: number
  page?: number
  sortColumn?: string
  sortDirection?: string
}

interface AudienceApiResponse {
  total: number
  audiences: Audience[]
}

type AudienceListQueryKey = [string, {filter: AudiencesFilter}]

const audienceListFetcher: QueryFunction<AudienceApiResponse> = async ({queryKey}) => {
  const [, {filter}] = queryKey as AudienceListQueryKey

  const params: AudienceListParams = {
    rowsPerPage: parseInt(filter.rowsPerPage.toString()),
    page: parseInt(filter.page.toString()),
    sortColumn: filter.sortedByKey,
    sortDirection: filter.sortedByDirection,
    skip: (filter.page - 1) * filter.rowsPerPage,
    limit: filter.rowsPerPage,
    name: filter.name,
    createdOn: filter.createdOn
  }

  try {
    const response = await api.get<Audience[]>('/notifications/audience/paginatedAudiences', {
      params
    })

    return {
      total: response.headers['x-total-count'] ? +response.headers['x-total-count'] : 0,
      audiences: response.data
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
export const useAudienceListFetcher = (filter: AudiencesFilter) => {
  return useQuery<AudienceApiResponse, AxiosError, AudienceApiResponse, AudienceListQueryKey>(
    ['/notifications/audience/paginatedAudiences', {filter}],
    audienceListFetcher,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )
}
