import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  paper: {
    maxWidth: '550px !important',
    width: '550px',
    maxHeight: '550px',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  confirmButton: {
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    background: '#016AD4',
    color: '#FFFFFF',
    placeSelf: 'end',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px'
  }
}))
