import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(8)
  },
  title: {
    marginLeft: 33
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    color: theme.palette.grey[500]
  },
  stepLine: {
    borderLeftWidth: 2
  },
  stepLabel: {
    color: theme.palette.text.primary
  },
  stepConnector: {
    borderLeftWidth: 2,
    height: theme.spacing(4)
  },
  sectionHeader: {
    marginLeft: 2,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`
  },
  stepIcon: {
    fontSize: 24
  },
  checkbox: {
    boxShadow: 'none'
  },
  checkboxLabel: {
    fontWeight: 'normal',
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2)
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  errorMessage: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.dark,
    flex: 1
  }
}))
