import {Country} from '@hconnect/apiclient'
import {Feature} from '@hconnect/common/components/FeaturesCheck'
import {ResponsiveTable, Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Chip, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DetailsField} from '../../components/DetailsField/DetailsField'

import {FeaturesCollapsedListRows} from './FeaturesCollapsedListRows'

interface FeatureDetailsProps {
  feature: Feature
  countries?: Country[]
  handleClose: () => void
  handleEdit: (feature: Feature) => void
  handleDelete: (featureName: string) => void
  canEditFeatures: boolean
}
// eslint-disable-next-line complexity
export const FeatureDetails = ({
  feature,
  countries,
  handleEdit,
  handleDelete,
  handleClose,
  canEditFeatures
}: FeatureDetailsProps) => {
  const screenSizes = useBreakPoints()
  const {t} = useTranslation()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const formatType = () => {
    return (
      feature.constraints
        ?.map((constraint) => {
          return constraint.type || '-'
        })
        .filter((type, index, arr) => arr.indexOf(type) === index) || '-'
    )
  }

  const formatTags = () => {
    return feature.tags.map((tag, index) => (
      <Chip
        key={index}
        label={tag}
        style={{
          background: '#0F89FF',
          color: '#FFFFFF',
          margin: '4px 8px 0 0'
        }}
        size="small"
      />
    ))
  }

  return (
    <Box display="flex" flexDirection="column" data-test-id="feature-details">
      <Box data-test-id="feature-details-actions" style={{marginBottom: '40px'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {!isMobile && (
            <Typography style={{fontWeight: 600, fontSize: '16px', color: '#54708C'}}>
              {t('features.details.title')}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start',
              width: isMobile ? '100%' : 'auto',
              gap: isMobile ? '16px' : 0
            }}
          >
            {!isMobile && canEditFeatures && (
              <Box mr={2}>
                <IconButton
                  data-test-id="delete-feature-details-button"
                  size="small"
                  onClick={() => handleDelete(feature.name)}
                  style={{boxShadow: 'none'}}
                >
                  <DeleteOutlineIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
            {!isMobile && canEditFeatures && (
              <Box mr={2}>
                <IconButton
                  data-test-id="edit-feature-details-button"
                  size="small"
                  color="primary"
                  onClick={() => handleEdit(feature)}
                  style={{boxShadow: 'none'}}
                >
                  <ModeEditOutlineIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
            {!isMobile && (
              <Box>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  style={{boxShadow: 'none'}}
                  data-test-id="close-feature-details-button"
                >
                  <CloseIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {!isMobile && (
          <>
            <Box style={{width: 'calc(50% - 12px)'}}>
              <DetailsField
                label={t('features.name')}
                value={feature.name || '-'}
                gutter
                dataTestId="feature-details-name-field"
              />
            </Box>
            <Box style={{width: 'calc(50% - 12px)'}}>
              <DetailsField
                label={t('features.description')}
                value={feature.description || '-'}
                gutter
                dataTestId="feature-details-email-field"
              />
            </Box>
            <Box style={{width: 'calc(50% - 12px)'}}>
              <DetailsField
                label={t('features.type')}
                value={formatType().toString() || '-'}
                gutter
                dataTestId="feature-details-type-field"
              />
            </Box>
            <Box style={{width: 'calc(50% - 12px)'}}>
              <DetailsField
                label={t('features.tags')}
                value={formatTags() || '-'}
                gutter
                dataTestId="feature-details-tags-field"
              />
            </Box>
          </>
        )}
        <Box style={{width: '100%'}}>
          <DetailsField
            label={t('features.constraints')}
            value={
              feature?.constraints && feature?.constraints.length > 0 ? (
                <ResponsiveTable
                  keyField="constraints"
                  columns={FeaturesCollapsedListRows({countries, t})}
                  rows={feature?.constraints}
                  onSort={() => {}}
                  loading={false}
                  isMobile={isMobile}
                />
              ) : (
                '-'
              )
            }
            gutter
            dataTestId="feature-details-constraints-field"
          />
        </Box>
      </Box>
    </Box>
  )
}
