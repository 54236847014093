import {Typography} from '@hconnect/uikit'
import {Box, CircularProgress} from '@mui/material'
import React from 'react'

import {useOrgUnit} from '../../../hooks/useOrgUnit'

type OrgUnitProps = {
  isCloneable: boolean
  orgUnitId: string
  countryId: string
  businessLine: string
}
export const OrgUnit = ({isCloneable, orgUnitId, countryId, businessLine}: OrgUnitProps) => {
  const {data, isLoading} = useOrgUnit(countryId, businessLine, false)

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography style={{opacity: isCloneable ? 1 : 0.5, textAlign: 'left'}}>
        {data?.find((orgUnit) => orgUnit.key === orgUnitId)?.label}
      </Typography>
    </Box>
  )
}
