import {useMutation} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../../../App.store'
import {CreateMessageFormData} from '../CreateMessage.form'

export type CreateMessageParams = {
  subject: string
  type: 'Email' | 'sms'
  body: string
  audienceIds: number[]
  attachments: File[]
}
export const useCreateMessage = (
  setShowConfirmationDialog: (data?: CreateMessageFormData) => void
) => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()
  const history = useHistory()

  const {mutate: createMessage, isLoading: isCreateMessageLoading} = useMutation(
    async (params: CreateMessageParams) => {
      const formData = new FormData()
      formData.append('subject', params.subject)
      formData.append('type', params.type)
      formData.append('body', params.body)
      params.audienceIds.forEach((audienceId) => {
        formData.append('audienceIds', JSON.stringify(audienceId))
      })
      params.attachments.forEach((attachment) => {
        formData.append('attachments', attachment)
      })

      const response: AxiosResponse = await api.post('/notifications/message', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })

      return response.data
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('createMessage.form.successCreateMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success',
          id: 'success-feature'
        })
        history.push('/messages')
      },
      onError: (error: AxiosError<{detail: string}>) => {
        enqueueSnackbar(
          error.response?.status === 404
            ? error.response?.data.detail
            : t('createMessage.form.errorCreateMsg'),
          {
            anchorOrigin: {vertical: 'top', horizontal: 'center'},
            variant: 'error'
          }
        )
      }
    }
  )

  const {mutate: createTestMessage, isLoading: isCreateTestMessageLoading} = useMutation(
    async (params: CreateMessageParams) => {
      const formData = new FormData()
      formData.append('subject', params.subject)
      formData.append('type', params.type)
      formData.append('body', params.body)
      params.audienceIds.forEach((audienceId) => {
        formData.append('audienceIds', JSON.stringify(audienceId))
      })
      params.attachments.forEach((attachment) => {
        formData.append('attachments', attachment)
      })

      const response: AxiosResponse = await api.post(
        '/notifications/message/sendtestmessage',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )

      return response.data
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('createMessage.form.successCreateTestMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success',
          id: 'success-feature'
        })
        setShowConfirmationDialog(undefined)
      },
      onError: (error: AxiosError<{detail: string}>) => {
        enqueueSnackbar(
          error.response?.status === 404
            ? error.response?.data.detail
            : t('createMessage.form.errorCreateMsg'),
          {
            anchorOrigin: {vertical: 'top', horizontal: 'center'},
            variant: 'error'
          }
        )
      }
    }
  )

  return {
    createMessage,
    createTestMessage,
    isCreateMessageLoading: isCreateMessageLoading || isCreateTestMessageLoading
  }
}
