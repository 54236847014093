import {Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Button, IconButton, Link, Skeleton} from '@mui/material'
import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {DetailsField} from '../../../components/DetailsField/DetailsField'
import {Products} from '../../../components/Products/Products'
import {RTEInput} from '../components/RTEInput'

import {useAttachments} from './hooks/useAttachments'
import {useMessageDetails} from './hooks/useMessageDetails'
import {Message} from './hooks/useMessages'
import {useStyles} from './MessageListDetails.styles'

type MessageListDetailsProps = {
  messageDetails: Message
  setMessageDetails: (message?: Message) => void
}
// eslint-disable-next-line complexity
export const MessageListDetails = ({
  messageDetails,
  setMessageDetails
}: MessageListDetailsProps) => {
  const screenSizes = useBreakPoints()
  const {t} = useTranslation()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {classes} = useStyles()
  const history = useHistory()
  const [attachmentId, setAttachmentId] = useState<number | undefined>()
  const {data, isLoading, refetch} = useMessageDetails(messageDetails.id)
  const {isFetching: isLoadingAttachment, data: attachmentData} = useAttachments(attachmentId)

  useEffect(() => {
    if (messageDetails.id) {
      void refetch()
    }
  }, [messageDetails])

  useEffect(() => {
    if (attachmentData) {
      setAttachmentId(undefined)
    }
  }, [attachmentData])

  return (
    <Box
      data-test-id="message-details"
      style={{display: 'flex', flexDirection: 'column', height: '100%'}}
    >
      <Box data-test-id="message-details-actions" style={{marginBottom: '40px'}}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {!isMobile && messageDetails && (
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '16px',
                color: '#54708C',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {messageDetails.subject}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start',
              width: isMobile ? '100%' : 'auto',
              gap: isMobile ? '16px' : 0
            }}
          >
            {!isMobile && (
              <Box>
                <IconButton
                  size="small"
                  onClick={() => setMessageDetails(undefined)}
                  style={{boxShadow: 'none'}}
                  data-test-id="close-message-details-button"
                >
                  <CloseIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Box style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
          {messageDetails && (
            <DetailsField
              label={t('messages.details.messageContent')}
              dataTestId="message-details-messageContent-field"
            >
              <Box style={{overflowY: 'auto', maxHeight: '200px'}}>
                {messageDetails.type.toLowerCase() === 'sms' ? (
                  messageDetails.body
                ) : (
                  <RTEInput readOnly={true} value={messageDetails.body} />
                )}
              </Box>
            </DetailsField>
          )}
          {messageDetails && (
            <DetailsField
              label={t('messages.details.selectedRecipients')}
              dataTestId="message-details-selectedRecipients-field"
            >
              {isLoading ? (
                <Skeleton width={60} height={30} />
              ) : data?.recipientsNames ? (
                <Products products={data.recipientsNames} columnList={true} />
              ) : (
                '-'
              )}
            </DetailsField>
          )}
          {messageDetails && (
            <DetailsField
              label={t('messages.details.numberOfRecipients')}
              dataTestId="message-details-numberOfRecipients-field"
            >
              {isLoading ? <Skeleton width={60} height={30} /> : data?.recipientCount || '-'}
            </DetailsField>
          )}
          {messageDetails && (
            <DetailsField
              label={t('messages.details.sent')}
              dataTestId="message-details-sent-field"
            >
              {moment(messageDetails.sentDate).format('DD/MM/YYYY | HH:MM') + 'h'}
            </DetailsField>
          )}
          {messageDetails && (
            <DetailsField
              label={t('messages.details.attachments')}
              dataTestId="message-details-attachments-field"
            >
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                {messageDetails.attachments?.length
                  ? messageDetails.attachments?.map((attachment) =>
                      isLoadingAttachment && attachment.attachmentId === attachmentId ? (
                        <Skeleton key={attachment.attachmentId} width={80} height={30} />
                      ) : (
                        <Link
                          key={attachment.attachmentId}
                          onClick={() => setAttachmentId(attachment.attachmentId)}
                        >
                          {attachment.fileName}
                        </Link>
                      )
                    )
                  : '-'}
              </Box>
            </DetailsField>
          )}
        </Box>
        <Box style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
          {messageDetails && (
            <Button
              variant="contained"
              onClick={() => {
                history.push('/messages/create', {
                  subject: messageDetails.subject,
                  messageContent: messageDetails.body,
                  selectedAudiencesIds: messageDetails.audiences.map((audience) => audience.id),
                  redirect: 'messages'
                })
              }}
              data-test-id="reuse-message-button"
              className={classes.reuseButton}
            >
              {t('messages.details.reuseMessage')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
