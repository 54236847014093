import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  dialog: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIconButton: {
    border: 'none',
    boxShadow: 'none',
    color: '#00274d !important'
  }
}))
