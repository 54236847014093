import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  container: {
    color: 'white'
  },
  addIcon: {
    marginRight: theme.spacing(1)
  },
  chip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    margin: '4px 8px 0 0'
  },
  mobileNumberContainer: {
    marginTop: '4px'
  },
  issue: {
    color: '#ffaf00',
    textTransform: 'uppercase'
  },
  rows: {
    display: 'flex'
  },
  issuesIcon: {
    marginLeft: 5
  },
  createButton: {
    marginBottom: '8px',
    background: 'rgba(255, 255, 255, 0.05)'
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableRows: {
    cursor: 'pointer'
  },
  scrollbar: {
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  checkbox: {
    color: 'rgba(0, 39, 77, 0.15)',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: 'none !important'
    },
    '&.Mui-checked': {
      color: '#016AD4 !important'
    },
    '& .MuiCheckbox-root': {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    }
  },
  checkboxIcon: {
    borderRadius: '3px',
    width: '16px',
    height: '16px',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#FBFBFC',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
    }
  },
  checkboxCheckedIcon: {
    backgroundColor: '#016AD4',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  },
  checkboxIndeterminateIcon: {
    '& > svg': {
      fill: '#016AD4',
      width: '20px',
      height: '20px'
    }
  },
  uncheckedBorder: {
    border: '1.5px solid rgba(0, 39, 77, 0.15)'
  },
  headerCell: {
    padding: 0
  },
  saveButton: {
    textTransform: 'none',
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    background: '#016AD4',
    color: '#FFFFFF',
    width: '100%'
  },
  saveButtonDisabled: {
    opacity: 0.3,
    color: '#FFFFFF !important',
    background: '#016AD4 !important',
    textTransform: 'capitalize'
  },
  discardButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '13px 16px',
    color: '#00274D',
    fontWeight: 500,
    width: '100%',
    border: '1px solid rgba(0, 39, 77, 0.1)',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',

    '&:hover': {
      border: '1px solid rgba(0, 39, 77, 0.1) !important'
    }
  },
  discardButtonDisabled: {
    opacity: 0.3,
    color: '#00274D !important'
  },
  summaryActionsContainer: {
    display: 'flex',
    gap: '12px',
    flexWrap: 'wrap-reverse',
    justifyContent: 'center',
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'nowrap'
    }
  },
  backButton: {
    flex: '1 0 100%',
    display: 'flex',
    alignItems: 'center'
  },
  pageNav: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.6,
    marginBottom: '4px',
    marginLeft: '-12px'
  },
  pageTitle: {
    marginBottom: '24px'
  },
  pageAboveTitle: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '24px'
    }
  },
  pageContent: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
}))
