import {Theme, makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    minHeight: 0,
    width: '450px',
    borderRadius: '6px',
    border: '1px solid rgba(0, 39, 77, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#00274D',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  firstTab: {
    textTransform: 'none',
    padding: '7.5px 19px',
    margin: '3.5px',
    minHeight: 0,
    height: '35px',
    letterSpacing: 'normal',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  secondTab: {
    textTransform: 'none',
    padding: '7.5px 33px',
    margin: '3.5px',
    minHeight: 0,
    width: '48%',
    height: '35px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  tabSelected: {
    background: '#016AD4',
    color: 'white',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600
  },
  selectDropdown: {
    backgroundColor: '#FFFFFF',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    borderRadius: '4px',
    boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
    width: '340px',
    maxWidth: '340px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: 'none'
    }
  },
  radio: {
    boxShadow: 'none',
    border: 'none',

    '&:hover': {
      boxShadow: 'none',
      border: 'none'
    }
  },
  radioIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  },
  datepicker: {
    backgroundColor: '#FFFFFF',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    borderRadius: '4px',
    boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
    width: '280px',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  textArea: {
    '& .MuiFilledInput-root': {
      border: '1.5px solid #016AD4',
      boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
      borderRadius: '4px',
      backgroundColor: '#FBFBFC',
      lineHeight: '22px'
    }
  },
  textAreaTemplate: {
    marginLeft: '-10px',
    '& .MuiFilledInput-root': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      lineHeight: '22px'
    }
  },
  templateButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#00274D',
    letterSpacing: 0,
    border: '1px solid rgba(0, 39, 77, 0.1)',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    borderRadius: '6px'
  },
  previewInfoContainer: {
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid #EAEDF0',
    borderTop: '1px solid #EAEDF0',
    borderBottom: '1px solid #EAEDF0',
    borderRadius: '4px',
    padding: '12px 16px',
    lineHeight: '16px',
    boxShadow: theme.shadows[9],
    marginTop: '14px'
  },
  uncheckedBorder: {
    border: '1.5px solid rgba(0, 39, 77, 0.15)'
  },
  timepicker: {
    outline: 'none',
    '& .MuiFilledInput-input': {
      backgroundColor: '#FFFFFF',
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      borderRadius: '4px',
      boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
      width: '190px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  }
}))
