import {Box, Button, TextField} from '@mui/material'
import {isEmpty} from 'lodash'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UsersTableFilter} from '../UsersList/UsersList.types'

import {useStyles} from './WidgetUserList.styles'

type WidgetUserListFormData = {
  name: string
  email: string
  mobileNumber: string
  customerId: string
}

type WidgetUserListFormProps = {
  setIsSearchForm: (isSearchForm: boolean) => void
  setFilter: (filter: UsersTableFilter) => void
  filter: UsersTableFilter
}
export const WidgetUserListForm = ({
  setIsSearchForm,
  filter,
  setFilter
}: WidgetUserListFormProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const formMethods = useForm<WidgetUserListFormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      mobileNumber: '',
      customerId: ''
    }
  })

  const allValues = formMethods.watch()

  const handleSubmitForm = (data: WidgetUserListFormData) => {
    setIsSearchForm(false)
    setFilter({
      ...filter,
      name: data.name,
      eMail: data.email,
      mobileNumber: data.mobileNumber,
      customerId: data.customerId
    })
  }
  return (
    <form
      onSubmit={formMethods.handleSubmit(handleSubmitForm)}
      style={{display: 'flex', flexDirection: 'column', gap: '12px'}}
    >
      <Controller
        name="name"
        control={formMethods.control}
        render={({field}) => {
          return (
            <TextField
              {...field}
              data-test-id="widget-user-name-input"
              placeholder={t('widgetUserList.form.name')}
              className={classes.textField}
            />
          )
        }}
      />
      <Controller
        name="email"
        control={formMethods.control}
        render={({field}) => {
          return (
            <TextField
              {...field}
              data-test-id="widget-user-email-input"
              placeholder={t('widgetUserList.form.email')}
              className={classes.textField}
            />
          )
        }}
      />
      <Controller
        name="mobileNumber"
        control={formMethods.control}
        render={({field}) => {
          return (
            <TextField
              {...field}
              data-test-id="widget-user-mobile-number-input"
              placeholder={t('widgetUserList.form.mobileNumber')}
              className={classes.textField}
            />
          )
        }}
      />
      <Controller
        name="customerId"
        control={formMethods.control}
        render={({field}) => {
          return (
            <TextField
              {...field}
              data-test-id="widget-user-customerId-input"
              placeholder={t('widgetUserList.form.customerId')}
              className={classes.textField}
              style={{width: '50%'}}
            />
          )
        }}
      />
      <Button
        variant="contained"
        className={classes.searchButton}
        type="submit"
        data-test-id="widget-user-search-button"
        disabled={Object.values(allValues).every((value) => value === '')}
      >
        {t('widgetUserList.form.search')}
      </Button>
    </form>
  )
}
