import {useBreakPoints} from '@hconnect/uikit'
import {Close, Check} from '@mui/icons-material'
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AudienceList} from '../components/AudienceList'

import {useStyles} from './SelectRecipientsDialog.styles'

type SelectRecipientsDialogProps = {
  showRecipents: boolean
  setShowRecipients: (showRecipients: boolean) => void
  selectedAudiencesIds: number[]
  setSelectedAudiencesIds: (audiencesIds: number[]) => void
}

export const SelectRecipientsDialog = ({
  showRecipents,
  setShowRecipients,
  selectedAudiencesIds,
  setSelectedAudiencesIds
}: SelectRecipientsDialogProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [shouldEvaluateAudiences, setShouldEvaluateAudiences] = useState(false)
  const [shouldCloseDialog, setShouldCloseDialog] = useState(false)

  return (
    <Dialog
      data-test-id="delete-feature-dialog"
      open={showRecipents}
      onClose={() => setShowRecipients(false)}
      classes={{paper: classes.paper}}
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{t('createMessage.form.recipients.title')}</DialogTitle>
        <IconButton
          onClick={() => setShowRecipients(false)}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent style={{padding: isMobile ? '0 8px' : undefined}}>
        <AudienceList
          selectedAudiencesIds={selectedAudiencesIds}
          setSelectedAudienceIds={setSelectedAudiencesIds}
          shouldEvaluateAudiences={shouldEvaluateAudiences}
          setShouldEvaluateAudiences={setShouldEvaluateAudiences}
          shouldCloseDialog={shouldCloseDialog}
          setShowRecipients={setShowRecipients}
          isRecipientsDialog={true}
        />
      </DialogContent>

      <DialogActions>
        <Box style={{display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center'}}>
          <Button
            onClick={() => setShowRecipients(false)}
            startIcon={<Close />}
            variant="text"
            style={{color: 'rgba(1, 106, 212, 1)'}}
            data-test-id="delete-feature-dialog-action-cancel"
          >
            {t('createMessage.form.recipients.cancel')}
          </Button>
          <Button
            onClick={() => {
              setShouldCloseDialog(true)
              setShouldEvaluateAudiences(true)
            }}
            className={classes.confirmButton}
            startIcon={<Check />}
            data-test-id="select-recipients-confirm-button"
            variant="contained"
          >
            {t('createMessage.form.recipients.confirm')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
