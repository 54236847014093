import {Box} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {DetailsField} from '../../../components/DetailsField/DetailsField'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'

interface UserDetailsFieldProps {
  roles?: RoleAssignment[] | null
  isRolesLoading?: boolean
}

export const UserDetailsField = ({roles, isRolesLoading}: UserDetailsFieldProps) => {
  const {t} = useTranslation()

  const customerIds = roles
    ?.map((role) => role.dataScope?.['customerIds'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .map((value) => value.split('.').pop())

  const businessLines = roles
    ?.map((role) => role.dataScope?.['businessLine'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)

  return (
    <Box>
      <Box mt={2}>
        <DetailsField
          label={t('quickPreview.customerId')}
          value={customerIds && customerIds.length > 0 ? customerIds.join(', ') : '-'}
          isLoading={isRolesLoading}
          dataTestId="userList-details-customerId-field"
        />
      </Box>
      <Box mt={2}>
        <DetailsField
          label={t('quickPreview.businessLine')}
          value={
            businessLines && businessLines.length > 0
              ? Array.from(
                  new Set(
                    businessLines &&
                      businessLines.map((bl) => t(`usersList.businessLine.${bl.toLowerCase()}`))
                  )
                )
                  .sort()
                  .join(', ')
              : '-'
          }
          isLoading={isRolesLoading}
          dataTestId="userList-details-businessLine-field"
        />
      </Box>
    </Box>
  )
}
