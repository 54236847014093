import {Content, Page, useBreakPoints} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Button, Grid, Paper} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {AudienceDetails} from '../../UsersList/AudienceDetails'
import {useAudience} from '../../UsersList/hooks/useAudience'
import {AudienceList} from '../components/AudienceList'

import {useStyles} from './Audiences.styles'

export type Audience = {
  id: number
  name: string
  filters: string
  createdOn: string
  createdBy: string
}
export const Audiences = () => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [audienceDetails, setAudienceDetails] = useState<Audience>()
  const [selectedAudiencesIds, setSelectedAudiencesIds] = useState<number[]>([])
  const [shouldEvaluateAudiences, setShouldEvaluateAudiences] = useState(false)
  const [shouldCloseDialog, setShouldCloseDialog] = useState(false)
  const [showRecipients, setShowRecipients] = useState(true)
  const {classes} = useStyles()
  const history = useHistory()

  const {
    fetchAudienceDetails,
    isLoadingAudienceDetails,
    audienceDetailsData,
    deleteAudienceMutation
  } = useAudience(undefined, audienceDetails?.id)

  useEffect(() => {
    if (audienceDetails) {
      void fetchAudienceDetails()
    }
  }, [audienceDetails])

  useEffect(() => {
    if (!showRecipients && shouldCloseDialog) {
      history.push('/messages/create', {
        selectedAudiencesIds:
          audienceDetails && selectedAudiencesIds.length === 0
            ? [audienceDetails.id]
            : selectedAudiencesIds,
        redirect: 'audiences'
      })
    }
  }, [showRecipients])

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (audienceDetails && audienceDetails?.id) {
      deleteAudienceMutation()
    }
    setAudienceDetails(undefined)
  }

  const handleCloseAudience = () => setAudienceDetails(undefined)
  const formatAudienceFilters = () => {
    if (audienceDetailsData?.filters) {
      const parsedFilters = JSON.parse(audienceDetailsData.filters)
      return Object.keys(parsedFilters).map((filterKey) => ({
        name: filterKey || '',
        value: parsedFilters[filterKey] || '',
        filterName: filterKey || ''
      }))
    } else return []
  }

  return (
    <Content>
      <Page
        data-test-id="page-audiences"
        title={t('audiences.title')}
        boxed={false}
        headerActionContent={
          <Button
            variant="contained"
            data-test-id="create-message-button"
            onClick={() => {
              setShouldCloseDialog(true)
              setShouldEvaluateAudiences(true)
            }}
            style={{
              background: 'rgba(255, 255, 255, 0.05)',
              fontWeight: 500,
              border: '1px solid rgba(255, 255, 255, 0.15)',
              marginTop: isMobile ? '12px' : 0
            }}
          >
            <Add className={classes.addIcon} fontSize="small" />
            {t('audiences.createMessage')}
          </Button>
        }
        classNameHeader={classes.pageHeader}
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid
          container
          style={{columnGap: '16px', marginTop: '22px', alignItems: 'stretch'}}
          wrap="nowrap"
        >
          <Grid
            item
            md={audienceDetails && !isMobile ? 8 : 12}
            sm={12}
            xs={12}
            style={{height: '100%'}}
          >
            <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
              <AudienceList
                audienceDetailsData={audienceDetailsData}
                selectedAudienceDetails={audienceDetails}
                setSelectedAudienceDetails={setAudienceDetails}
                selectedAudiencesIds={selectedAudiencesIds}
                setSelectedAudienceIds={setSelectedAudiencesIds}
                setShouldEvaluateAudiences={setShouldEvaluateAudiences}
                shouldEvaluateAudiences={shouldEvaluateAudiences}
                shouldCloseDialog={shouldCloseDialog}
                setShouldCloseDialog={setShouldCloseDialog}
                setShowRecipients={setShowRecipients}
                isLoadingAdditionalDetails={isLoadingAudienceDetails}
              />
            </Paper>
          </Grid>
          {!isMobile && audienceDetails ? (
            <Grid item md={4} sm={0} xs={0}>
              <Paper elevation={4} style={{padding: '26px 32px 26px 32px', height: '100%'}}>
                <AudienceDetails
                  audience={audienceDetails}
                  filters={formatAudienceFilters()}
                  handleClose={handleCloseAudience}
                  isLoadingAdditionalDetails={isLoadingAudienceDetails}
                  additionalDetailsData={audienceDetailsData}
                  setShouldEvaluateAudiences={setShouldEvaluateAudiences}
                  setShouldCloseDialog={setShouldCloseDialog}
                  handleDelete={handleDelete}
                />
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Page>
    </Content>
  )
}
