import {Feature, useFeaturesListFetcher} from '@hconnect/common/components/FeaturesCheck'
import {Content, Page, ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import {Snackbar} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AddIcon from '@mui/icons-material/Add'
import {Button, Grid, Paper} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {api} from '../../App.store'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {useQueryParamState} from '../../common/QueryParamStateHook'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../modules/LoggedInUser.selectors'

import {DeleteFeatureDialog} from './dialogs/DeleteFeatureDialog'
import {FeatureDetails} from './FeatureDetails'
import {useStyles} from './FeaturesList.styles'
import {FeaturesListRows} from './FeaturesListRows'
import {useFeaturesListFilters} from './hooks/useFeaturesListFilters'

export type FeaturesFilter = {
  rowsPerPage: number
  page: number
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
  name?: string
  description?: string
  tags?: string
  type?: string
  constraints?: string
  enabled?: boolean
  country: string
}

// eslint-disable-next-line complexity
export const FeaturesList = () => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const {classes} = useStyles()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [deleteFeatureDialogData, setDeleteFeatureDialogData] = useState<string | undefined>()
  const history = useHistory()
  const permissions = useSelector(selectLoggedInUserPermissions)
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const {data: roles} = useUserRoles(loggedInUserProfile?.user_id)

  const canEditFeatures =
    permissions.some((permission) => permission.permissionType === 'CHANGE_FEATURES') ||
    !!roles?.find(
      (role) =>
        role.roleType === 'GLOBAL_ADMIN' &&
        (role.dataScope?.['countryId'] === '*' || role.dataScope?.['areaId'] === '*')
    ) ||
    false

  const [featureDetails, setFeatureDetails] = useState<Feature>()

  const handleClose = () => setFeatureDetails(undefined)

  const [filter, setFilter] = useQueryParamState<FeaturesFilter>({
    rowsPerPage: 10,
    page: 1,
    sortedByKey: 'name',
    sortedByDirection: 'asc'
  })

  const {
    data: features,
    isFetching: featuresFetching,
    isError: featuresError
  } = useFeaturesListFetcher(api, 'all', filter)

  const {
    data: countries,
    isFetching: countriesFetching,
    isError: countriesError
  } = useCountriesConfiguration()

  const {filterInputValues, handleFilterInputValues, setFilterInputValues, setRemovedFilter} =
    useFeaturesListFilters(filter, setFilter)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const handleEditFeature = (feature: Feature) => {
    history.push(`/features/edit/${feature.name}`)
  }
  const handleDeleteFeature = (featureName: string) => {
    setDeleteFeatureDialogData(featureName)
  }

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortedByKey === sortKey && filter.sortedByDirection === 'asc')
    setFilter({
      ...filter,
      sortedByKey: sortKey,
      sortedByDirection: asc ? 'asc' : 'desc'
    })
  }

  const rowsPerPage = Number(filter.rowsPerPage)

  const noResultsMessage =
    featuresError || countriesError ? t('features.errorOnFetching') : t('features.noResults')

  const totalPageCount = features?.headers ? parseInt(features.headers['x-total-count'], 10) : 0

  return (
    <Content>
      <Page
        data-test-id="page-features"
        title={t('features.title')}
        classNameHeader={classes.pageHeader}
        boxed={false}
        headerActionContent={
          canEditFeatures ? (
            <Button
              variant="contained"
              data-test-id="create-feature-button"
              onClick={() => history.push('/features/create')}
              className={classes.createButton}
            >
              <AddIcon className={classes.addIcon} />
              {t('features.createNewFeature')}
            </Button>
          ) : null
        }
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid
          container
          style={{columnGap: '16px', marginTop: '22px', alignItems: 'stretch'}}
          wrap="nowrap"
        >
          <Grid
            item
            md={featureDetails && !isMobile ? 8 : 12}
            sm={12}
            xs={12}
            style={{height: '100%'}}
          >
            <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
              <ResponsiveTable
                keyField="name"
                columns={FeaturesListRows({
                  countries,
                  setIsSnackbarOpen,
                  handleEditFeature,
                  handleDeleteFeature,
                  canEditFeatures
                })}
                rows={features?.data ?? []}
                onSort={(e, sortKey) => setSortKey(sortKey)}
                sortedBy={filter.sortedByKey}
                sortingOrder={filter.sortedByDirection}
                emptyMessage={!featuresFetching && !countriesFetching && noResultsMessage}
                loading={featuresFetching && countriesFetching}
                isMobile={isMobile}
                page={filter.page}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                count={totalPageCount}
                enableColumnSelection={true}
                enableHorizontalScroll={true}
                tableHeight="70vh"
                stickyHeader={true}
                enablePinning={true}
                isRowSelectable={isMobile ? undefined : () => true}
                rowDetailsAvailable={true}
                filterListInputValues={filterInputValues}
                setFilterListInputValues={setFilterInputValues}
                onFilterInputChange={(props: TableFilterItemProps) =>
                  handleFilterInputValues(props)
                }
                onFilterRemove={(filter: TableFilterType) => {
                  setRemovedFilter(filter)
                  setFilterInputValues(
                    filterInputValues.filter(
                      (inputValue) => inputValue.filterField !== filter.filterField
                    )
                  )
                }}
                onRowClick={(item: Feature) => {
                  if (item.name === featureDetails?.name) {
                    setFeatureDetails(undefined)
                  } else {
                    setFeatureDetails(item)
                  }
                }}
                mobileRowDetails={
                  featureDetails &&
                  featureDetails?.constraints.length > 1 && (
                    <FeatureDetails
                      feature={featureDetails}
                      countries={countries}
                      handleEdit={(feature) => handleEditFeature(feature)}
                      handleClose={handleClose}
                      handleDelete={(featureName) => handleDeleteFeature(featureName)}
                      canEditFeatures={canEditFeatures}
                    />
                  )
                }
              />
            </Paper>
          </Grid>
          {!isMobile && featureDetails ? (
            <Grid item md={4} sm={0} xs={0}>
              <Paper elevation={4} style={{padding: '26px 32px 26px 32px', height: '100%'}}>
                <FeatureDetails
                  feature={featureDetails}
                  countries={countries}
                  handleClose={handleClose}
                  handleDelete={(featureName) => handleDeleteFeature(featureName)}
                  handleEdit={(feature) => handleEditFeature(feature)}
                  canEditFeatures={canEditFeatures}
                />
              </Paper>
            </Grid>
          ) : null}
        </Grid>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setIsSnackbarOpen(false)}
        >
          <Alert onClose={() => setIsSnackbarOpen(false)} severity="success">
            {t('features.copied')}
          </Alert>
        </Snackbar>
        {deleteFeatureDialogData && (
          <DeleteFeatureDialog
            deleteFeatureDialogData={deleteFeatureDialogData}
            setDeleteFeatureDialogData={setDeleteFeatureDialogData}
          />
        )}
      </Page>
    </Content>
  )
}
