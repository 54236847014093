import {User} from '@hconnect/apiclient'
import {trackEvent, trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {Button} from '@material-ui/core'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../../App.store'
import {generateEventTrackBodyFromUser} from '../../../common/EventTracking/utils'

type UseDeleteUserProps = {
  setShowDeleteUserDialog: (showDialog: boolean) => void
  userData?: User
  shouldGoBack?: boolean
}

export const useDeleteUser = ({
  setShowDeleteUserDialog,
  userData,
  shouldGoBack = true
}: UseDeleteUserProps) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const history = useHistory<{source: string}>()

  const handleDeleteUser = async () => {
    try {
      if (userData) {
        setShowDeleteUserDialog(false)
        await api.delete(`/users/${userData.id}`)

        trackEventWithBrowserProps('adminUserDelete', generateEventTrackBodyFromUser(userData))

        shouldGoBack ? history.goBack() : window.location.reload()
        enqueueSnackbar(
          t('manageUser.deleteUserSnackbarSuccess', {
            name: userData.name
          }),
          {
            // eslint-disable-next-line react/display-name
            action: () => (
              <Button onClick={() => closeSnackbar()} variant="text" color="primary">
                {t('manageUser.deleteUserSnackbarClose')}
              </Button>
            ),
            variant: 'success'
          }
        )
      }
    } catch (e) {
      const error = e as AxiosError
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'ManageUser.tsx Delete user',
        endpoint: error.response?.config?.url
      })
      enqueueSnackbar(
        error.response?.status === 400 || error.response?.status === 403
          ? t('manageUser.hasNoPermissions')
          : t('manageUser.deleteUserSnackbarError'),
        {variant: 'error'}
      )
    }
  }

  return {handleDeleteUser}
}
