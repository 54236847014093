import {useQuery} from '@tanstack/react-query'
import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {api} from '../../../App.store'
import {Announcement, AnnouncementsFilter} from '../Announcements'

interface ApiResponse {
  total: number
  announcements: Announcement[]
}
interface ErrorResponse {
  error: Error
}

export const useAnnouncements = ({
  page,
  rowsPerPage,
  sortedByKey,
  sortedByDirection,
  ...filterProps
}: AnnouncementsFilter) => {
  return useQuery<ApiResponse, ErrorResponse>(
    ['announcements', {page, rowsPerPage, sortedByKey, sortedByDirection, ...filterProps}],
    async () => {
      const params = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        sortedBy: `${sortedByKey} ${sortedByDirection}`,
        ...filterProps
      }
      const requestConfig: AxiosRequestConfig = {
        params
      }
      try {
        const res: AxiosResponse<Announcement[]> = await api.get('/announcements', requestConfig)
        return {
          total: res.headers['total-count'] ? +res.headers['total-count'] : 0,
          announcements: res.data
        }
      } catch (e) {
        throw new Error('Error during fetching announcements')
      }
    },
    {
      cacheTime: 0
    }
  )
}

export const useAnnouncementDetails = (messageId?: string) => {
  return useQuery<Announcement, ErrorResponse>(
    ['announcements', {messageId}],
    async () => {
      try {
        const res: AxiosResponse<Announcement> = await api.get(`/announcements/${messageId}`)
        return res.data
      } catch (e) {
        throw new Error('Error during fetching announcements')
      }
    },
    {
      enabled: Boolean(messageId)
    }
  )
}
