import {User, addToWhitelist, getUsers} from '@hconnect/apiclient'
import {Divider, Typography} from '@hconnect/uikit'
import {Box, TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {api} from '../../App.store'
import {selectLoggedInUserPermissions} from '../../modules/LoggedInUser.selectors'

import {useStyles} from './Configurations.styles'

const MIN_LENGTH_AUTOCOMPLETE = 3

const RenderedUserOption: React.FC<{user: User}> = ({user}) => (
  <div>
    <div>{user.name}</div>
    <div>{user.eMail}</div>
    <div>{user.mobileNumber}</div>
  </div>
)

export const PushNotificationConfiguration: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [userSearchValue, setUserSearchValue] = useState('')
  const [users, setUsers] = useState<User[]>([])

  const permissions = useSelector(selectLoggedInUserPermissions)

  useEffect(() => {
    const searchForUser = async () => {
      if (userSearchValue.trim().length < MIN_LENGTH_AUTOCOMPLETE) {
        return
      }

      const result = await getUsers(api)({eMail: userSearchValue})

      setUsers(result.data)
    }
    // eslint-disable-next-line no-void
    void searchForUser()
  }, [userSearchValue])

  const canEditConfigurations = permissions.some(
    (permission) => permission.permissionType === 'CHANGE_CONFIGURATIONS'
  )

  // a user can have multiple device tokens. We add every one in a separate request
  // because the whole request fails if any one of the tokens already exists
  const handlePushNotificationAddSubmit = async (user: User) => {
    const promises = user.mobileDeviceTokens.map(async ({token}) =>
      addToWhitelist(api)({type: 'push', entry: [token]})
    )

    const settledPromises = await Promise.allSettled(promises)

    const tokensAdded: string[] = []

    settledPromises.forEach((promiseSettledResult) => {
      if (promiseSettledResult.status === 'fulfilled') {
        if (promiseSettledResult.value.type === 'value') {
          tokensAdded.push(promiseSettledResult.value.value[0])
        }
      }
    })

    enqueueSnackbar(t('configurations.whitelist.success.pushAdded'), {variant: 'success'})
  }

  return (
    <Box mb={4} paddingBottom="24px">
      <Box>
        <Typography variant="h3">{t('configurations.whitelist.pushNotificationHeader')}</Typography>
      </Box>
      <Divider />

      <div className={classes.controlRow}>
        {canEditConfigurations && (
          <Autocomplete
            options={users}
            onInputChange={(_, value) => setUserSearchValue(value)}
            getOptionLabel={(user) => user.name ?? ''}
            renderOption={(user) => <RenderedUserOption user={user} />}
            onChange={(_, user) => user && handlePushNotificationAddSubmit(user)}
            style={{width: 300}}
            renderInput={(params) => <TextField {...params} placeholder="Email..." />}
          />
        )}
      </div>
    </Box>
  )
}
