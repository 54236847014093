import Assessment from '@mui/icons-material/Assessment'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {IconTextButton} from '../../components/IconTextButton'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../modules/LoggedInUser.selectors'

import {AuditInformationDialog} from './dialogs/AuditInformationDialog'

export type ViewAuditButtonProps = {
  userIdentifier: string
  auditType: 'user' | 'roles'
  userCountry: string
}

export const ViewAuditButton = ({auditType, userIdentifier, userCountry}: ViewAuditButtonProps) => {
  const {t} = useTranslation()
  const permissions = useSelector(selectLoggedInUserPermissions)
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const canViewAuditInformation = permissions.some(
    (permission) =>
      permission.permissionType === 'VIEW_AUDIT' && 
      (permission.dataScope.countryId === userCountry ||
        loggedInUserProfile?.hasRoles.includes('GLOBAL_ADMIN'))
  )

  const [isAuditDialogOpen, setShowAuditDialog] = useState(false)
  const handleCloseDialog = () => {
    setShowAuditDialog(false)
  }

  return (
    <>
      {isAuditDialogOpen && (
        <AuditInformationDialog
          userIdentifier={userIdentifier}
          auditType={auditType}
          onCloseAuditDialog={handleCloseDialog}
        />
      )}
      <IconTextButton
        label={t(auditType === 'user' ? 'audit.viewUserAudit' : 'audit.viewRoleAudit')}
        IconComponent={Assessment}
        onClick={() => setShowAuditDialog(true)}
        data-test-id="button-audit-information"
        hide={!canViewAuditInformation}
      />
    </>
  )
}
