import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  pageHeader: {
    marginBottom: '22px'
  },
  copyJSONButton: {
    display: 'flex',
    '&.MuiButton-root': {
      textTransform: 'none',
      whiteSpace: 'nowrap',
      padding: '9px 16px',
      fontSize: '16px',
      fontWeight: 500,
      color: '#00274D',
      letterSpacing: 0,
      border: '1px solid rgba(0, 39, 77, 0.1)',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px'
    }
  },
  addIcon: {
    marginRight: theme.spacing(1)
  },
  createButton: {
    marginBottom: '8px',
    background: 'rgba(255, 255, 255, 0.05)',
    fontWeight: 500,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: '12px'
    }
  }
}))
