import {Company} from '@hconnect/apiclient'
import {trackEvent, trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {Content, Page} from '@hconnect/uikit'
import {Button, CircularProgress} from '@mui/material'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {generateEventTrackBodyFromCompany} from '../../common/EventTracking/utils'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {CompanyForm} from '../../components/Company/CompanyForm'
import {CompanyFormData} from '../../components/Company/CompanyForm.types'
import {handleCompanyValidationError} from '../../errorHandling'
import {createCompany} from '../../modules/Company.actions'
import {CompanyErrorResponse} from '../../validations/types'

import {useStyles} from './CreateCompany.styles'

export const CreateCompany: React.FC = () => {
  const {t} = useTranslation()
  const {
    data: availableCountries,
    isLoading: loadingAvailableCountries,
    error: errorAvailableCountries
  } = useCountriesConfiguration()
  const {classes} = useStyles()
  const history = useHistory()
  const [isCompanyCreating, setIsCompanyCreating] = useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const getCountry = () => {
    const countryIds = availableCountries?.map((country) => country.countryCode)
    return countryIds?.length ? countryIds[0] : ''
  }

  const country = getCountry()

  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      country,
      availableCountries: availableCountries ?? [],
      website: '',
      industry: '',
      name: ''
    },
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const renderCreateCompanyForm = () => {
    if (errorAvailableCountries) {
      return <div>{t('roleAssignment.labelFetchError')}</div>
    }

    if (loadingAvailableCountries) {
      return <CircularProgress size={20} />
    }

    return (
      <form id="create-company-form" onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
        <CompanyForm formMethods={formMethods} readonlyMode={false} />
        <div className={classes.errorContainer}>
          <Button
            color="primary"
            type="submit"
            disabled={isCompanyCreating}
            data-test-id="button-submit"
          >
            {t('createCompany.submit')}
          </Button>
        </div>
      </form>
    )
  }

  const handleSubmitForm = async (company) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {availableCountries, ...cleanCompany} = company
    try {
      setIsCompanyCreating(true)
      const company = await handleCreateCompany(cleanCompany)

      trackEventWithBrowserProps('adminCompanyCreateSuccess', {
        ...generateEventTrackBodyFromCompany(company),
        creationChannel: 'adminConsole'
      })

      enqueueSnackbar(
        t('createCompany.companyWasCreated', {
          name: company.name || company.country
        }),
        {anchorOrigin: {vertical: 'top', horizontal: 'center'}, variant: 'success'}
      )
      history.push('/companies')
    } catch (err) {
      const error = err as AxiosError<CompanyErrorResponse>
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'CreateUser.tsx',
        endpoint: error.response?.config?.url
      })
      const errorMessage = handleCompanyValidationError(error.response?.data, formMethods)
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    } finally {
      setIsCompanyCreating(false)
    }
  }

  useEffect(() => {
    formMethods.setValue('country', country, {shouldValidate: true})
    formMethods.setValue('availableCountries', availableCountries ?? [], {shouldValidate: true})
  }, [formMethods, country, availableCountries])

  const handleCreateCompany = async (companyInfo: Partial<Company>): Promise<Company> => {
    const payload = {
      ...companyInfo
    }

    const company = await createCompany(payload)
    return company
  }

  return (
    <Content>
      <Page title={t('createCompany.title')}>{renderCreateCompanyForm()}</Page>
    </Content>
  )
}
