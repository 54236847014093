import {addToWhitelist, deleteFromWhitelist, getWhitelist} from '@hconnect/apiclient'
import {Divider, LoadingButton, Searchfield, Typography} from '@hconnect/uikit'
import {Box, ButtonBase, InputAdornment, TextField} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {List} from 'react-virtualized'

import {api} from '../../App.store'
import {selectLoggedInUserPermissions} from '../../modules/LoggedInUser.selectors'

import {useStyles} from './Configurations.styles'

const filterValues = (values: string[], filter: string): string[] =>
  filter.trim() === '' ? values : values.filter((value) => value.includes(filter))

export const EmailNotificationConfiguration: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [emails, setEmails] = useState<string[]>([])
  const [addEmailInput, setAddEmailInput] = useState('')
  const [emailFilter, setEmailFilter] = useState('')

  // to handle which delete button shows a spinner
  const [loadingStates, setLoadingStates] = useState<string[]>([])

  const permissions = useSelector(selectLoggedInUserPermissions)

  const canEditConfigurations = permissions.some(
    (permission) => permission.permissionType === 'CHANGE_CONFIGURATIONS'
  )

  const getEmailWhitelist = useCallback(async () => {
    const response = await getWhitelist(api)('email')

    if (response.type === 'value') {
      setEmails(response.value)
    } else {
      enqueueSnackbar(t('configurations.whitelist.error.getEmails'), {variant: 'error'})
    }
  }, [enqueueSnackbar, t])

  // eslint-disable-next-line no-void
  useEffect(() => void getEmailWhitelist(), [getEmailWhitelist])

  const filteredEmails = useMemo(() => filterValues(emails, emailFilter), [emails, emailFilter])

  const handleEmailAddSubmit = async () => {
    const response = await addToWhitelist(api)({type: 'email', entry: [addEmailInput]})

    if (response.type === 'value') {
      setAddEmailInput('')
      enqueueSnackbar(t('configurations.whitelist.success.emailAdded'), {variant: 'success'})
      await getEmailWhitelist()
    } else {
      enqueueSnackbar(t('configurations.whitelist.error.emailAdd'), {variant: 'error'})
    }
  }

  const handleEmailDelete = async (email: string) => {
    setLoadingStates((s) => [...s, email])
    await deleteFromWhitelist(api)({type: 'email', entry: email})
    await getEmailWhitelist()
    setLoadingStates((s) => s.filter((entry) => entry !== email))
    enqueueSnackbar(t('configurations.whitelist.success.emailRemoved'), {variant: 'success'})
  }

  return (
    <Box mb={4} paddingBottom="24px">
      <Box>
        <Typography variant="h3">{t('configurations.whitelist.emailHeader')}</Typography>
      </Box>
      <Divider />
      <div className={classes.controlRow}>
        {canEditConfigurations && (
          <TextField
            placeholder="admin1@nam.com"
            value={addEmailInput}
            onChange={(e) => {
              setAddEmailInput(e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ButtonBase onClick={handleEmailAddSubmit}>
                    <AddIcon />
                  </ButtonBase>
                </InputAdornment>
              )
            }}
          />
        )}
        <Searchfield
          placeholder="Filter..."
          value={emailFilter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailFilter(e.target.value)}
        />
      </div>
      <List
        rowHeight={61}
        height={500}
        autoWidth
        width={9999}
        rowCount={filteredEmails.length}
        className={classes.list}
        rowRenderer={({index, style}) => {
          const email = filteredEmails[index]

          return (
            <div key={email} style={style}>
              <div key={email} className={classes.listContainer}>
                <Typography>{email}</Typography>
                {canEditConfigurations && (
                  <LoadingButton
                    loading={loadingStates.includes(email)}
                    color="primary"
                    onClick={() => handleEmailDelete(email)}
                  >
                    {t('configurations.whitelist.deleteButtonText')}
                  </LoadingButton>
                )}
              </div>
              <Divider color="soft" />
            </div>
          )
        }}
      />
    </Box>
  )
}
