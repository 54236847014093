import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  noQuillBorder: {
    '& .ql-container': {
      border: 'none'
    },

    '& .ql-editor': {
      minHeight: 0
    }
  }
}))
