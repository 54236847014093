import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../../App.store'
import {Audience} from '../../Audiences/Audiences'
import {MessagesFilter} from '../MessagesList'

export type Message = {
  id: number
  sentDate: string
  createdBy: string
  recipientCount: number
  recipientNames: string[]
  audiences: Audience[]
  subject: string
  type: string
  body: string
  attachments?: Attachment[]
}

export type Attachment = {
  attachmentId: number
  fileName: string
}

type MessagesParams = {
  skip?: number
  limit?: number
  subject?: string
  audienceName?: string
  rowsPerPage?: number
  page?: number
  sortColumn?: string
  sortDirection?: string
  sentDate?: string
  type?: string
  body?: string
}

interface MessagesApiResponse {
  total: number
  messages: Message[]
}
type MessagesQueryKey = [
  string,
  {
    rowsPerPage: number
    page: number
    sortColumn: string
    sortDirection: 'asc' | 'desc'
    subject: string
    audienceName: string
    sentDate: string
    type: 'email' | 'sms'
    body: string
  }
]

const messagesListFetcher: QueryFunction<MessagesApiResponse> = async ({queryKey}) => {
  const [
    ,
    {rowsPerPage, page, audienceName, sentDate, sortDirection, sortColumn, subject, type, body}
  ] = queryKey as MessagesQueryKey
  const params: MessagesParams = {
    rowsPerPage: parseInt(rowsPerPage.toString()),
    page: parseInt(page.toString()),
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    subject: subject,
    audienceName: audienceName,
    sentDate: sentDate,
    type: type,
    body: body
  }

  try {
    const response = await api.get<Message[]>('/notifications/message/paginatedMessageHistory', {
      params
    })

    return {
      total: response.headers['x-total-count'] ? +response.headers['x-total-count'] : 0,
      messages: response.data
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
export const useMessages = (filter: MessagesFilter) => {
  return useQuery<MessagesApiResponse, AxiosError, MessagesApiResponse, MessagesQueryKey>(
    [
      '/notifications/message/paginatedMessageHistory',
      {
        rowsPerPage: filter.rowsPerPage,
        page: filter.page,
        audienceName: filter.audienceName,
        sentDate: filter.sentDate,
        sortDirection: filter.sortDirection,
        sortColumn: filter.sortColumn,
        subject: filter.subject,
        type: filter.type,
        body: filter.body
      }
    ],
    messagesListFetcher,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )
}
