import {Quill} from 'react-quill'

const Parchment = Quill.import('parchment')

export class IndentAttributor extends Parchment.Attributor.Style {
  constructor() {
    super('indent', 'text-indent', {
      scope: Parchment.Scope.BLOCK
    })
  }

  add(node, value) {
    if (value === 0) {
      this.remove(node)
      return true
    } else {
      return super.add(node, `${value}em`)
    }
  }
}
