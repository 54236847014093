import {Content, Page} from '@hconnect/uikit'
import {Paper} from '@material-ui/core'
import axios from 'axios'
import React, {useState} from 'react'

import {DocxDropzone} from './DocxDropzone'
import {useStyles} from './LegalDocuments.styles'

export const LegalDocuments = () => {
  const classes = useStyles()
  const [conversionStatus, setConversionStatus] = useState<
    'idle' | 'uploading' | 'uploaded' | 'error'
  >('idle')
  const [convertedDocument, setConvertedDocument] = useState<string>('')

  const uploadDocx = async (file: File) => {
    const formData = new FormData()
    formData.append('DocxFile', file)

    setConversionStatus('uploading')
    try {
      const response = await axios.post<string>(
        'https://legaldocumentsui-dev.apps.dev02-emea.hck8s.me/Preview/Generate',
        formData,
        {
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {'Content-Type': 'multipart/form-data'}
        }
      )
      setConvertedDocument(response.data)
      setConversionStatus('uploaded')
    } catch (error) {
      setConversionStatus('error')
      setConvertedDocument('')
    }
  }

  return (
    <Content>
      <Page title="Legal Documents Converter Preview" className={classes.container}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            height: conversionStatus === 'idle' ? '250px' : '50px'
          }}
        >
          <DocxDropzone onDocxDropped={uploadDocx} />
        </div>
        {conversionStatus === 'error' && <div>There was an error, try again ...</div>}
        {conversionStatus === 'uploading' && <div>Uploading ...</div>}
        {conversionStatus === 'uploaded' && (
          <Paper className={classes.documentContainer}>
            <div dangerouslySetInnerHTML={{__html: convertedDocument}} />
          </Paper>
        )}
      </Page>
    </Content>
  )
}
