import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    alignItems: 'flex-end'
  },
  discardChangesButton: {
    marginRight: theme.spacing(2)
  }
}))
