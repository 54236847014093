import {Typography} from '@hconnect/uikit'
import {Box, Button, TextField} from '@material-ui/core'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Announcement} from '../../Announcements'
import {MessageTypes} from '../../common'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type PreviewInputProps = AnnouncementFormProps & {
  editAnnouncement?: Announcement
}

export const PreviewInput = ({formMethods, editAnnouncement}: PreviewInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const messageType = formMethods.watch('messageType')
  const products = formMethods.watch('products')
  const validFrom = formMethods.watch('validFrom')
  const validTo = formMethods.watch('validTo')

  const formatTemplate = (validFrom: string, validTo: string, messageType: MessageTypes) => {
    return t(`announcements.form.templates.${messageType}`, {
      validFrom: moment(validFrom).format('DD/MM/YYYY HH:mm'),
      validTo: moment(validTo).format('DD/MM/YYYY HH:mm')
    })
  }

  const templatesList = [
    {
      messageType: MessageTypes.maintenance,
      value: formatTemplate(validFrom as string, validTo as string, MessageTypes.maintenance)
    }
  ]

  const [currentTemplate, setCurrentTemplate] = useState<string | undefined>()
  const templateValue = templatesList.find(
    (template) => (template.messageType as string) === messageType
  )?.value
  const ref = useRef<HTMLInputElement>(null)
  const {formState} = formMethods

  useEffect(() => {
    if (messageType === (MessageTypes.maintenance as string)) {
      setCurrentTemplate(templateValue)
    } else {
      setCurrentTemplate(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType, validTo, validFrom])

  useEffect(() => {
    formMethods.setValue('message', editAnnouncement?.message || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType])

  useEffect(() => {
    if (currentTemplate) {
      formMethods.setValue('message', currentTemplate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate])

  return (
    <Box display="flex" flexDirection="column" data-test-id="announcement-form-message-row">
      <Typography
        style={{fontSize: '18px', fontWeight: 600, marginBottom: currentTemplate ? 0 : '12px'}}
      >
        {t('announcements.form.preview')}
      </Typography>
      <Box
        style={{
          display: 'flex',
          alignItems: currentTemplate ? 'center' : 'start',
          gap: '12px'
        }}
      >
        <Controller
          control={formMethods.control}
          name="message"
          rules={{
            validate: (v) => v.length > 0
          }}
          render={({field}) => (
            <TextField
              {...field}
              ref={ref}
              data-test-id="message-text-field"
              multiline={true}
              type="text"
              fullWidth
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                style: {color: '#54708C'}
              }}
              error={formState.errors.message?.type === 'validate'}
              helperText={
                formState.errors.message?.type === 'validate'
                  ? t('announcements.form.validateMessageText')
                  : null
              }
              minRows={currentTemplate ? 1 : 3}
              variant="filled"
              label={
                currentTemplate
                  ? t('announcements.form.messageTemplate')
                  : t('announcements.form.messageText')
              }
              className={currentTemplate ? classes.textAreaTemplate : classes.textArea}
              value={currentTemplate || field.value}
              {...(products.includes('OnSite') && {inputProps: {maxLength: 200}})}
            />
          )}
        />
        {templateValue && (
          <Button
            className={classes.templateButton}
            onClick={() => {
              setCurrentTemplate(
                currentTemplate
                  ? undefined
                  : templatesList.find(
                      (template) => (template.messageType as string) === messageType
                    )?.value
              )
            }}
          >
            {currentTemplate
              ? t('announcements.form.editMessage')
              : t('announcements.form.restoreTemplate')}
          </Button>
        )}
      </Box>
      <Box
        className={classes.previewInfoContainer}
        style={{borderLeft: currentTemplate ? '4px solid #00AB50' : '4px solid #EE8B00'}}
      >
        <Typography style={{color: '#00274D', fontSize: '16px'}}>
          {currentTemplate
            ? t('announcements.form.templateInfo')
            : t('announcements.form.noTemplateInfo')}
        </Typography>
      </Box>
    </Box>
  )
}
