import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './ResetPasswordDialog.styles'

type ResetPasswordDialogProps = {
  showResetPasswordDialog: boolean
  setShowResetPasswordDialog: (show: boolean) => void
  handleResetPassword: () => Promise<void>
}
export const ResetPasswordDialog = ({
  setShowResetPasswordDialog,
  showResetPasswordDialog,
  handleResetPassword
}: ResetPasswordDialogProps) => {
  const {t} = useTranslation()

  const classes = useStyles()
  return (
    <Dialog
      data-test-id="manage-user-dialog-reset-password"
      open={showResetPasswordDialog}
      onClose={() => setShowResetPasswordDialog(false)}
    >
      <DialogTitle>{t('manageUser.resetPasswordDialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          {t('manageUser.resetPasswordDialogMessage')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setShowResetPasswordDialog(false)} variant="text" color="primary">
          {t('manageUser.resetPasswordDialogActionCancel')}
        </Button>
        <Button
          onClick={handleResetPassword}
          variant="text"
          color="primary"
          data-test-id="button-reset-password-confirm"
        >
          {t('manageUser.resetPasswordDialogActionOk')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
