import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    alignItems: 'flex-end',
    marginBottom: 8
  },
  chipAuto: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    height: 24
  }
}))
