import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ScheduleDays} from '../../common'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type ScheduleInputProps = AnnouncementFormProps

export const ScheduleInput = ({formMethods}: ScheduleInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const scheduleOptions = [
    {value: ScheduleDays.zeroDays, label: t('announcements.form.schedule.zeroDays')},
    {value: ScheduleDays.oneDay, label: t('announcements.form.schedule.oneDay')},
    {value: ScheduleDays.threeDays, label: t('announcements.form.schedule.threeDays')},
    {value: ScheduleDays.fiveDays, label: t('announcements.form.schedule.fiveDays')},
    {value: ScheduleDays.sevenDays, label: t('announcements.form.schedule.sevenDays')}
  ]
  const [selectedItem, setSelectedItem] = useState<Item>(scheduleOptions[0])

  return (
    <Box
      data-test-id="announcement-schedule-dropdown"
      style={{display: 'flex', flexDirection: 'column'}}
    >
      <Typography style={{fontSize: '18px', fontWeight: 600, marginBottom: '12px'}}>
        {t('announcements.form.schedule.title')}
      </Typography>
      <Controller
        name="schedule"
        control={formMethods.control}
        render={({field}) => (
          <SelectDropdown
            data-test-id={`announcement-form-${field.name}`}
            label="Schedule"
            className={classes.selectDropdown}
            errorRender={() => <ErrorPaper variant="primaryDark" />}
            options={scheduleOptions}
            renderItem={(item: Item) => (
              <Typography variant="body1" color="textSecondary">
                {item.label}
              </Typography>
            )}
            showSearch={false}
            stringifyItem={(item: Item) => item.value}
            stringifySubItem={(item: Item) => item.label}
            renderChosenItem={(item: Item) => item.label}
            keyExtractor={(item: Item) => item.value}
            selectedItem={selectedItem}
            onChange={(item: Item) => {
              field.onChange(item.value)
              setSelectedItem(item)
            }}
            popoverStyle={{left: 0}}
          />
        )}
      />
    </Box>
  )
}
