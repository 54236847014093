import {EventAttachmentsDisplay} from '@hconnect/common/components/attachments'
import {Attachment} from '@hconnect/common/types'
import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {ArrowForward} from '@material-ui/icons'
import {AttachFile} from '@mui/icons-material'
import {Box, Button, TextField, Typography} from '@mui/material'
import fileDownload from 'js-file-download'
import React, {useEffect, useState} from 'react'
import {Controller, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {SegmentedMessage} from 'sms-segments-calculator'

import {ImageViewer} from '../components/ImageViewer'
import {RTEInput} from '../components/RTEInput'
import {SelectRecipientsDialog} from '../dialogs/SelectRecipientsDialog'
import {UploadAttachmentDialog} from '../dialogs/UploadAttachmentDialog'
import {MessageDetailsResponse} from '../MessagesList/hooks/useMessageDetails'

import {AttachmentListRows} from './AttachmentListRows'
import {SelectChannelInput} from './inputs/SelectChannelInput'

export type CreateMessageFormData = {
  subject: string
  channelType: 'Email' | 'sms'
  message: string
  attachments: File[]
}

const FILE_MAX_SIZE_IN_MB = 10

export type AnnouncementFormProps = {
  formMethods: UseFormReturn<CreateMessageFormData>
  selectedAudiencesIds: number[]
  setSelectedAudiencesIds: (audiencesIds: number[]) => void
  setShowSummary: (showSummary: boolean) => void
  showRecipients: boolean
  setShowRecipients: (showRecipients: boolean) => void
  files: File[]
  setFiles: (files: File[]) => void
  setMessageInfo: (info: SegmentedMessage) => void
  messageInfo?: SegmentedMessage
  isReadonly?: boolean
}

const MAX_EMAIL_WORDS_LENGTH = 2000

export const CreateMessageForm = ({
  formMethods,
  selectedAudiencesIds,
  setSelectedAudiencesIds,
  setShowSummary,
  messageInfo,
  setMessageInfo,
  showRecipients,
  setShowRecipients,
  files,
  setFiles,
  isReadonly
}: AnnouncementFormProps) => {
  const {t} = useTranslation()
  const channelType = formMethods.watch('channelType')
  const messageContent = formMethods.watch('message')
  const screenSizes = useBreakPoints()
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false)
  const [showImagePreview, setShowImagePreview] = useState<File>()

  const validateWordCount = (input: string) => {
    const words = input.trim().split(/\s+/)
    if (words.length > MAX_EMAIL_WORDS_LENGTH) {
      return 'ERROR'
    }
    return true
  }
  const formatMessageError = () => {
    if (formMethods.formState.errors.message?.type === 'required') {
      return t('createMessage.form.messageRequired')
    } else if (formMethods.formState.errors.message?.type === 'unsafeLink') {
      return t('createMessage.form.unsafeLink')
    } else {
      return t('createMessage.form.messageCharLimit', {
        maxWordsLength: MAX_EMAIL_WORDS_LENGTH,
        words: formMethods
          .watch('message')
          .trim()
          .split(/\s+/)
          .filter((char) => !!char).length
      })
    }
  }

  useEffect(() => {
    if (messageContent?.includes('<a href=') && !messageContent.includes('https://')) {
      formMethods.setError('message', {
        type: 'unsafeLink',
        message: 'Unsafe link provided'
      })
    }
  }, [messageContent])

  const columns = AttachmentListRows({setShowImagePreview, files, setFiles})

  const handleDownload = (image: File) => {
    fileDownload(image, image?.name)
  }

  const handleDelete = (image: File) => {
    setFiles(files.filter((file) => file.name !== image.name))
  }

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: isTablet ? '100%' : '70%',
          height: '90%'
        }}
      >
        {!isReadonly && <SelectChannelInput formMethods={formMethods} />}
        {!isReadonly && (
          <Controller
            name="subject"
            control={formMethods.control}
            rules={{
              required: channelType !== 'sms',
              minLength: channelType === 'sms' ? 0 : 1
            }}
            render={({field}) => {
              return (
                <Box style={{display: 'flex', flexDirection: 'column'}}>
                  <TextField
                    {...field}
                    placeholder={t('createMessage.form.subject')}
                    style={{
                      marginTop: '26px',
                      borderRadius: '4px',
                      border: '1.5px solid rgba(0, 39, 77, 0.15)',
                      padding: '6px 16px',
                      display: channelType === 'sms' ? 'none' : undefined
                    }}
                    value={field.value?.trimStart()}
                    type="text"
                    variant="standard"
                    data-test-id="create-message-title"
                    InputProps={{disableUnderline: true, readOnly: isReadonly}}
                    error={!!formMethods.formState.errors.subject}
                  />
                  {!!formMethods.formState.errors.subject && (
                    <Typography
                      variant="caption"
                      color="error"
                      style={{
                        marginBottom: '20px',
                        padding: '0 16px',
                        visibility: channelType === 'Email' ? 'visible' : 'hidden'
                      }}
                    >
                      {t('createMessage.form.subjectRequired')}
                    </Typography>
                  )}
                </Box>
              )
            }}
          />
        )}
        <Controller
          name="message"
          control={formMethods.control}
          disabled={isReadonly}
          rules={{
            required: true,
            minLength: 1,
            validate: (input) => (channelType === 'Email' ? validateWordCount(input) : true)
          }}
          // eslint-disable-next-line complexity
          render={({field}) => {
            return (
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                {channelType === 'sms' ? (
                  <TextField
                    {...field}
                    onChange={(event) => {
                      event.preventDefault()
                      const inputValue = event.target.value
                      field.onChange(inputValue)
                      setMessageInfo(new SegmentedMessage(inputValue))
                    }}
                    onPaste={(event) => {
                      event.preventDefault()
                      const inputValue = event.clipboardData.getData('text')
                      field.onChange(field.value + inputValue)
                      setMessageInfo(new SegmentedMessage(field.value + inputValue))
                    }}
                    multiline={true}
                    minRows={isMobile ? 4 : 8}
                    placeholder={t('createMessage.form.messageContent')}
                    style={{
                      marginTop: '16px',
                      marginBottom: formMethods.formState.errors.message ? '16px' : '36px',
                      borderRadius: '4px',
                      border: '1.5px solid rgba(0, 39, 77, 0.15)',
                      padding: '6px 16px',
                      overflowY: 'auto',
                      maxHeight: '300px'
                    }}
                    type="text"
                    variant="standard"
                    data-test-id="create-message-content"
                    InputProps={{
                      disableUnderline: true,
                      readOnly: isReadonly
                    }}
                    error={!!formMethods.formState.errors.message}
                  />
                ) : (
                  <Box
                    style={{
                      marginTop: '16px',
                      marginBottom: formMethods.formState.errors.message ? '16px' : '36px'
                    }}
                    data-test-id="create-message-content"
                  >
                    <RTEInput
                      {...field}
                      readOnly={isReadonly}
                      onChange={(value) => {
                        const trimmedEmptyValue = value === '<div><br></div>' ? '' : value
                        field.onChange(trimmedEmptyValue)
                      }}
                      placeholder={t('createMessage.form.messageContent')}
                    />
                  </Box>
                )}
                {!!formMethods.formState.errors.message && (
                  <Typography
                    variant="caption"
                    color="error"
                    style={{marginBottom: '16px', padding: '0 16px'}}
                  >
                    {formatMessageError()}
                  </Typography>
                )}
                {channelType === 'Email' && !isReadonly && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      gap: isMobile ? '8px' : 0,
                      alignItems: isMobile ? 'start' : 'center',
                      justifyContent: 'space-between',
                      marginBottom: '12px'
                    }}
                  >
                    <Box style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                      <Typography variant="h4" style={{color: '#54708C'}}>
                        {t('createMessage.form.attachments.title')}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          fontSize: '14px',
                          color:
                            formMethods.formState.errors.attachments?.type === 'size'
                              ? '#DA0901'
                              : '#52667A'
                        }}
                      >
                        {files.length > 1 || files.length <= 0
                          ? t('createMessage.form.attachments.secondaryTitlePlural', {
                              numberOfAttachments: files.length,
                              currentSize: files
                                .map(
                                  (attachment) =>
                                    attachment?.size &&
                                    Math.round(
                                      (attachment?.size / (1024 * 1024) + Number.EPSILON) * 100
                                    ) / 100
                                )
                                .reduce((a, b) => {
                                  if (a !== undefined && b !== undefined) {
                                    return a + b
                                  }
                                  return 0
                                }, 0)
                                .toFixed(2),
                              maxSize: FILE_MAX_SIZE_IN_MB
                            })
                          : t('createMessage.form.attachments.secondaryTitleSingular', {
                              currentSize: files
                                .map(
                                  (attachment) =>
                                    attachment.size &&
                                    Math.round(
                                      (attachment.size / (1024 * 1024) + Number.EPSILON) * 100
                                    ) / 100
                                )
                                .reduce((a, b) => {
                                  if (a !== undefined && b !== undefined) {
                                    return a + b
                                  }
                                  return 0
                                }, 0)
                                .toFixed(2),
                              maxSize: FILE_MAX_SIZE_IN_MB
                            })}
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      onClick={() => setShowAttachmentDialog(true)}
                      style={{
                        color: 'rgba(1, 106, 212, 1)',
                        fontWeight: 500,
                        marginLeft: isMobile ? '-16px' : 0
                      }}
                      startIcon={<AttachFile style={{color: 'rgba(1, 106, 212, 1)'}} />}
                    >
                      {t('createMessage.form.attachments.add')}
                    </Button>
                  </Box>
                )}
                {channelType === 'Email' && !isReadonly && (
                  <ResponsiveTable
                    columns={columns}
                    rows={files}
                    onSort={() => null}
                    keyField="name"
                    stickyHeader={true}
                    isMobile={isMobile}
                    emptyMessage={t('createMessage.form.attachments.empty')}
                    tableHeight={isTablet ? '15vh' : '20vh'}
                    scrollbarsVisible={true}
                  />
                )}
              </Box>
            )
          }}
        />
        <Box
          style={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {channelType === 'sms' && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '6px',
                marginBottom: '8px'
              }}
            >
              <Typography variant="caption">
                {t('createMessage.form.segmentsCount', {
                  segmentsCount: messageInfo?.segmentsCount || 0
                })}
              </Typography>
              <Typography variant="caption">
                {t('createMessage.form.charactersCount', {
                  charactersCount: messageInfo?.numberOfCharacters || 0
                })}
              </Typography>
            </Box>
          )}
        </Box>
        {isTablet && (
          <Button
            startIcon={<ArrowForward fontSize="small" />}
            variant="contained"
            onClick={() => {
              window.scrollTo({top: 0, left: 0})
              setShowSummary(true)
            }}
            style={{
              textTransform: 'none',
              padding: '13px 16px',
              minWidth: '240px',
              background: '#016AD4',
              color: '#FFFFFF'
            }}
          >
            {t('announcements.form.viewSummary')}
          </Button>
        )}
      </Box>
      {showRecipients && (
        <SelectRecipientsDialog
          showRecipents={showRecipients}
          setShowRecipients={setShowRecipients}
          selectedAudiencesIds={selectedAudiencesIds}
          setSelectedAudiencesIds={setSelectedAudiencesIds}
        />
      )}
      {showAttachmentDialog && (
        <UploadAttachmentDialog
          showAttachmentDialog={showAttachmentDialog}
          setShowAttachmentDialog={setShowAttachmentDialog}
          files={files}
          setFiles={setFiles}
        />
      )}
      {showImagePreview && (
        <ImageViewer
          initialImage={showImagePreview}
          images={files}
          setShowDialog={setShowImagePreview}
          onDelete={handleDelete}
          onDownload={handleDownload}
        />
      )}
    </Box>
  )
}
