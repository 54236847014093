import {Product, User} from '@hconnect/apiclient'
import {TableColumnType, Typography, useTranslation} from '@hconnect/uikit'
import {Box, FormControlLabel, Switch, Tooltip} from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import {useInviteUser} from '../hooks/useInviteUser'

import {useStyles} from './InviteUser.styles'

export type InviteUserProps = {
  product: Product
  lastActivityDate: string
  isInvited: boolean
}

type InviteUserRowsProps = {
  user?: User
  refetchUser: () => void
}
export const InviteUserRows = ({refetchUser, user}: InviteUserRowsProps) => {
  const {t} = useTranslation()
  const {inviteUser} = useInviteUser(user?.id, refetchUser)
  const {classes} = useStyles()

  const rowsPool: {[key: string]: TableColumnType} = {
    product: {
      field: 'product',
      renderHeader: (
        <Typography
          style={{fontSize: '12px', textTransform: 'none', fontWeight: 400, color: '#52667A'}}
        >
          {t('quickPreview.productInvited')}
        </Typography>
      ),
      headerAlign: 'left',
      renderCell: (item: InviteUserProps) => {
        return (
          <FormControlLabel
            value="top"
            disabled={item.isInvited}
            control={
              <Tooltip
                title={
                  item.isInvited
                    ? item.product === Product.Hub
                      ? t('quickPreview.hubCannotUnInvite')
                      : t('quickPreview.onsiteCannotUnInvite')
                    : ''
                }
              >
                <span>
                  <Switch
                    color="primary"
                    checked={item.isInvited}
                    disabled={item.isInvited}
                    onChange={async () => {
                      if (!item.isInvited) {
                        await inviteUser(item.product)
                      }
                    }}
                    data-test-id={`invite-product-${item.product}`}
                    classes={{
                      track: clsx({
                        [classes.trackEnabled]: item.isInvited
                      })
                    }}
                  />
                </span>
              </Tooltip>
            }
            label={item.product}
            labelPlacement="end"
            aria-readonly={item.isInvited}
            data-test-id={`invite-product-${item.product}-label`}
          />
        )
      }
    },
    lastActivityDate: {
      field: 'lastActivityDate',
      renderHeader: (
        <Typography
          style={{fontSize: '12px', textTransform: 'none', fontWeight: 400, color: '#52667A'}}
        >
          {t('quickPreview.lastActivityDate')}
        </Typography>
      ),
      headerAlign: 'left',
      renderCell: (item: InviteUserProps) => (
        <Box
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography>{item.lastActivityDate}</Typography>
        </Box>
      )
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.product,
    rowsPool.lastActivityDate
  ]

  return rowOrder(rowsPool)
}
