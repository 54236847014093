import {LoadingButton, Typography, useBreakPoints} from '@hconnect/uikit'
import {ArrowBack, ArrowBackIos, ArrowForwardIos, Close, DeleteOutlined} from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/Download'
import {Box, Button, Dialog, DialogContent, IconButton} from '@mui/material'
import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './ImageViewer.styles'

type ImageViewerProps = {
  images: File[]
  setShowDialog: (file?: File) => void
  initialImage?: File
  onDownload?: (image: File) => void
  onDelete?: (image: File) => void
  isDownloading?: boolean
  isDeleting?: boolean
}
// eslint-disable-next-line complexity
export const ImageViewer = ({
  initialImage,
  images,
  setShowDialog,
  onDelete,
  onDownload,
  isDownloading,
  isDeleting
}: ImageViewerProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [activeImage, setActiveImage] = useState<File>(initialImage || images[0])
  const startHorizontalSwipe = useRef(0)
  const endHorizontalSwipe = useRef(0)

  const handleSwitchImages = (direction: 'left' | 'right') => {
    const index = images.findIndex((image) => activeImage.name === image.name)
    if (direction === 'left') {
      if (index > 0) {
        setActiveImage(images[index - 1])
      }
    }
    if (direction === 'right') {
      if (index < images.length - 1) {
        setActiveImage(images[index + 1])
      }
    }
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    startHorizontalSwipe.current = event.touches[0].clientX
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    endHorizontalSwipe.current = event.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const distance = startHorizontalSwipe.current - endHorizontalSwipe.current

    if (distance > 50) {
      handleSwitchImages('right')
    } else if (distance < -50) {
      handleSwitchImages('left')
    }
  }

  return (
    <Dialog
      data-test-id="image-viewer-root"
      open={!!images.length}
      onClose={() => setShowDialog(undefined)}
      classes={{paper: classes.overlay, root: classes.backdrop}}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'space-between' : 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
          width: '100%'
        }}
      >
        <IconButton
          onClick={() => setShowDialog(undefined)}
          style={{
            border: 'none',
            boxShadow: 'none',
            marginRight: '10px',
            alignSelf: 'flex-end',
            color: '#FFFFFF',
            margin: '32px',
            visibility: isMobile ? 'visible' : 'hidden'
          }}
          data-test-id="mobile-image-viewer-close-button"
        >
          <ArrowBack style={{color: '#FFFFFF'}} />
        </IconButton>
        <Typography
          style={{
            color: '#FFFFFF',
            visibility: isMobile ? 'visible' : 'hidden',
            textAlign: 'center'
          }}
        >
          {activeImage.name}
        </Typography>
        <Button
          variant="text"
          onClick={() => setShowDialog(undefined)}
          style={{
            border: 'none',
            boxShadow: 'none',
            marginRight: '10px',
            alignSelf: 'flex-end',
            color: '#FFFFFF',
            margin: isMobile ? 0 : '32px',
            visibility: isMobile ? 'hidden' : 'visible'
          }}
          data-test-id="image-viewer-close-button"
          startIcon={<Close style={{color: '#FFFFFF'}} />}
        >
          {t('imageViewer.close')}
        </Button>
      </Box>
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: isMobile ? 0 : undefined
        }}
      >
        {!isMobile && (
          <IconButton
            style={{
              boxShadow: 'none',
              height: '64px',
              width: '64px',
              visibility: activeImage.name !== images[0].name ? 'visible' : 'hidden'
            }}
            onClick={() => handleSwitchImages('left')}
          >
            <ArrowBackIos
              style={{height: '64px', width: '64px', marginRight: '-24px', color: '#FFFFFF'}}
            />
          </IconButton>
        )}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '12px',
            height: isMobile ? '100%' : 'auto',
            minWidth: isMobile ? '100%' : '600px'
          }}
        >
          <img
            src={URL.createObjectURL(activeImage)}
            alt={activeImage.name}
            style={{maxWidth: isMobile ? '100%' : '1000px', margin: isMobile ? 'auto' : 0}}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'end' : 'space-between',
              alignItems: isMobile ? 'end' : 'start',
              width: '100%',
              gap: '12px'
            }}
          >
            {!isMobile && <Typography style={{color: '#FFFFFF'}}>{activeImage.name}</Typography>}
            <Box style={{display: 'flex', gap: '12px', margin: isMobile ? '8px' : 0}}>
              {onDownload && (
                <LoadingButton
                  onClick={() => onDownload(activeImage)}
                  loading={isDownloading}
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  btnClassName={classes.downloadButton}
                  data-test-id="image-viewer-download"
                >
                  {t('imageViewer.download')}
                </LoadingButton>
              )}
              {onDelete && (
                <LoadingButton
                  onClick={() => {
                    onDelete(activeImage)
                    images.length > 1
                      ? handleSwitchImages(activeImage.name !== images[0].name ? 'left' : 'right')
                      : setActiveImage(images[0])
                  }}
                  loading={isDeleting}
                  variant="contained"
                  startIcon={<DeleteOutlined />}
                  btnClassName={classes.deleteButton}
                  data-test-id="image-viewer-delete"
                >
                  {t('imageViewer.delete')}
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Box>
        {!isMobile && (
          <IconButton
            style={{
              boxShadow: 'none',
              height: '64px',
              width: '64px',
              visibility: activeImage.name !== images[images.length - 1].name ? 'visible' : 'hidden'
            }}
            onClick={() => handleSwitchImages('right')}
          >
            <ArrowForwardIos style={{height: '64px', width: '64px', color: '#FFFFFF'}} />
          </IconButton>
        )}
      </DialogContent>
    </Dialog>
  )
}
