import {LoadingButton} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type DeleteAudienceDialogProps = {
  showDialog: boolean
  setShowDialog: (show: boolean) => void
  isLoading: boolean
  handleDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const DeleteAudienceDialog = ({
  showDialog,
  setShowDialog,
  handleDelete,
  isLoading
}: DeleteAudienceDialogProps) => {
  const {t} = useTranslation()

  return (
    <Dialog
      data-test-id="delete-audience-dialog"
      open={showDialog}
      onClose={() => setShowDialog(false)}
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{t('audiences.deleteAudienceDialog.dialogTitle')}</DialogTitle>
        <IconButton
          onClick={() => setShowDialog(false)}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent style={{width: '400px'}}>
        <DialogContentText>{t('audiences.deleteAudienceDialog.dialogMessage')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={handleDelete}
          loading={isLoading}
          variant="text"
          color="primary"
          style={{textTransform: 'none', fontSize: '16px', fontWeight: 600, lineHeight: '22px'}}
          data-test-id="delete-audience-dialog-action-delete"
        >
          {t('audiences.deleteAudienceDialog.dialogActionDelete')}
        </LoadingButton>

        <Button
          onClick={() => setShowDialog(false)}
          variant="text"
          color="primary"
          data-test-id="delete-audience-dialog-action-cancel"
        >
          {t('audiences.deleteAudienceDialog.dialogActionCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
