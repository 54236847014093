import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '1000px !important',
    width: '1000px',
    [theme.breakpoints.down('sm')]: {
      top: undefined,
      height: '85vh',
      width: '100%',
      margin: '0 !important'
    }
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      top: 'initial !important'
    }
  },
  dialogContentText: {
    color: theme.palette.text.primary
  },
  loadingIndicator: {
    color: '#FFFFFF !important'
  },
  confirmDisabled: {
    opacity: 0.5,
    color: '#FFFFFF !important'
  }
}))
