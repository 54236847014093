import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  activeDropdown: {
    width: '100%',
    maxWidth: '100%'
  },
  saveButton: {
    textTransform: 'none',
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    background: '#016AD4',
    color: '#FFFFFF',
    width: '100%'
  },
  saveButtonDisabled: {
    opacity: 0.3,
    color: '#FFFFFF !important',
    background: '#016AD4 !important',
    textTransform: 'capitalize'
  }
}))
