import {TextFields} from '@mui/icons-material'
import React, {useEffect, useMemo, useRef} from 'react'
import ReactDOM from 'react-dom'
import ReactQuill, {Quill, ReactQuillProps} from 'react-quill'
import './quill.snow.css'

import {IndentAttributor} from '../utils/IndentAttributor'
import {ListAlignmentAttributor} from '../utils/ListAlignmentAttributor'

import {useStyles} from './RTEInput.styles'

const Block = Quill.import('blots/block')
Block.tagName = 'DIV'
const IndentStyle = new IndentAttributor()
const ListIndentStyle = new ListAlignmentAttributor()
const Inline = Quill.import('blots/inline')
const AlignStyle = Quill.import('attributors/style/align')
const DirectionStyle = Quill.import('attributors/style/direction')
const ListContainer = Quill.import('formats/list')
const ListItem = Quill.import('formats/list/item')
Quill.register(Block, true)
Quill.register(Inline, true)
Quill.register(AlignStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(ListContainer, true)
Quill.register(ListItem, true)
Quill.register(IndentStyle, true)
Quill.register(ListIndentStyle, true)

type RTEInputProps = ReactQuillProps & {
  readOnly?: boolean
}

export const stripHtmlTags = (html: string, whitelist: string[]) => {
  if (whitelist.length === 0) return html.replace(/<[^>]*>/gi, '')
  return html.replace(new RegExp(`<(?!/?(${whitelist.join('|')}))[^>]*>`, 'gi'), '')
}
export const RTEInput = (props: RTEInputProps) => {
  const quillRef = useRef<ReactQuill>(null)
  const {classes} = useStyles()

  useEffect(() => {
    const quill = quillRef.current?.getEditor()

    if (quill && !props.readOnly) {
      const toolbar = quill.getModule('toolbar')
      const picker = toolbar.container.querySelector('.ql-picker-label')
      if (picker) {
        picker.innerHTML = ''
        ReactDOM.render(<TextFields style={{marginTop: '-12px'}} />, picker)
      }
    }
  }, [])

  const modules = useMemo(
    () => ({
      keyboard: {
        bindings: {
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: (range: {index: number; length: number}) => {
              const editor = quillRef.current?.getEditor()
              editor?.insertText(range.index, '\n', 'user')
              editor?.setSelection(range.index + 1, 0, 'silent')
              editor?.format('linebreak', true, 'user')
              return false
            }
          }
        }
      },
      toolbar: {
        container: [
          ['bold'],
          ['italic'],
          ['underline'],
          [{header: [1, 2, false]}],
          [{color: []}],
          ['link'],
          [{list: 'ordered'}],
          [{list: 'bullet'}],
          [{indent: '-1'}],
          [{indent: '+1'}],
          [{align: ''}],
          [{align: 'center'}],
          [{align: 'right'}],
          ['clean']
        ]
      }
    }),
    [quillRef]
  )

  const readOnlyModules = useMemo(
    () => ({
      toolbar: false
    }),
    [quillRef]
  )

  return (
    <ReactQuill
      {...props}
      ref={quillRef}
      modules={props.readOnly ? readOnlyModules : modules}
      className={props.readOnly ? classes.noQuillBorder : props.className}
      theme="snow"
      formats={
        props.readOnly
          ? undefined
          : [
              'bold',
              'italic',
              'underline',
              'list',
              'bullet',
              'indent',
              'link',
              'header',
              'color',
              'align'
            ]
      }
    />
  )
}
