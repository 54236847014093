import {Typography, useBreakPoints} from '@hconnect/uikit'
import {Add, ArrowForward} from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Box, Button, Chip, TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React, {useEffect, useState} from 'react'
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormReturn
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {FeatureConstraintInput} from '../dialogs/FeatureConstraint'

import {FeatureFormData} from './FeatureCreate'
import {useStyles} from './FeatureCreate.styles'

type FeatureCreateFormProps = {
  formMethods: UseFormReturn<FeatureFormData>
  fields: FieldArrayWithId<FeatureFormData>[]
  append: UseFieldArrayAppend<FeatureFormData>
  remove: UseFieldArrayRemove
  tags: string[]
  selectedTags: string[]
  setSelectedTags: (tags: string[]) => void
  selectedInput: string
  setSelectedInput: (input: string) => void
  setShowSummary: (showSummary: boolean) => void
}
export const FeatureCreateForm = ({
  formMethods,
  fields,
  append,
  remove,
  tags,
  selectedTags,
  setSelectedTags,
  selectedInput,
  setSelectedInput,
  setShowSummary
}: FeatureCreateFormProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {featureName} = useParams<{featureName?: string}>()
  const screenSizes = useBreakPoints()
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const [featureNameValue, setFeatureNameValue] = useState(featureName || '')
  const [validateFeatureName, setValidateFeatureName] = useState(false)

  useEffect(() => {
    if (validateFeatureName) {
      formMethods.setValue('featureName', featureNameValue, {
        shouldValidate: true
      })
    } else {
      setValidateFeatureName(true)
    }
  }, [featureNameValue])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event.target.value
    const removeAllSpaces = input.replace(/\s+/g, '')
    setFeatureNameValue(removeAllSpaces)
  }

  const formatFeatureNameHelperText = () => {
    const errorType = formMethods.formState.errors.featureName?.type
    if (errorType === 'required') {
      return t('features.form.featureNameRequired')
    }

    if (errorType === 'minLength') {
      return t('features.form.featureNameMinLength')
    }
  }

  return (
    <Box style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <Controller
        name="featureName"
        control={formMethods.control}
        rules={{
          required: true,
          minLength: 2
        }}
        render={({field}) => {
          return (
            <TextField
              {...field}
              data-test-id={`create-new-feature-${field.name}`}
              fullWidth
              label={t('features.form.featureName')}
              type="text"
              variant="standard"
              style={{
                borderRadius: '4px',
                border: '1.5px solid rgba(0, 39, 77, 0.15)',
                padding: '6px 16px'
              }}
              onChange={(event) => handleInputChange(event)}
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                style: {color: '#54708C', padding: '6px 16px'}
              }}
              onKeyDown={(event) => handleKeyDown(event)}
              disabled={!!featureName}
              focused={true}
              error={!!formMethods.formState.errors.featureName}
              helperText={formatFeatureNameHelperText()}
            />
          )
        }}
      />
      <Controller
        name="featureDescription"
        control={formMethods.control}
        rules={{
          required: true
        }}
        render={({field}) => {
          return (
            <TextField
              {...field}
              data-test-id={`create-new-feature-${field.name}`}
              fullWidth
              label={t('features.form.featureDescription')}
              multiline={true}
              minRows={2}
              type="text"
              variant="standard"
              style={{
                borderRadius: '4px',
                border: '1.5px solid rgba(0, 39, 77, 0.15)',
                padding: '6px 16px'
              }}
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                style: {color: '#54708C', padding: '6px 16px'}
              }}
              focused={true}
              error={!!formMethods.formState.errors.featureDescription}
              helperText={
                !!formMethods.formState.errors.featureDescription &&
                t('features.form.featureDescriptionRequired')
              }
            />
          )
        }}
      />
      <Controller
        name="featureTags"
        control={formMethods.control}
        rules={{
          required: true
        }}
        render={({field}) => {
          return (
            <Autocomplete
              multiple
              freeSolo
              forcePopupIcon={true}
              disableClearable={true}
              popupIcon={<KeyboardArrowDownIcon style={{color: '#00274D'}} />}
              className={classes.autocomplete}
              value={selectedTags}
              inputValue={selectedInput}
              onChange={(e, value) => {
                setSelectedTags(value as string[])
              }}
              onInputChange={(event, newInputValue) => {
                const options = newInputValue.split(',')
                if (options.length > 1) {
                  setSelectedTags(
                    selectedTags
                      .concat(options)
                      .map((x) => x.trim())
                      .filter((x) => x)
                  )
                } else {
                  setSelectedInput(newInputValue)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  data-test-id={`create-new-feature-${field.name}`}
                  label={t('features.form.featureTags')}
                  style={{color: '#54708C'}}
                  focused={true}
                  error={!!formMethods.formState.errors.featureTags}
                  helperText={
                    !!formMethods.formState.errors.featureTags &&
                    t('features.form.featureTagsRequired')
                  }
                />
              )}
              options={tags}
              renderTags={(value: readonly string[]) =>
                value.map((option: string, index: number) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option}
                    style={{backgroundColor: '#016AD4', color: 'white'}}
                  />
                ))
              }
            />
          )
        }}
      />
      <Typography style={{fontSize: '18px', fontWeight: 600}}>
        {t('features.form.featureConstraints.title')}
      </Typography>

      <Box style={{display: 'flex', flexDirection: 'column'}}>
        {fields.map((field, index) => (
          <FeatureConstraintInput
            key={field.id}
            formMethods={formMethods}
            index={index}
            remove={remove}
          />
        ))}
        <Button
          variant="text"
          color="primary"
          onClick={() => append({dataScope: '', type: '', enabled: false})}
          className={classes.addConstraintButton}
          data-test-id="button-add-new-feature-constraint"
        >
          <Add className={classes.icon} style={{color: '#016AD4'}} />
          {t('features.form.featureConstraints.add')}
        </Button>
      </Box>
      {isTablet && (
        <Box className={classes.viewSummary}>
          <Button
            startIcon={<ArrowForward fontSize="small" />}
            variant="contained"
            onClick={() => {
              window.scrollTo({top: 0, left: 0})
              setShowSummary(true)
            }}
            className={classes.viewSummaryButton}
          >
            {t('features.form.summary')}
          </Button>
        </Box>
      )}
    </Box>
  )
}
