import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.store'

export interface Audit {
  eventDateTime: string | Date
  modifiedBy: string
  action: 'added' | 'modified' | 'deleted'
  oldValue: unknown
  newValue: unknown
}

interface ErrorResponse {
  error: Error
}

export const useAuditInformation = (auditType: string, userIdentifier: string) => {
  return useQuery<AxiosResponse<Audit[]>, ErrorResponse>(
    [auditType, {userIdentifier}],
    async () => {
      try {
        const url =
          auditType === 'user'
            ? `/identity/audit/${userIdentifier}`
            : `/permissions/audit/${userIdentifier}`

        return await api.get(url)
      } catch (e) {
        throw new Error('Error during fetching audit logs')
      }
    },
    {
      enabled: Boolean(userIdentifier)
    }
  )
}
