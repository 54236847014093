import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  backButton: {
    flex: '1 0 100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  pageNav: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.6,
    marginBottom: '-12px',
    marginLeft: '-12px'
  },
  pageTitle: {
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: 600,
    marginLeft: '8px'
  },
  pageAboveTitle: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '24px'
    }
  },
  pageContent: {
    marginBottom: '22px',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      width: '100%'
    }
  },
  activeDropdown: {
    width: '100%',
    maxWidth: '100%'
  },
  saveButton: {
    textTransform: 'none',
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    background: '#016AD4',
    color: '#FFFFFF',
    width: '100%'
  },
  saveButtonDisabled: {
    opacity: 0.3,
    color: '#FFFFFF !important',
    background: '#016AD4 !important',
    textTransform: 'capitalize'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  summaryActionsContainer: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    marginTop: '40px'
  },
  discardButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '13px 16px',
    color: '#00274D',
    fontWeight: 500,
    width: '100%',
    border: '1px solid rgba(0, 39, 77, 0.1)',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',

    '&:hover': {
      border: '1px solid rgba(0, 39, 77, 0.1) !important'
    }
  },
  discardButtonDisabled: {
    opacity: 0.3,
    color: '#00274D !important'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  deleteButton: {
    marginBottom: '24px',
    background: 'rgba(255, 255, 255, 0.05)',
    fontWeight: 500,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: '12px'
    }
  },
  disableAutofill: {
    '& input:-internal-autofill-selected': {
      '-webkit-box-shadow': '0 0 0 1000px white inset !important'
    }
  },
  autocomplete: {
    '.MuiIconButton-root': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none'
    },
    '.MuiOutlinedInput-input': {
      padding: '0 16px'
    },
    paddingTop: '10px',
    borderRadius: '4px',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    marginBottom: '22px'
  },
  viewSummary: {
    position: 'relative',
    bottom: '-1px',
    paddingTop: 'calc(1em + 1px)',
    marginTop: '-10px',
    width: '100%',
    left: 0,
    right: 0,
    background: '#FFFFFF',
    height: '110px',
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    zIndex: 0
  },
  viewSummaryButton: {
    textTransform: 'none',
    padding: '13px 16px',
    minWidth: '240px',
    background: '#016AD4',
    color: '#FFFFFF'
  },
  addConstraintButton: {
    color: '#016AD4',
    fontWeight: 500,
    alignSelf: 'flex-start',
    marginTop: '34px'
  }
}))
