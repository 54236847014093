import {useMutation} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useEffect, useState} from 'react'

import {api} from '../../../../App.store'

type CountryPrice = {
  priceUnit: string
  segmentPrice: number
  carrierFees: number
  recipientsWithPhoneNumber: number
  recipientsWithoutPhoneNumber: number
  isTwilioPriceInfoFound: boolean
}
type SmsPriceResponse = {
  [countryCode: string]: CountryPrice
}
const fetchSmsPriceConfig = async (selectedAudienceIds: number[]) => {
  const response: AxiosResponse<SmsPriceResponse> = await api.post(
    '/notifications/Audience/SmsPriceConfigForAudience',
    selectedAudienceIds
  )
  return response.data
}
export const useCalculateAudienceSms = (segmentsCount: number, charactersCount: number) => {
  const [totalPrice, setTotalPrice] = useState(0)
  const [phoneNumberRecipients, setPhoneNumberRecipients] = useState(0)

  const {mutate, data, isLoading} = useMutation(fetchSmsPriceConfig)

  useEffect(() => {
    if (data) {
      const calculatedPrice = Object.keys(data)
        .map((key) => {
          const countryData = data[key]
          return (
            countryData.recipientsWithPhoneNumber *
            (segmentsCount * countryData.segmentPrice + segmentsCount * countryData.carrierFees)
          )
        })
        .reduce((acc, item) => {
          return acc + item
        }, 0)

      const calculatedRecipients = Object.keys(data)
        .map((key) => {
          const countryData = data[key]
          return countryData.recipientsWithPhoneNumber
        })
        .reduce((acc, item) => {
          return acc + item
        }, 0)

      setPhoneNumberRecipients(calculatedRecipients)
      setTotalPrice(
        charactersCount === 0 ? 0 : Math.round((calculatedPrice + Number.EPSILON) * 100) / 100
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentsCount, charactersCount, data])

  return {totalPrice, mutateSmsPrice: mutate, isLoadingSmsPrice: isLoading, phoneNumberRecipients}
}
