import {Quill} from 'react-quill'

const Parchment = Quill.import('parchment')

export class ListAlignmentAttributor extends Parchment.Attributor.Style {
  constructor() {
    super('align', 'text-align', {
      scope: Parchment.Scope.BLOCK
    })
  }

  add(node: HTMLElement, value: string): boolean {
    if (['left', 'center', 'right', 'justify'].indexOf(value) === -1) {
      return false
    }

    node.style.setProperty('text-align', value, 'important')

    if (node.tagName === 'LI') {
      const parentNode = node.parentNode as HTMLElement
      if (parentNode && (parentNode.tagName === 'UL' || parentNode.tagName === 'OL')) {
        parentNode.style.setProperty('text-align', value, 'important')
        parentNode.style.setProperty('list-style-position', 'inside', 'important')
      }
    }

    return super.add(node, value)
  }

  remove(node: HTMLElement) {
    node.style.removeProperty('text-align')

    if (node.tagName === 'LI') {
      const parentNode = node.parentNode as HTMLElement
      if (parentNode && (parentNode.tagName === 'UL' || parentNode.tagName === 'OL')) {
        parentNode.style.removeProperty('text-align')
        parentNode.style.removeProperty('list-style-position')
      }
    }

    super.remove(node)
  }
}
