import {Tab, Tabs} from '@material-ui/core'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type SelectPillarsInputProps = AnnouncementFormProps

export const SelectPillarsInput = ({formMethods}: SelectPillarsInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const pillarType = formMethods.watch('pillarType')
  const mappedPillarType = pillarType === 'hConnect' ? 0 : 1

  useEffect(() => {
    formMethods.resetField('products')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillarType])

  return (
    <Tabs
      value={mappedPillarType}
      TabIndicatorProps={{
        style: {
          display: 'none'
        }
      }}
      onChange={(_, value) =>
        formMethods.setValue('pillarType', value === 0 ? 'hConnect' : 'hProduce')
      }
      className={classes.tabContainer}
    >
      <Tab
        data-test-id="pillar-hconnect"
        label={t('announcements.form.pillarHConnect')}
        className={classes.firstTab}
        classes={{
          selected: classes.tabSelected
        }}
      />
      <Tab
        data-test-id="pillar-hproduce"
        label={t('announcements.form.pillarHProduce')}
        className={classes.secondTab}
        classes={{
          selected: classes.tabSelected
        }}
      />
    </Tabs>
  )
}
