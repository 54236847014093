import {Product, User} from '@hconnect/apiclient'
import {useMutation} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {api} from '../../../App.store'

type InviteUserParams = {
  user: User
  product: Product | null
}

const inviteUser = async (params?: InviteUserParams) => {
  const response: AxiosResponse = await api.post('/users/invite', {
    user_id: params?.user.id,
    product: params?.product
  })
  return response.data
}
export const useInviteUser = (
  product: Product,
  setAlreadyInvited: (isInvited: boolean) => void,
  setIsPolling: (isPolling: boolean) => void,
  user?: User | null,
  refetchUser?: () => void
) => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()

  return useMutation(inviteUser, {
    onSuccess: () => {
      refetchUser?.()
      setIsPolling(false)
      enqueueSnackbar(
        `${t('createUser.invitationSent', {
          name: user?.name || user?.eMail
        })} ${product}`,
        {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success'
        }
      )
    },
    onError: (e) => {
      const error = e as AxiosError<{detail?: string; title?: string}>
      if (product === Product.Datalink && error.response?.status === 400) {
        setAlreadyInvited(true)
      } else if (error.response?.status === 403 && product === Product.HProduce) {
        setIsPolling(true)
      } else {
        enqueueSnackbar(
          (error.response?.data?.detail || error.response?.data?.title) ?? error.message,
          {variant: 'error'}
        )
      }
      refetchUser?.()
    },
    retry: false
  })
}
