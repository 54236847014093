import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './DeleteUserDialog.styles'

type DeleteUserDialogProps = {
  showDeleteUserDialog: boolean
  setShowDeleteUserDialog: (show: boolean) => void
  handleDeleteUser: () => Promise<void>
}
export const DeleteUserDialog = ({
  setShowDeleteUserDialog,
  showDeleteUserDialog,
  handleDeleteUser
}: DeleteUserDialogProps) => {
  const {t} = useTranslation()

  const classes = useStyles()
  return (
    <Dialog
      data-test-id="manage-user-dialog-delete-user"
      open={showDeleteUserDialog}
      onClose={() => setShowDeleteUserDialog(false)}
    >
      <DialogTitle>{t('manageUser.deleteUserDialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          {t('manageUser.deleteUserDialogMessage')}
        </DialogContentText>
        <DialogContentText className={`${classes.dialogContentText} ${classes.bold}`}>
          {t('manageUser.deleteUserDialogMessageWarning')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setShowDeleteUserDialog(false)}
          variant="text"
          color="primary"
          data-test-id="delete-user-dialog-action-cancel"
        >
          {t('manageUser.deleteUserDialogActionCancel')}
        </Button>
        <Button
          onClick={handleDeleteUser}
          variant="text"
          color="primary"
          data-test-id="button-delete-confirm"
        >
          {t('manageUser.deleteUserDialogActionOk')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
