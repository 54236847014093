import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  errorContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  errorMessage: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.dark,
    flex: 1
  }
}))
