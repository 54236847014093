import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: 'white'
  },
  chip: {
    color: theme.palette.common.white,
    backgroundColor: '#0F89FF',
    margin: '4px 8px 0 0'
  },
  mobileNumberContainer: {
    marginTop: '4px'
  },
  issue: {
    color: '#ffaf00',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  },
  rows: {
    display: 'flex'
  },
  issuesIcon: {
    marginLeft: 5
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap'
    }
  },
  manageButton: {
    display: 'flex',
    width: '100%',
    '&.MuiButton-root': {
      textTransform: 'none',
      whiteSpace: 'nowrap',
      padding: '9px 32px',
      fontSize: '16px',
      fontWeight: 500,
      color: '#00274D',
      letterSpacing: 0,
      border: '1px solid rgba(0, 39, 77, 0.1)',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px'
    }
  },
  columnHeader: {
    whiteSpace: 'nowrap'
  }
}))
