import {
  QueryFunction,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../../App.store'
import {Audience} from '../../Messages/Audiences/Audiences'

type CreateAudienceParams = {
  name: string
  description: string
  filters: string
}

type EditAudienceParams = {
  id: number
  name: string
  description: string
}

export type AudienceDetailsResponse = {
  id: number
  name: string
  filters: string
  description: string
  numberOfRecipients: number
  pastMessages: string[]
  createdBy: string
  createdOn: string
}

type AudienceQueryKey = [string, {id: number}]
const createAudience = async (params: CreateAudienceParams): Promise<AxiosResponse<Audience>> => {
  const response: AxiosResponse<Audience> = await api.post('/notifications/audience', params)
  return response
}

const editAudience = async (params: EditAudienceParams) => {
  const {id, ...otherParams} = params
  const response: AxiosResponse = await api.put(`/notifications/audience?id=${id}`, otherParams)
  return response.data
}

const deleteAudience = (id?: number) => async () => {
  return id && (await api.delete(`/notifications/audience?id=${id}`))
}

const audienceDetailsFetcher: QueryFunction<AudienceDetailsResponse> = async ({queryKey}) => {
  const [base, {id}] = queryKey as AudienceQueryKey
  try {
    const response = await api.get<AudienceDetailsResponse>(`/notifications/${base}?id=${id}`)
    return response.data
  } catch (e) {
    throw new Error('Error during fetching audience details')
  }
}

export const useAudience = (
  closeDetails?: (audience?: Audience) => void,
  id?: number,
  shouldRedirectToCreateMessage?: boolean,
  selectedAudiencesIds?: number[]
) => {
  const {enqueueSnackbar} = useSnackbar()
  const queryClient = useQueryClient()
  const history = useHistory()
  const {t} = useTranslation()

  const {mutate: createAudienceMutation, isLoading: isCreateAudienceLoading} = useMutation(
    createAudience,
    {
      onSuccess: (response: AxiosResponse<Audience>) => {
        enqueueSnackbar(
          response.status === 200
            ? t('usersList.audience.successReactivateMessage')
            : t('usersList.audience.successCreateMessage'),
          {
            anchorOrigin: {vertical: 'top', horizontal: 'center'},
            variant: 'success'
          }
        )
        closeDetails && closeDetails(shouldRedirectToCreateMessage ? response.data : undefined)
      },
      onError: (error: AxiosError<{detail: string}>) => {
        enqueueSnackbar(error.response?.data.detail, {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  const {mutate: editAudienceMutation, isLoading: isEditAudienceLoading} = useMutation(
    editAudience,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['/notifications/audience/paginatedAudiences'])
        enqueueSnackbar(t('usersList.audience.successUpdateMessage'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success'
        })
        closeDetails && closeDetails()
      },
      onError: (error: AxiosError<{detail: string}>) => {
        enqueueSnackbar(error.response?.data.detail, {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  const {mutate: deleteAudienceMutation, isLoading: isDeleteAudienceLoading} = useMutation(
    deleteAudience(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['/notifications/audience/paginatedAudiences'])
        enqueueSnackbar(t('usersList.audience.successDeleteMessage'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success'
        })
        closeDetails && closeDetails()
      },
      onError: (error: AxiosError<{detail: string}>) => {
        enqueueSnackbar(error.response?.data.detail, {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  const {
    data: audienceDetailsData,
    isLoading: isLoadingAudienceDetails,
    refetch: fetchAudienceDetails
  } = useQuery(['audience', {id}], audienceDetailsFetcher, {enabled: false})

  const audienceDetailsListResult = useQueries({
    queries: (selectedAudiencesIds || []).map((audienceId) => ({
      queryKey: ['audience', {id: audienceId}],
      queryFn: audienceDetailsFetcher,
      enabled:
        selectedAudiencesIds &&
        selectedAudiencesIds?.length > 0 &&
        history.location.pathname === '/messages/create'
    }))
  })

  return {
    createAudienceMutation,
    isCreateAudienceLoading,
    editAudienceMutation,
    isEditAudienceLoading,
    audienceDetailsData,
    isLoadingAudienceDetails,
    fetchAudienceDetails,
    deleteAudienceMutation,
    isDeleteAudienceLoading,
    fetchingNumberOfRecipents: audienceDetailsListResult.some((details) => details.isFetching),
    totalNumberOfRecipients: audienceDetailsListResult
      .map((details) => details.data?.numberOfRecipients)
      .filter((numberOfRecipents) => numberOfRecipents !== undefined)
      // @ts-ignore
      .reduce((a, b) => a + b, 0),
    audiencesNames: audienceDetailsListResult.map((details) => details.data?.name)
  }
}
