import {RoleAssignment} from '../../../modules/ManageUsers.selectors'

export const handleFilteredRoles = (
  roles: RoleAssignment[],
  loggedInUserRoles?: RoleAssignment[] | null,
  userRoles?: RoleAssignment[] | null
) => {
  loggedInUserRoles?.forEach((loggedInRole: RoleAssignment) => {
    const isCustomerIdExisting = !!loggedInRole.dataScope.customerIds
    const isPayerIdExisting = !!loggedInRole.dataScope.payerIds

    const filteredRoles = userRoles?.filter((userRole) => {
      const isCountryMatching = userRole.dataScope.countryId === loggedInRole.dataScope.countryId

      const isBusinessLineMatching =
        userRole.dataScope.businessLine === loggedInRole.dataScope.businessLine
      const isOrgUnitMatching = userRole.dataScope.orgUnitId === loggedInRole.dataScope.orgUnitId

      if (
        isBusinessLineMatching &&
        isOrgUnitMatching &&
        (!isCustomerIdExisting || !isPayerIdExisting)
      ) {
        return true
      }

      const isCustomerIncluded = isCustomerIdExisting
        ? Array.isArray(loggedInRole.dataScope.customerIds) &&
          loggedInRole.dataScope.customerIds.some(
            (id) => userRole.dataScope.customerIds?.includes(id)
          )
        : false
      const isPayerIncluded = isPayerIdExisting
        ? Array.isArray(loggedInRole.dataScope.payerIds) &&
          loggedInRole.dataScope.payerIds.some((id) => userRole.dataScope.payerIds?.includes(id))
        : false
      return isCountryMatching && (isCustomerIncluded || isPayerIncluded)
    })

    if (filteredRoles?.length) {
      filteredRoles?.forEach((filteredRole: RoleAssignment) => {
        const exists = roles.some((v) => v.id === filteredRole.id)

        if (!exists) {
          roles.push(filteredRole)
        }
      })
    }
  })
}
