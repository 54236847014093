import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  addIcon: {
    marginRight: theme.spacing(1)
  },
  createButton: {
    marginBottom: '8px',
    background: 'rgba(255, 255, 255, 0.05)',
    fontWeight: 500,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: '12px'
    }
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '22px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  pageGrid: {
    columnGap: '16px',
    alignItems: 'stretch'
  },
  paper: {
    padding: theme.spacing(3, 4.5),
    elevation: 4,
    height: '100%'
  },
  detailsPaper: {
    elevation: 4,
    height: '100%'
  },
  responsiveTablePaper: {
    padding: theme.spacing(0.5, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 4.5)
    }
  },
  detailsGrid: {
    padding: '26px 32px 26px 32px',
    height: '100%'
  }
}))
