import {Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {LoadingButton} from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Link
} from '@mui/material'
import classNames from 'classnames'
import clsx from 'clsx'
import {isEmpty} from 'lodash'
import upperFirst from 'lodash/upperFirst'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {DetailsField} from '../../components/DetailsField/DetailsField'
import {DiscardDialog} from '../Announcements/components/dialogs/DiscardDialog'
import {Audience} from '../Messages/Audiences/Audiences'
import {DeleteAudienceDialog} from '../Messages/dialogs/DeleteAudienceDialog'

import {useStyles} from './AudienceDetails.styles'
import {AudienceDetailsResponse, useAudience} from './hooks/useAudience'

type AudienceFilter = {
  name: string
  value: string
  filterName: string
  filterValue?: string
}

type AudienceFormProps = {
  audienceName: string
  audienceDescription: string
  audienceFilters: AudienceFilter[]
}
interface AudienceDetailsProps {
  filters: AudienceFilter[]
  handleClose: (audience?: Audience, discard?: boolean) => void
  handleDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void
  audience?: Audience
  isLoadingAdditionalDetails?: boolean
  additionalDetailsData?: AudienceDetailsResponse
  setShouldEvaluateAudiences?: (shouldEvaluate: boolean) => void
  setShouldCloseDialog?: (shouldClose: boolean) => void
}
// eslint-disable-next-line complexity
export const AudienceDetails = ({
  filters,
  handleClose,
  audience,
  isLoadingAdditionalDetails,
  additionalDetailsData,
  setShouldEvaluateAudiences,
  setShouldCloseDialog,
  handleDelete
}: AudienceDetailsProps) => {
  const screenSizes = useBreakPoints()
  const {t} = useTranslation()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {classes} = useStyles()
  const history = useHistory()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [shouldRedirectToCreateMessage, setShouldRedirectToCreateMessage] = useState(false)
  const [shouldOpenDeleteDialog, setShouldOpenDeleteDialog] = useState(false)
  const [shouldCloseDetails, setShouldCloseDetails] = useState(false)
  const [shouldOpenDiscardDialog, setShouldOpenDiscardDialog] = useState(false)
  const isBlocklistPresent = !!filters.find(
    (filter) => filter.filterName === 'blocklistNotifications' && filter.filterValue === 'true'
  )

  const {
    createAudienceMutation,
    isCreateAudienceLoading,
    editAudienceMutation,
    isEditAudienceLoading,
    isDeleteAudienceLoading
  } = useAudience(handleClose, undefined, shouldRedirectToCreateMessage)

  const {handleSubmit, clearErrors, formState, control, resetField, setValue} =
    useForm<AudienceFormProps>({
      reValidateMode: 'onChange'
    })

  useEffect(() => {
    if (audience) {
      resetField('audienceName', {
        defaultValue: audience.name
      })
    }
  }, [audience])

  useEffect(() => {
    if (additionalDetailsData?.description) {
      resetField('audienceDescription', {
        defaultValue: additionalDetailsData?.description
      })
    }
  }, [additionalDetailsData])

  useEffect(() => {
    if (shouldRedirect) {
      handleClose()
    }

    if (shouldCloseDetails) {
      handleClose(undefined, true)
    }
  }, [shouldRedirect, shouldCloseDetails])

  useEffect(() => {
    clearErrors('audienceFilters')
    setValue('audienceFilters', filters)
  }, [filters])

  const handleAudienceSubmit = (data: AudienceFormProps) => {
    const formattedFilters = filters.reduce((a, b) => {
      a[b.filterName] = b.filterValue || b.value
      return a
    }, {})

    const createMutationParams = {
      name: data.audienceName.trim(),
      description: data.audienceDescription,
      filters: JSON.stringify(formattedFilters)
    }

    const editMutationParams = {
      id: audience?.id || -1,
      name: data.audienceName.trim(),
      description: data.audienceDescription
    }

    audience
      ? editAudienceMutation(editMutationParams)
      : createAudienceMutation(createMutationParams)
  }

  const handleShowRecipients = () => {
    const queryParams = filters
      .map((filter) => `${encodeURIComponent(filter.name)}=${encodeURIComponent(filter.value)}`)
      .join('&')

    history.push(
      `/users?page=1&rowsPerPage=10&sortColumn=createdOn&sortDirection=desc&${queryParams}`
    )
  }

  return (
    <>
      <form style={{height: '100%'}} onSubmit={handleSubmit(handleAudienceSubmit)}>
        <Box
          data-test-id="audience-details"
          style={{display: 'flex', flexDirection: 'column', height: '100%'}}
        >
          <Box data-test-id="audience-details-actions" style={{marginBottom: '40px'}}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {!isMobile && (
                <Typography style={{fontWeight: 600, fontSize: '16px', color: '#54708C'}}>
                  {audience ? audience.name : t('usersList.audience.title')}
                </Typography>
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  width: isMobile ? '100%' : 'auto',
                  gap: isMobile ? '16px' : 0
                }}
              >
                {audience && (
                  <Box mr={2}>
                    {isLoadingAdditionalDetails ? (
                      <Skeleton width={20} height={30} />
                    ) : (
                      <IconButton
                        data-test-id="delete-audience-button"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation()
                          setShouldOpenDeleteDialog(true)
                        }}
                        style={{boxShadow: 'none'}}
                      >
                        <DeleteOutlineIcon style={{color: '#016AD4'}} />
                      </IconButton>
                    )}
                  </Box>
                )}
                {!isMobile && (
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setShouldOpenDiscardDialog(true)}
                      style={{boxShadow: 'none'}}
                      data-test-id="close-audience-details-button"
                    >
                      <CloseIcon style={{color: '#016AD4'}} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <Box style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
              <Controller
                name="audienceName"
                control={control}
                rules={{required: true}}
                render={({field}) => {
                  return (
                    <TextField
                      {...field}
                      value={field.value?.trimStart()}
                      className={classes.textField}
                      data-test-id="audience-details-title"
                      type="text"
                      variant="standard"
                      onClick={(event) => event.stopPropagation()}
                      placeholder={t('usersList.audience.name')}
                      InputProps={{disableUnderline: true, inputProps: {style: {padding: 0}}}}
                      error={!!formState.errors.audienceName}
                      helperText={
                        formState.errors.audienceName?.type === 'required' &&
                        t('usersList.audience.required', {field: t('usersList.audience.name')})
                      }
                    />
                  )
                }}
              />
              <Controller
                name="audienceDescription"
                control={control}
                render={({field}) =>
                  isLoadingAdditionalDetails ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={150}
                      style={{borderRadius: '8px'}}
                    />
                  ) : (
                    <TextField
                      {...field}
                      style={{
                        borderRadius: '4px',
                        padding: '12px',
                        border: '1px solid rgba(209, 217, 224, 1)'
                      }}
                      type="text"
                      variant="standard"
                      data-test-id="audience-details-description"
                      onClick={(event) => event.stopPropagation()}
                      placeholder={t('usersList.audience.description')}
                      InputProps={{disableUnderline: true, inputProps: {style: {padding: 0}}}}
                      multiline={true}
                      minRows={3}
                      error={!!formState.errors.audienceDescription}
                      helperText={
                        formState.errors.audienceDescription?.type === 'required' &&
                        t('usersList.audience.required', {
                          field: t('usersList.audience.description')
                        })
                      }
                    />
                  )
                }
              />
              <Box style={{flex: '0 1'}}>
                <Controller
                  name="audienceFilters"
                  control={control}
                  rules={{
                    validate: (value) => value && value.length > 0
                  }}
                  render={() => (
                    <DetailsField
                      label={t('usersList.audience.filters')}
                      dataTestId="audience-details-filters-field"
                    >
                      <Box style={{marginBottom: '4px'}} />
                      {isLoadingAdditionalDetails && (
                        <Box style={{display: 'flex', gap: '8px'}}>
                          <Skeleton width={80} /> <Skeleton width={120} />
                        </Box>
                      )}
                      {!isLoadingAdditionalDetails &&
                        !isBlocklistPresent &&
                        filters.map((filter) => (
                          <Chip
                            data-test-id={`filter-chip-${upperFirst(filter.name)}`}
                            classes={{
                              root: classNames({
                                [classes.tags]: true,
                                [classes.tagMod1]: true
                              })
                            }}
                            style={{marginRight: '4px'}}
                            key={filter.name}
                            label={upperFirst(filter.name) + ': ' + filter.value}
                            size="small"
                          />
                        ))}
                      {!audience && (formState.errors.audienceFilters || filters.length === 0) && (
                        <Typography
                          style={{
                            color: formState.errors.audienceFilters ? '#CC0801' : '#52667A'
                          }}
                        >
                          {t('usersList.audience.filtersRequired')}
                        </Typography>
                      )}
                      {!audience && isBlocklistPresent && (
                        <Typography
                          style={{
                            color: formState.errors.audienceFilters ? '#CC0801' : '#52667A'
                          }}
                        >
                          {t('usersList.audience.blocklistError')}
                        </Typography>
                      )}
                    </DetailsField>
                  )}
                />
              </Box>
              {audience && (
                <DetailsField
                  label={t('usersList.audience.recipents')}
                  dataTestId="audience-details-recipents-field"
                >
                  {isLoadingAdditionalDetails ? (
                    <Skeleton width={60} height={30} />
                  ) : (
                    <Box style={{display: 'flex', alignItems: 'baseline', gap: '8px'}}>
                      <Typography>{additionalDetailsData?.numberOfRecipients || 0} -</Typography>
                      <Link underline="none" onClick={handleShowRecipients}>
                        {t('usersList.audience.showRecipients')}
                      </Link>
                    </Box>
                  )}
                </DetailsField>
              )}
              {audience && (
                <DetailsField
                  label={t('usersList.audience.pastMessages')}
                  dataTestId="audience-details-pastMessages-field"
                  gutter
                >
                  {isLoadingAdditionalDetails ? (
                    <Skeleton width="100%" height={40} />
                  ) : additionalDetailsData?.pastMessages &&
                    additionalDetailsData?.pastMessages.length > 0 ? (
                    <Box style={{overflowY: 'auto', maxHeight: '200px'}}>
                      {additionalDetailsData?.pastMessages.map((message) => (
                        <Typography key={message} style={{padding: '4px 0'}}>
                          {message}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    t('usersList.audience.noPastMessages')
                  )}
                </DetailsField>
              )}
            </Box>
            <Box style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
              {!audience && (
                <FormControlLabel
                  style={{fontWeight: 500, color: '#29333D', whiteSpace: 'nowrap'}}
                  control={
                    <Checkbox
                      onChange={(_, checked) => setShouldRedirectToCreateMessage(checked)}
                      data-test-id="checkbox-create-message"
                      icon={
                        <span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />
                      }
                      checkedIcon={
                        <span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />
                      }
                    />
                  }
                  label={t('usersList.audience.immediateMessage')}
                />
              )}
              {audience && (
                <LoadingButton
                  className={classes.saveButton}
                  onClick={(event) => {
                    event.stopPropagation()
                    setShouldCloseDialog?.(true)
                    setShouldEvaluateAudiences?.(true)
                  }}
                  loading={isLoadingAdditionalDetails}
                  classes={{
                    loadingIndicator: classes.loadingIndicator
                  }}
                  style={{
                    color: isLoadingAdditionalDetails ? 'transparent' : '#FFFFFF'
                  }}
                >
                  {t('audiences.createMessage')}
                </LoadingButton>
              )}
              <LoadingButton
                className={audience ? classes.discardButton : classes.saveButton}
                disabled={
                  !isEmpty(formState.errors) ||
                  (audience && isEmpty(formState.dirtyFields)) ||
                  isBlocklistPresent
                }
                style={{
                  color:
                    isCreateAudienceLoading || isEditAudienceLoading
                      ? 'transparent'
                      : audience
                        ? undefined
                        : '#FFFFFF'
                }}
                classes={{
                  loadingIndicator: classes.loadingIndicator,
                  loading: classes.loadingButton
                }}
                loading={
                  isCreateAudienceLoading || isEditAudienceLoading || isLoadingAdditionalDetails
                }
                type="submit"
                data-test-id="create-audience-button"
              >
                {t('usersList.audience.save')}
              </LoadingButton>
              {!audience && (
                <Button
                  className={classes.discardButton}
                  onClick={(event) => {
                    event.stopPropagation()
                    setShouldOpenDiscardDialog(true)
                  }}
                >
                  {t('usersList.audience.discard')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </form>
      <DiscardDialog
        setShouldRedirect={setShouldRedirect}
        setShouldOpenDiscardDialog={setShouldOpenDiscardDialog}
        shouldOpenDiscardDialog={shouldOpenDiscardDialog}
        setShouldCloseDetails={setShouldCloseDetails}
      />
      <DeleteAudienceDialog
        showDialog={shouldOpenDeleteDialog}
        setShowDialog={setShouldOpenDeleteDialog}
        handleDelete={handleDelete}
        isLoading={isDeleteAudienceLoading}
      />
    </>
  )
}
