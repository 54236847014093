import {Paper} from '@material-ui/core'
import {motion, useAnimation} from 'framer-motion'
import React, {useCallback, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'

import {useStyles} from './DocxDropzone.styles'

type DocxDropzoneProps = {
  onDocxDropped: (file: File) => void
}

export const DocxDropzone: React.FC<DocxDropzoneProps> = ({onDocxDropped}) => {
  const classes = useStyles()

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    console.log(acceptedFiles)
    acceptedFiles[0] && onDocxDropped(acceptedFiles[0])
    // Do something with the files
  }, [])

  const onDropRejected = useCallback((acceptedFiles) => {
    console.log('rej', acceptedFiles)
    // Do something with the files
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: '.docx'
  })

  const controls = useAnimation()

  useEffect(() => {
    if (isDragActive) {
      void controls.start({scale: 0.95})
    } else {
      controls.set({scale: 1})
      controls.stop()
    }
  }, [isDragActive, controls])

  return (
    <motion.div
      className={classes.container}
      animate={controls}
      transition={{
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 1
      }}
    >
      <Paper className={classes.paper}>
        <div
          {...getRootProps()}
          style={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'}}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop docx file here ...</p>
          ) : (
            <p>Drag and drop legal document in docx format ...</p>
          )}
        </div>
      </Paper>
    </motion.div>
  )
}
