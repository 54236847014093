import React from 'react'

interface CloneLinkProps {
  className: string
}

export const CloneIcon: React.FC<CloneLinkProps> = ({className}) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 23 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Create/Manage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Manage-user:-view" transform="translate(-774.000000, -171.000000)" fillRule="nonzero">
        <g id="Group-14" transform="translate(768.000000, 162.000000)">
          <g id="people_alt-24px" transform="translate(6.000000, 6.000000)">
            <g id="Group" transform="translate(1.000000, 4.000000)">
              <path
                d="M15.67,9.13 C17.04,10.06 18,11.32 18,13 L18,16 L22,16 L22,13 C22,10.82 18.43,9.53 15.67,9.13 Z"
                id="Path"
                fill="#29AAFF"
              />
              <path
                d="M8,8 C10.209139,8 12,6.209139 12,4 C12,1.790861 10.209139,0 8,0 C5.790861,0 4,1.790861 4,4 C4,6.209139 5.790861,8 8,8 Z"
                id="Oval"
                stroke="#29AAFF"
                strokeDasharray="1"
              />
              <path
                d="M14,8 C16.21,8 18,6.21 18,4 C18,1.79 16.21,0 14,0 C13.53,0 13.09,0.1 12.67,0.24 C13.5,1.27 14,2.58 14,4 C14,5.42 13.5,6.73 12.67,7.76 C13.09,7.9 13.53,8 14,8 Z"
                id="Path"
                fill="#29AAFF"
              />
              <path
                d="M8,9 C5.33,9 0,10.34 0,13 L0,16 L16,16 L16,13 C16,10.34 10.67,9 8,9 Z"
                id="Path"
                stroke="#29AAFF"
                strokeDasharray="1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
