import {Feature} from '@hconnect/common/components/FeaturesCheck'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.store'

interface ErrorResponse {
  error: Error
}
export const useFeatureDetails = (featureName?: string) => {
  return useQuery<Feature, ErrorResponse>(
    ['features', {featureName}],
    async () => {
      try {
        const res: AxiosResponse<Feature> = await api.get(`/features/${featureName}`)
        return res.data
      } catch (e) {
        throw new Error('Error during fetching features')
      }
    },
    {
      enabled: Boolean(featureName)
    }
  )
}
