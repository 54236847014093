import {LoadingButton} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useFeaturesList} from '../hooks/useFeaturesList'

type DeleteFeatureDialogProps = {
  deleteFeatureDialogData: string
  setDeleteFeatureDialogData: (deleteFeatureDialogData: string | undefined) => void
}

export const DeleteFeatureDialog = ({
  deleteFeatureDialogData,
  setDeleteFeatureDialogData
}: DeleteFeatureDialogProps) => {
  const {t} = useTranslation()

  const {deleteFeature, isLoading} = useFeaturesList(setDeleteFeatureDialogData)

  return (
    <Dialog
      data-test-id="delete-feature-dialog"
      open={!!deleteFeatureDialogData}
      onClose={() => setDeleteFeatureDialogData(undefined)}
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{t('features.deleteFeatureDialog.dialogTitle')}</DialogTitle>
        <IconButton
          onClick={() => setDeleteFeatureDialogData(undefined)}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent style={{width: '400px'}}>
        <DialogContentText>{t('features.deleteFeatureDialog.dialogMessage')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => deleteFeature(deleteFeatureDialogData)}
          loading={isLoading}
          variant="text"
          color="primary"
          style={{textTransform: 'none', fontSize: '16px', fontWeight: 600, lineHeight: '22px'}}
          data-test-id="delete-feature-dialog-action-delete"
        >
          {t('features.deleteFeatureDialog.dialogActionDelete')}
        </LoadingButton>

        <Button
          onClick={() => setDeleteFeatureDialogData(undefined)}
          variant="text"
          color="primary"
          data-test-id="delete-feature-dialog-action-cancel"
        >
          {t('features.deleteFeatureDialog.dialogActionCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
