import {Typography} from '@hconnect/uikit'
import {Box, FormControl, FormControlLabel, FormGroup, FormHelperText} from '@material-ui/core'
import React, {useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CheckboxForm} from '../Announcements.components'

import {AnnouncementFormProps} from './types'

export type BusinessLinesInputProps = AnnouncementFormProps

export const BusinessLinesInput = ({formMethods}: BusinessLinesInputProps) => {
  const {t} = useTranslation()
  const products = formMethods.watch('products')
  const selectedBusinessLines = formMethods.watch('businessLines')

  useEffect(() => {
    if (products.includes('OnSite')) {
      formMethods.setValue('businessLines', ['RMC'])
    } else {
      formMethods.setValue('businessLines', selectedBusinessLines)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  return (
    <Box>
      <Controller
        control={formMethods.control}
        name="businessLines"
        rules={{
          validate: (value) => value.length > 0
        }}
        render={({field}) => (
          <FormControl>
            <Typography style={{fontSize: '18px', fontWeight: 600, whiteSpace: 'nowrap'}}>
              {t('announcements.businessLines')}
            </Typography>

            <FormGroup style={{marginLeft: -10, marginTop: '15px'}}>
              <FormControlLabel
                control={
                  <CheckboxForm
                    name="rmc"
                    dataTestId="rmc-checkbox"
                    checked={field.value.includes('RMC')}
                    value="RMC"
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      if (checked) {
                        const values = new Set(field.value)
                        values.add('RMC')
                        field.onChange([...values])
                      } else {
                        const values = new Set(field.value)
                        values.delete('RMC')
                        field.onChange([...values])
                      }
                    }}
                  />
                }
                label={t('announcements.RMC')}
              />
              <FormControlLabel
                control={
                  <CheckboxForm
                    name="cement"
                    value="CEM"
                    dataTestId="cem-checkbox"
                    checked={field.value.includes('CEM')}
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      if (checked) {
                        const values = new Set(field.value)
                        values.add('CEM')
                        field.onChange([...values])
                      } else {
                        const values = new Set(field.value)
                        values.delete('CEM')
                        field.onChange([...values])
                      }
                    }}
                  />
                }
                label={t('announcements.CEM')}
              />
              <FormControlLabel
                control={
                  <CheckboxForm
                    name="aggregates"
                    dataTestId="agg-checkbox"
                    value="AGG"
                    checked={field.value.includes('AGG')}
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      if (checked) {
                        const values = new Set(field.value)
                        values.add('AGG')
                        field.onChange([...values])
                      } else {
                        const values = new Set(field.value)
                        values.delete('AGG')
                        field.onChange([...values])
                      }
                    }}
                  />
                }
                label={t('announcements.AGG')}
              />
            </FormGroup>
            {formMethods.formState.errors.businessLines?.type === 'validate' ? (
              <FormHelperText
                style={{marginLeft: 0}}
                component="div"
                error={formMethods.formState.errors.businessLines?.type === 'validate'}
              >
                {t('announcements.form.validateBusinessLines')}
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      />
    </Box>
  )
}
