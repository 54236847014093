import {makeStyles} from 'tss-react/mui'

export const useListViewStyles = makeStyles()(() => ({
  listViewPaginationToolbar: {
    display: 'flex',
    width: '100%',
    color: '#54708C',
    fontWeight: 500,
    '&.MuiToolbar-root': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  listViewPaginationActions: {
    '& .MuiButtonBase-root': {
      boxShadow: 'none'
    }
  },
  listViewPaginationDisplayedRows: {
    margin: 0,
    flex: 1
  },
  listViewPaginationSpacer: {
    display: 'none'
  },
  listViewPaginationActionIconButton: {
    color: '#29333D'
  },
  listViewPaginationSelectIcon: {
    color: '#54708C'
  },
  listViewPaginationSelect: {
    color: '#29333D',
    fontWeight: 500,
    paddingRight: 0,
    marginTop: '4px'
  },
  listItem: {
    borderBottom: '1px solid #E8ECF0',
    padding: '8px 14px'
  },
  listItemClickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}))
