import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: '24px 48px'
  },
  controlRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0'
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px'
  },
  list: {
    outline: 'none',
    marginRight: '12px'
  }
}))
