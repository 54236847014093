import {Product} from '@hconnect/apiclient'
import {useMutation} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.store'

interface UseInviteUserResult {
  inviteUser: (product: Product) => Promise<void>
}

export const useInviteUser = (
  userId: string | undefined,
  refetchUser: () => void
): UseInviteUserResult => {
  const mutation = useMutation<AxiosResponse, Error, Product, unknown>(
    async (product: Product) => {
      if (!userId) {
        throw new Error('User ID is undefined')
      }
      return await api.post('/users/invite', {
        user_id: userId,
        product: product
      })
    },
    {
      onSuccess: () => {
        refetchUser()
      },
      onError: (error) => {
        console.error('Error inviting user:', error)
      }
    }
  )

  return {
    inviteUser: async (product: Product): Promise<void> => {
      await mutation.mutateAsync(product)
    }
  }
}
