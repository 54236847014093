import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {MessageTypes} from '../../common'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type MessageTypeInputProps = AnnouncementFormProps

export const MessageTypeInput = ({formMethods}: MessageTypeInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const messageTypesOptions = [
    {value: MessageTypes.information, label: t('announcements.information')},
    {value: MessageTypes.warning, label: t('announcements.warning')},
    {value: MessageTypes.maintenance, label: t('announcements.maintenance')}
  ]

  return (
    <Box
      data-test-id="announcement-type-dropdown"
      style={{display: 'flex', flexDirection: 'column'}}
    >
      <Typography style={{fontSize: '18px', fontWeight: 600, marginBottom: '12px'}}>
        {t('announcements.form.messageType')}
      </Typography>
      <Controller
        name="messageType"
        control={formMethods.control}
        render={({field}) => {
          return (
            <SelectDropdown
              data-test-id={`announcement-form-${field.name}`}
              label={t('announcements.form.messageType')}
              className={classes.selectDropdown}
              errorRender={() => <ErrorPaper variant="primaryDark" />}
              options={messageTypesOptions}
              renderItem={(item: Item) => (
                <Typography variant="body1" color="textSecondary">
                  {item.label}
                </Typography>
              )}
              showSearch={false}
              stringifyItem={(item: Item) => item.value}
              stringifySubItem={(item: Item) => item.label}
              renderChosenItem={(item: Item) => item.label}
              keyExtractor={(item: Item) => item.value}
              selectedItem={messageTypesOptions.find(
                (value) => (value.value as string) === field.value
              )}
              onChange={(item: Item) => {
                field.onChange(item.value)
              }}
              popoverStyle={{left: 0}}
            />
          )
        }}
      />
    </Box>
  )
}
