import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D9E0',
      borderRadius: '4px'
    },
    '& .MuiInputBase-input': {
      padding: '12px'
    }
  },
  searchButton: {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#016AD4',
    borderRadius: '4px',
    marginTop: '48px',
    '&:disabled': {
      backgroundColor: '#016AD4',
      opacity: 0.5,
      color: '#FFFFFF'
    }
  }
}))
