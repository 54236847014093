import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  FormControlLabel
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React, {useEffect, useState} from 'react'
import {UseFormReturn, UseFieldArrayRemove} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CheckboxForm} from '../../Announcements/components'
import {FeatureFormData} from '../components/FeatureCreate'

import {useStyles} from './FeatureConstraint.styles'

type FeatureConstraintInputProps = {
  formMethods: UseFormReturn<FeatureFormData, object>
  index: number
  remove: UseFieldArrayRemove
}
export const FeatureConstraintInput = ({
  formMethods,
  remove,
  index
}: FeatureConstraintInputProps) => {
  const errorDataScope = formMethods.formState.errors.featureConstraints?.[index]?.dataScope
  const errorType = !!formMethods.formState.errors.featureConstraints?.[index]?.type
  const {t} = useTranslation()
  const {classes} = useStyles()
  const valueType = formMethods.watch().featureConstraints?.[index]?.type
  const valueEnabled = formMethods.watch().featureConstraints?.[index]?.enabled

  const [selectedType, setSelectedType] = useState<string | null>('')

  useEffect(() => {
    if (valueType) {
      setSelectedType(valueType)
    }
  }, [valueType])

  const validateJSON = (value: string) => {
    if (!isNaN(parseInt(value))) {
      return false
    }

    try {
      JSON.parse(value)
      return true
    } catch (error) {
      return false
    }
  }

  const formatDataScopeHelperText = () => {
    if (errorDataScope) {
      return errorDataScope.type === 'required'
        ? t('features.form.dataScopeRequired')
        : t('features.form.dataScopeWrongJSON')
    }
  }

  const types = ['user', 'role', 'data', 'public']
  return (
    <Accordion defaultExpanded={true} style={{backgroundColor: 'transparent'}}>
      <AccordionSummary className={classes.accordionSummary}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box
            style={{display: 'flex', gap: '12px', justifyContent: 'center', alignItems: 'center'}}
          >
            <Box className={classes.constraintTitle}>{index + 1}</Box>
            <Typography style={{fontWeight: 600}}>
              {t('features.form.featureConstraints.constraintTitle')}
            </Typography>
          </Box>
          <Button
            onClick={() => remove(index)}
            size="small"
            className={classes.constraintDeleteButton}
            data-test-id={`delete-constraint-${index + 1}`}
          >
            <DeleteOutlineIcon style={{color: '#016AD4', marginRight: '8px'}} />
            {t('features.form.featureConstraints.delete')}
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{backgroundColor: 'transparent', padding: 0}}>
        <Box style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <TextField
            fullWidth
            label={t('features.form.featureConstraints.dataScopeLabel')}
            data-test-id={`featureConstraints.${index + 1}.dataScope`}
            type="text"
            variant="standard"
            style={{
              borderRadius: '4px',
              border: '1.5px solid rgba(0, 39, 77, 0.15)',
              padding: '6px 16px'
            }}
            InputProps={{disableUnderline: true}}
            InputLabelProps={{
              style: {color: '#54708C', padding: '6px 16px'}
            }}
            focused={true}
            error={!!errorDataScope}
            helperText={formatDataScopeHelperText()}
            {...formMethods.register(`featureConstraints.${index}.dataScope`, {
              required: true,
              validate: validateJSON
            })}
          />
          <Autocomplete
            freeSolo
            forcePopupIcon={true}
            disableClearable={true}
            popupIcon={<KeyboardArrowDownIcon style={{color: '#00274D'}} />}
            className={classes.autocomplete}
            value={selectedType || ''}
            inputValue={selectedType || ''}
            onChange={(e, value) => {
              formMethods.setValue(`featureConstraints.${index}.type`, value, {
                shouldValidate: true
              })
              setSelectedType(value)
            }}
            onInputChange={(event, newInputValue) => {
              setSelectedType(newInputValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('features.form.featureConstraints.typeLabel')}
                data-test-id={`featureConstraints.${index + 1}.type`}
                style={{color: '#54708C'}}
                focused={true}
                error={errorType}
                helperText={errorType && t('features.form.typeRequired')}
                {...formMethods.register(`featureConstraints.${index}.type`, {required: true})}
              />
            )}
            options={types}
          />
          <FormControlLabel
            data-test-id={`featureConstraints.${index + 1}.enabled`}
            control={
              <CheckboxForm
                dataTestId={`featureConstraints.label.${index + 1}.enabled`}
                checked={valueEnabled}
              />
            }
            style={{marginTop: '2px', marginLeft: '8px'}}
            label={t('features.form.featureConstraints.enabledLabel')}
            {...formMethods.register(`featureConstraints.${index}.enabled`)}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
