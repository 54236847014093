import {Product, User} from '@hconnect/apiclient'
import {BoxedContainer} from '@hconnect/uikit'
import {Paper, PaperProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {InviteUserButtons} from './InviteUserButtons'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `0 ${theme.spacing(2)}px`,
    zIndex: 10
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minHeight: theme.spacing(6),
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`
  },
  colorPrimary: {
    color: 'red'
  },
  icon: {
    marginRight: 10
  },
  body1: {
    fontSize: 14
  },
  info: {
    marginRight: 6
  }
}))

interface Props extends PaperProps {
  user: User | null
  products: Product[]
  refetchUser?: () => void
}

export const UserIssueButtonBar: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper} square elevation={4}>
      <BoxedContainer>
        <InviteUserButtons
          data-test-id="invite-buttons-box-notification"
          variant="text"
          user={props.user}
          products={props.products}
          refetchUser={props.refetchUser}
        />
      </BoxedContainer>
    </Paper>
  )
}
