import {Company} from '@hconnect/apiclient'
import {TableColumnType, useMobileBreakPoint, useTranslation} from '@hconnect/uikit'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Button, Link} from '@mui/material'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {useStyles} from './CompanyListRows.styles'

export const CompanyListRows = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const isMobile = useMobileBreakPoint()

  const history = useHistory()
  const rowsPool: {[key: string]: TableColumnType} = {
    name: {
      field: 'name',
      filterName: t('companyList.name'),
      mobileRowHeader: true,
      headerName: t('companyList.name'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      renderCell: (company: Company) => (
        <Box
          style={{
            maxWidth: isMobile ? '400px' : '250px',
            minWidth: '150px',
            display: 'flex',
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'start'
          }}
        >
          <Box className={classes.textOverflow}>{company.name || '-'}</Box>
        </Box>
      )
    },
    country: {
      field: 'country',
      filterName: t('companyList.country'),
      headerName: t('companyList.country'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      renderCell: (company: Company) => (
        <Box
          style={{
            maxWidth: isMobile ? '400px' : '250px',
            minWidth: '150px',
            display: 'flex',
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'start'
          }}
        >
          <Box className={classes.textOverflow}>{company.country || '-'}</Box>
        </Box>
      )
    },
    subscriptionPlanNames: {
      field: 'subscriptions',
      filterName: t('companyList.subscriptions'),
      headerName: t('companyList.subscriptions'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (company: Company) => (
        <Box
          style={{
            maxWidth: isMobile ? '400px' : '250px',
            minWidth: '150px',
            display: 'flex',
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'start'
          }}
        >
          <Box className={classes.textOverflow}>
            {company.subscriptionPlanNames?.join(', ') || '-'}
          </Box>
        </Box>
      )
    },
    actions: {
      field: 'actions',
      headerClassName: classes.columnHeader,
      headerName: isMobile ? '' : t('companyList.actions'),
      headerAlign: 'left',
      renderCell: (company: Company) => {
        const pushLink = `/companies/manage/${company.id}`
        return isMobile ? (
          <Button
            href={pushLink}
            className={classes.manageButton}
            onClick={(e: any) => {
              e.preventDefault()
              history.push(pushLink, {source: 'list', from: window.location.href})
            }}
          >
            <ModeEditOutlineIcon style={{marginRight: '4px'}} />
            {t('companyList.manageCompany')}
          </Button>
        ) : (
          <Link
            color="primary"
            href={pushLink}
            style={{display: 'flex', width: '100%', justifyContent: 'center'}}
            onClick={(e: any) => {
              e.preventDefault()
              history.push(pushLink, {source: 'list', from: window.location.href})
            }}
          >
            <ModeEditOutlineIcon style={{marginRight: '4px'}} />
          </Link>
        )
      }
    }
  }

  const RowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.actions,
    rowsPool.name,
    rowsPool.country,
    rowsPool.subscriptionPlanNames
  ]

  return RowOrder(rowsPool)
}
