import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import {useEffect, useState} from 'react'

import {FeaturesFilter} from '../FeaturesList'

export const useFeaturesListFilters = (
  filter: FeaturesFilter,
  setFilter: (filter: FeaturesFilter) => void
) => {
  const [filterInputValues, setFilterInputValues] = useState<TableFilterType[]>([])
  const [removedFilter, setRemovedFilter] = useState<TableFilterType>()
  const [shouldResetPage, setShouldResetPage] = useState(false)

  useEffect(() => {
    const formattedInputValues = filterInputValues.map((value) => handleFilterValues(value))
    const mergeParams: FeaturesFilter = Object.assign(filter, ...formattedInputValues)
    const filteredParams = Object.keys(mergeParams)
      .filter((param) => removedFilter?.filterField !== param)
      .reduce((acc, key) => {
        const _acc = acc
        if (mergeParams[key] !== undefined && mergeParams[key] !== '') _acc[key] = mergeParams[key]
        return _acc
      }, {})

    if (shouldResetPage) {
      setFilter({...filteredParams, page: 1} as FeaturesFilter)
      setShouldResetPage(false)
    } else {
      setFilter(filteredParams as FeaturesFilter)
    }

    setRemovedFilter(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputValues])

  const handleFilterValues = (value: TableFilterType) => {
    if (value.filterField) {
      return {[value.filterField]: value.value}
    }
  }

  const handleFilterInputValues = (props: TableFilterItemProps) => {
    setShouldResetPage(true)
    const filterInputIndex = filterInputValues.findIndex(
      (item) => item.filterField === props.filterField
    )

    const newFilterInputValues = [...filterInputValues]
    if (filterInputIndex === -1) {
      setFilterInputValues([
        ...filterInputValues,
        {filterField: props.filterField || '', value: props.value || ''}
      ])
    } else {
      newFilterInputValues[filterInputIndex].value = props.value || ''
      setFilterInputValues(newFilterInputValues)
    }
  }

  return {
    filter,
    setFilter,
    handleFilterInputValues,
    filterInputValues,
    setFilterInputValues,
    setRemovedFilter
  }
}
