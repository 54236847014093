import {Company} from '@hconnect/apiclient'
import {Content, Page, ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import {Button, Grid, Paper} from '@mui/material'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {useQueryParamState} from '../../common/QueryParamStateHook'

import {CompanyDetails} from './CompanyDetails'
import {CompanyHeaderActions} from './CompanyHeaderActions'
import {useStyles} from './CompanyList.styles'
import {CompanyTableFilter} from './CompanyList.types'
import {CompanyListRows} from './CompanyListRows'
import {useCompanies} from './hooks/useCompanies'
import {useCompanyListFilters} from './hooks/useCompanyListFilters'

// eslint-disable-next-line complexity
export const CompanyList: React.FC = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const history = useHistory()

  const [areFiltersSetup, setAreFiltersSetup] = useState(false)

  const [filter, setFilter] = useQueryParamState<CompanyTableFilter>({
    rowsPerPage: 10,
    page: 1,
    sortColumn: 'name',
    sortDirection: 'desc'
  })

  const [companyDetails, setCompanyDetails] = useState<Company>()

  const {isLoading: countriesLoading} = useCountriesConfiguration()
  const {
    data: response,
    refetch: reloadCompanies,
    error,
    isInitialLoading: isLoading,
    isFetching
  } = useCompanies(filter, true)
  const {filterInputValues, handleFilterInputValues, setFilterInputValues, setRemovedFilter} =
    useCompanyListFilters(filter, setFilter, !!response?.data, setAreFiltersSetup)

  const noResultsMessage = t('companyList.lookup.noResults')

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortColumn === sortKey && filter.sortDirection === 'asc')
    setFilter({
      ...filter,
      sortColumn: sortKey,
      sortDirection: asc ? 'asc' : 'desc'
    })
  }

  const totalPageCount = response?.headers ? parseInt(response.headers['x-total-count'], 10) : 0

  const rowsPerPage = Number(filter.rowsPerPage)

  const SnackbarActionButton = () => (
    <Button onClick={() => void reloadCompanies()} variant="text" color="primary">
      {t('companyList.retry')}
    </Button>
  )

  if (error) {
    enqueueSnackbar(
      error.message === 'companyList.cannotLoadCompanies' ? t(error.message) : error.message,
      {
        action: <SnackbarActionButton />,
        variant: 'error'
      }
    )
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const handleClose = () => setCompanyDetails(undefined)

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>, company: Company) => {
    event.preventDefault()
    const pushLink = `/companies/manage/${company.id}`
    history.push(pushLink, {source: 'list', from: window.location.href})
    setCompanyDetails(undefined)
  }

  const handleDetails = () => {
    if (companyDetails) {
      return (
        <CompanyDetails
          company={companyDetails}
          handleClose={handleClose}
          handleEdit={handleEdit}
        />
      )
    }

    return null
  }

  return (
    <Content>
      <Page
        data-test-id="page-companylist"
        title={t('companyList.title')}
        boxed={false}
        classNameHeader={classes.pageHeader}
        headerActionContent={<CompanyHeaderActions />}
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid container className={classes.pageGrid} wrap="nowrap">
          <Grid
            item
            md={companyDetails && !isMobile ? 8 : 12}
            sm={12}
            xs={12}
            className={classes.detailsPaper}
          >
            <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
              <ResponsiveTable
                keyField="id"
                columns={CompanyListRows()}
                rows={response?.data ?? []}
                onSort={(e, sortKey) => setSortKey(sortKey)}
                sortedBy={filter.sortColumn}
                sortingOrder={filter.sortDirection}
                emptyMessage={!isLoading && noResultsMessage}
                loading={isLoading || isFetching || countriesLoading || !areFiltersSetup}
                isMobile={isMobile}
                isRowSelectable={isMobile ? undefined : () => true}
                onRowClick={(item: Company) => {
                  if (item.id === companyDetails?.id) {
                    setCompanyDetails(undefined)
                  } else {
                    setCompanyDetails(item)
                  }
                }}
                mobileRowDetails={handleDetails()}
                page={filter.page}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                count={totalPageCount}
                enableHorizontalScroll={true}
                enableColumnSelection={true}
                tableHeight="70vh"
                stickyHeader={true}
                enablePinning={true}
                rowDetailsAvailable={true}
                filterListInputValues={filterInputValues}
                setFilterListInputValues={setFilterInputValues}
                onFilterInputChange={(props: TableFilterItemProps) =>
                  handleFilterInputValues(props)
                }
                onFilterRemove={(filter: TableFilterType) => {
                  setRemovedFilter(filter)
                  setFilterInputValues(
                    filterInputValues.filter((inputValue) => {
                      return inputValue.filterField !== filter.filterField
                    })
                  )
                }}
              />
            </Paper>
          </Grid>
          {!isMobile && companyDetails ? (
            <Grid item md={4} sm={0} xs={0}>
              <Paper elevation={4} className={classes.detailsGrid}>
                {handleDetails()}
              </Paper>
            </Grid>
          ) : null}
        </Grid>
        {isMobile}
      </Page>
    </Content>
  )
}
