import {useBreakPoints} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import React, {CSSProperties} from 'react'
import {useTranslation} from 'react-i18next'

import {AuditInformation} from '../../../components/UserInformation/AuditInformation/AuditInformation'
import {useAuditInformation} from '../hooks/useAuditInformation'

import {useStyles} from './AuditInformationDialog.styles'

type AuditInformationDialogProps = {
  userIdentifier: string
  auditType: string
  onCloseAuditDialog: (show: boolean) => void
}

export const AuditInformationDialog = ({
  userIdentifier,
  auditType,
  onCloseAuditDialog
}: AuditInformationDialogProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {data, isFetching} = useAuditInformation(auditType, userIdentifier)

  const tabletContentCss: CSSProperties = {
    background: '#FFFFFF',
    color: '#000000',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '85vh',
    padding: '0 32px',
    overflowY: 'hidden',
    zIndex: 10
  }
  const Title = ({onClose}) => {
    return (
      <Box className={classes.dialog}>
        <DialogTitle style={{paddingLeft: isTablet ? '7px' : undefined}}>
          {t(
            auditType === 'user' ? 'audit.auditUsersDialogHeader' : 'audit.auditRolesDialogHeader'
          )}
        </DialogTitle>
        <IconButton
          style={{
            paddingRight: isTablet ? '0' : undefined,
            marginRight: isTablet ? '-5px' : '24px'
          }}
          onClick={() => onClose()}
          className={classes.closeIconButton}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
    )
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      data-test-id="manage-user-dialog-audit-information"
      open={true}
      onClose={() => onCloseAuditDialog(false)}
    >
      <Title onClose={() => onCloseAuditDialog(false)} />
      <DialogContent style={{overflowY: 'clip'}}>
        {isTablet && (
          <ClickAwayListener onClickAway={() => onCloseAuditDialog(false)}>
            <Box style={tabletContentCss}>
              <Title onClose={() => onCloseAuditDialog(false)} />
              <AuditInformation audit={data?.data} isLoading={isFetching} />
            </Box>
          </ClickAwayListener>
        )}
        {!isTablet && <AuditInformation audit={data?.data} isLoading={isFetching} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseAuditDialog(false)} variant="text" color="primary">
          {t('audit.auditInfoClose')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
