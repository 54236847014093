import {api} from '@hconnect/hub/src/App.global'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

export const useCloneUser = (userIdToClone: string) => {
  return useQuery(
    ['getAllowedRolesToClone', {userIdToClone}],
    async () => {
      const res: AxiosResponse<number[]> = await api.get(
        `/roles/getAllowedRolesToClone?userIdToClone=${userIdToClone}`
      )
      return res.data
    },
    {
      cacheTime: 0,
      retry: false,
      enabled: false
    }
  )
}
