import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1
    // margin: '24px 48px'
  },
  paper: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    flex: 1
  }
}))
