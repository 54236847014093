import {Tab, Tabs} from '@material-ui/core'
import React from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CreateMessageFormData} from '../CreateMessage.form'
import {useStyles} from '../CreateMessage.styles'

export type SelectChannelInputProps = {
  formMethods: UseFormReturn<CreateMessageFormData>
}

export const SelectChannelInput = ({formMethods}: SelectChannelInputProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const channelType = formMethods.watch('channelType')
  const mappedChannelType = channelType === 'Email' ? 0 : 1

  return (
    <Tabs
      value={mappedChannelType}
      TabIndicatorProps={{
        style: {
          display: 'none'
        }
      }}
      onChange={(_, value) => {
        formMethods.setValue('channelType', value === 0 ? 'Email' : 'sms')
        formMethods.setValue('message', '')
      }}
      className={classes.tabContainer}
    >
      <Tab
        data-test-id="channel-email"
        label={t('createMessage.form.channelEmail')}
        className={classes.firstTab}
        classes={{
          selected: classes.tabSelected
        }}
      />

      <Tab
        data-test-id="channel-sms"
        label={t('createMessage.form.channelSms')}
        className={classes.secondTab}
        classes={{
          selected: classes.tabSelected
        }}
      />
    </Tabs>
  )
}
