import {addToWhitelist, deleteFromWhitelist, getWhitelist} from '@hconnect/apiclient'
import {
  Divider,
  LoadingButton,
  PhoneNumberTextField,
  Searchfield,
  Typography
} from '@hconnect/uikit'
import {Box, ButtonBase, InputAdornment} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {List} from 'react-virtualized'

import {api} from '../../App.store'
import {selectLoggedInUserPermissions} from '../../modules/LoggedInUser.selectors'

import {useStyles} from './Configurations.styles'

const filterValues = (values: string[], filter: string): string[] =>
  filter.trim() === '' ? values : values.filter((value) => value.includes(filter))

export const SmsNotificationConfiguration: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [loadingStates, setLoadingStates] = useState<string[]>([])

  const [mobileNumbers, setMobileNumbers] = useState<string[]>([])
  const [addMobileNumberInput, setAddMobileNumberInput] = useState('')
  const [mobileNumberFilter, setMobileNumberFilter] = useState('')

  const permissions = useSelector(selectLoggedInUserPermissions)

  const canEditConfigurations = permissions.some(
    (permission) => permission.permissionType === 'CHANGE_CONFIGURATIONS'
  )

  const getMobileWhitelist = useCallback(async () => {
    const response = await getWhitelist(api)('sms')

    if (response.type === 'value') {
      setMobileNumbers(response.value)
    } else {
      enqueueSnackbar(t('configurations.whitelist.error.getMobiles'), {variant: 'error'})
    }
  }, [enqueueSnackbar, t])
  // eslint-disable-next-line no-void
  useEffect(() => void getMobileWhitelist(), [getMobileWhitelist])

  const filteredSms = useMemo(
    () => filterValues(mobileNumbers, mobileNumberFilter),
    [mobileNumbers, mobileNumberFilter]
  )

  const handleMobileNumberAddSubmit = async () => {
    const response = await addToWhitelist(api)({type: 'sms', entry: [addMobileNumberInput]})

    if (response.type === 'value') {
      enqueueSnackbar(t('configurations.whitelist.success.smsAdded'), {variant: 'success'})
      await getMobileWhitelist()
    } else {
      enqueueSnackbar(t('configurations.whitelist.error.smsAdd'), {variant: 'error'})
    }
  }

  const handleMobileNumberDelete = async (number: string) => {
    setLoadingStates((s) => [...s, number])
    await deleteFromWhitelist(api)({type: 'sms', entry: number})
    enqueueSnackbar(t('configurations.whitelist.success.smsRemoved'), {variant: 'success'})
    await getMobileWhitelist()
    setLoadingStates((s) => s.filter((entry) => entry !== number))
  }

  return (
    <Box mb={4} paddingBottom="24px">
      <Box>
        <Typography variant="h3">{t('configurations.whitelist.smsHeader')}</Typography>
      </Box>
      <Divider />
      <div className={classes.controlRow}>
        {canEditConfigurations && (
          <PhoneNumberTextField
            placeholder="+4432443243"
            onPhoneChange={(phoneNumber) => {
              setAddMobileNumberInput(phoneNumber)
            }}
            InputLabelProps={{shrink: true}}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ButtonBase onClick={handleMobileNumberAddSubmit}>
                    <AddIcon />
                  </ButtonBase>
                </InputAdornment>
              )
            }}
          />
        )}
        <Searchfield
          placeholder="Filter..."
          value={mobileNumberFilter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMobileNumberFilter(e.target.value)
          }
        />
      </div>
      <List
        rowHeight={61}
        height={500}
        autoWidth
        width={9999}
        rowCount={filteredSms.length}
        className={classes.list}
        rowRenderer={({index, style}) => {
          const mobileNumber = filteredSms[index]

          return (
            <div key={mobileNumber} style={style}>
              <div key={mobileNumber} className={classes.listContainer}>
                <Typography>{mobileNumber}</Typography>
                {canEditConfigurations && (
                  <LoadingButton
                    loading={loadingStates.includes(mobileNumber)}
                    color="primary"
                    onClick={() => handleMobileNumberDelete(mobileNumber)}
                  >
                    {t('configurations.whitelist.deleteButtonText')}
                  </LoadingButton>
                )}
              </div>
            </div>
          )
        }}
      />
    </Box>
  )
}
