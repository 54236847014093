import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  reuseButton: {
    textTransform: 'none',
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    background: '#016AD4',
    color: '#FFFFFF',
    width: '100%'
  },
  tags: {
    borderRadius: '14px'
  },
  tagMod1: {
    color: '#FFFFFF',
    backgroundColor: '#0F89FF'
  }
}))
