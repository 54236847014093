import {Company} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import type {QueryFunction} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'

import {api} from '../../../App.store'
import {CompanyTableFilter, FetchCompaniesParameters} from '../CompanyList.types'

type QueryKey = [string, {filter: CompanyTableFilter}]

const getCompanies = (url: string, filter: CompanyTableFilter) => {
  try {
    const params: FetchCompaniesParameters = {
      skip: (filter.page - 1) * filter.rowsPerPage,
      take: filter.rowsPerPage,
      sortColumn: filter.sortColumn,
      sortDirection: filter.sortDirection,
      name: filter.name,
      country: filter.country
    }
    return api.get<Company[]>(url, {params})
  } catch (error) {
    throw new Error('companyList.cannotLoadCompanies')
  }
}

const companiesFetcher: QueryFunction<AxiosResponse<Company[]> | undefined, QueryKey> = async ({
  queryKey
}) => {
  try {
    const [base, {filter}] = queryKey as QueryKey
    return await getCompanies(`${base}`, filter)
  } catch (e) {
    const error = e as AxiosError
    console.error(error)
    trackEvent('adminConsoleError', {
      product: 'adminConsole',
      date: new Date().toISOString(),
      errorCode: error.response?.status,
      component: 'useCompanies.ts companiesFetcher',
      endpoint: error.response?.config?.url
    })
  }
}

export const useCompanies = (filter: CompanyTableFilter, enabled: boolean) => {
  return useQuery<
    AxiosResponse<Company[]> | undefined,
    AxiosError,
    AxiosResponse<Company[]>,
    QueryKey
  >(['/companies', {filter}], companiesFetcher, {
    retry: false,
    enabled
  })
}
