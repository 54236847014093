import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  tags: {
    borderRadius: '14px'
  },
  tagMod1: {
    color: '#FFFFFF',
    backgroundColor: '#0F89FF'
  }
}))
