import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(8)
  },
  dialogContentText: {
    color: theme.palette.text.primary
  },
  bold: {
    fontWeight: 'bold'
  },
  loadingIndicator: {
    color: '#FFFFFF !important'
  }
}))
