import {Content, Page} from '@hconnect/uikit'
import {Paper} from '@material-ui/core'
import React from 'react'

import {useStyles} from './Configurations.styles'
import {EmailNotificationConfiguration} from './EmailConfiguration'
import {PushNotificationConfiguration} from './PushConfiguration'
import {SmsNotificationConfiguration} from './SmsConfiguration'

export const Configurations = () => {
  const classes = useStyles()
  return (
    <Content>
      <Page title="Configurations">
        <Paper className={classes.paper}>
          <EmailNotificationConfiguration />
          <SmsNotificationConfiguration />
          <PushNotificationConfiguration />
        </Paper>
      </Page>
    </Content>
  )
}
