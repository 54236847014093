import {Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Company} from '../../../../apiclient/src/response/Company'
import {DetailsField} from '../../components/DetailsField/DetailsField'

interface CompanyDetailsProps {
  company: Company
  handleClose: () => void
  handleEdit: (event: React.MouseEvent<HTMLButtonElement>, company: Company) => void
}
// eslint-disable-next-line complexity
export const CompanyDetails = ({company, handleEdit, handleClose}: CompanyDetailsProps) => {
  const screenSizes = useBreakPoints()
  const {t} = useTranslation()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  return (
    <Box display="flex" flexDirection="column" data-test-id="companyList-details">
      <Box data-test-id="company-details-actions" style={{marginBottom: '40px'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {!isMobile && (
            <Typography style={{fontWeight: 600, fontSize: '16px', color: '#54708C'}}>
              {t('companyList.companyDetailsSectionHeader')}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start',
              width: isMobile ? '100%' : 'auto',
              gap: isMobile ? '16px' : 0
            }}
          >
            <Box mr={2}>
              <IconButton
                data-test-id="edit-companyList-details-button"
                size="small"
                color="primary"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleEdit(event, company)}
                style={{boxShadow: 'none'}}
              >
                <ModeEditOutlineIcon style={{color: '#016AD4'}} />
              </IconButton>
            </Box>
            {!isMobile && (
              <Box>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  style={{boxShadow: 'none'}}
                  data-test-id="close-companyList-details-button"
                >
                  <CloseIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        <Box style={{width: 'calc(50% - 12px)'}}>
          <DetailsField
            label={t('companyList.companyName')}
            value={company.name || '-'}
            gutter
            dataTestId="companyList-details-name-field"
          />
        </Box>
        <Box style={{width: 'calc(50% - 12px)'}}>
          <DetailsField
            label={t('companyList.country')}
            value={company.country}
            gutter
            dataTestId="companyList-details-country-field"
          />
        </Box>
        {company.website && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('companyList.website')}
              value={company.website || '-'}
              gutter
              dataTestId="companyList-details-website-field"
            />
          </Box>
        )}
        {company.industry && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('companyList.industry')}
              value={company.industry || '-'}
              gutter
              dataTestId="companyList-details-industry-field"
            />
          </Box>
        )}
        {company.subscriptionPlanNames && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('companyList.subscriptions')}
              value={company.subscriptionPlanNames?.join(', ') || '-'}
              gutter
              dataTestId="companyList-details-subscriptions-field"
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
