import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {QueryObserverResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {UserInformationFormData} from '../../../components/UserInformation/UserInformationForm.types'
import {handleValidationError} from '../../../errorHandling'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {updateUser} from '../../../modules/Users.actions'

type UseUpdateUserProps = {
  setEditing: (edit: boolean) => void
  formMethods: UseFormReturn<UserInformationFormData, unknown>
  refetchUser: () => Promise<QueryObserverResult<User | undefined>>
  userData?: User
}

export const useUpdateUser = ({
  setEditing,
  formMethods,
  userData,
  refetchUser
}: UseUpdateUserProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const {t, i18n} = useTranslation()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const handleUpdateUser = async (userInformation: UserInformationFormData) => {
    if (userData) {
      try {
        // don't send availableCountries in updateUser
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {availableCountries, marketId, ...values} = userInformation

        const formattedValues =
          (values.country === 'CA' || values.country === 'US') && marketId === 'hproduce'
            ? {...values, marketId: ''}
            : {...values, marketId}
        // eslint-disable-line @typescript-eslint/no-unused-vars
        await updateUser(userData.id, formattedValues)

        enqueueSnackbar(t('manageUser.userWasUpdated'), {variant: 'success'})
        await refetchUser()
        setEditing(false)

        if (loggedInUserProfile?.id === userData.id) {
          void i18n.changeLanguage(values.defaultLocale)
        }
      } catch (e) {
        const error = e as AxiosError
        trackEvent('adminConsoleError', {
          product: 'adminConsole',
          date: new Date().toISOString(),
          errorCode: error.response?.status,
          component: 'ManageUser.tsx updateUser',
          endpoint: error.response?.config?.url
        })
        const errorMessage = handleValidationError(error, formMethods)

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      }
    }
  }

  return {handleUpdateUser}
}
