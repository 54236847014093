import {TableColumnType, useTranslation} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'

import {AnnouncementSummary} from './Announcements.create'

export const AnnouncementSummaryListRows = () => {
  const {t} = useTranslation()
  const rowsPool: {[key: string]: TableColumnType} = {
    country: {
      field: 'country',
      headerName: t('announcements.summaryCountries.country'),
      headerAlign: 'left',
      renderCell: (summary: AnnouncementSummary) => (
        <Box
          style={{fontSize: 14, fontWeight: 500}}
          dangerouslySetInnerHTML={{__html: summary.country.replace(/\n/g, '<br />')}}
        />
      )
    },
    localStartTime: {
      field: 'validFrom',
      headerName: t('announcements.summaryCountries.localStartTime'),
      headerAlign: 'left',
      renderCell: (summary: AnnouncementSummary) => (
        <Box
          style={{fontSize: 14}}
          dangerouslySetInnerHTML={{__html: summary.validFrom.replace(/\n/g, '<br />')}}
        />
      )
    },
    localEndTime: {
      field: 'validTo',
      headerName: t('announcements.summaryCountries.localEndTime'),
      headerAlign: 'left',
      renderCell: (summary: AnnouncementSummary) => (
        <Box
          style={{fontSize: 14}}
          dangerouslySetInnerHTML={{__html: summary.validTo.replace(/\n/g, '<br />')}}
        />
      )
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.country,
    rowsPool.localStartTime,
    rowsPool.localEndTime
  ]
  return rowOrder(rowsPool)
}
