import {Content, Page} from '@hconnect/uikit'
import {Box, Skeleton} from '@mui/material'
import React from 'react'

import {useStyles} from './QuickPreview.styles'

export const QuickPreviewSkeleton: React.FC = () => {
  const {classes} = useStyles()

  return (
    <Content>
      <Page variant="sheet" className={classes.page}>
        <Skeleton variant="rounded" height={60} width="100%" />
        <Box display="flex" flexDirection="column" marginY={2}>
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="100%" height={48} />
          <Skeleton variant="text" width="100%" height={48} />
          <Skeleton variant="text" width="100%" height={48} />
        </Box>
        <Box marginY={2}>
          <Skeleton variant="rounded" width="100%" height={120} />
        </Box>
        <Box marginY={2}>
          <Skeleton variant="rounded" width="100%" height={50} />
          <Skeleton variant="rounded" width="100%" height={50} />
        </Box>
      </Page>
    </Content>
  )
}
