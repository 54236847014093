import {ApiDownloadRequestStatuses} from '@hconnect/hub/src/AsyncJobQueue/AsyncJobQueue.types'
import {DatePicker, LoadingButton, SelectDropdown, Typography} from '@hconnect/uikit'
import {IconButton} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import DownloadIcon from '@mui/icons-material/Download'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText
} from '@mui/material'
import {isEmpty} from 'lodash'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useUserRoles} from '../../../common/hooks/useUserRoles'
import {FormPrompt} from '../hooks/useBlocker'
import {useExportData} from '../hooks/useExportData'

import {ExportUserCountryInput} from './ExportUserCountryInput'
import {useStyles} from './ExportUserDataDialog.styles'

type ExportUserDataDialogProps = {
  showExportUserDataDialog: boolean
  setShowExportUserDataDialog: (show: boolean) => void
  userId?: string
}

export type ExportUserFormData = {
  countryId: string
  timestamp: string
  isActive: boolean | null
}
export const ExportUserDataDialog = ({
  setShowExportUserDataDialog,
  showExportUserDataDialog,
  userId
}: ExportUserDataDialogProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [apiStatus, setApiStatus] = useState<ApiDownloadRequestStatuses>()

  const {mutateDocument, isLoading} = useExportData(setApiStatus, apiStatus)

  const {data: roles, isLoading: rolesLoading} = useUserRoles(userId)

  const exportAdminCountries =
    roles
      ?.filter((role) => role.roleType === 'EXPORT_ADMIN')
      .map((role) => role.dataScope.countryId as string) || []

  const exportUserForm = useMemo(
    () => ({
      countryId: exportAdminCountries.length === 1 ? exportAdminCountries[0] : '',
      timestamp: moment().local().toISOString(),
      isActive: null
    }),
    [exportAdminCountries]
  )

  const formMethods = useForm<ExportUserFormData>({
    defaultValues: exportUserForm,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const handleRequestDownload = (data: ExportUserFormData) => {
    mutateDocument({
      countryId: data.countryId,
      date: data.timestamp,
      active: data.isActive
    })
  }

  const options = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
    {value: null, label: 'Both'}
  ]

  const oldestAvailableTimestamp = new Date('2022-11-01')
  const formatApiResponseStatusText = (apiStatus: ApiDownloadRequestStatuses) => {
    switch (apiStatus) {
      case ApiDownloadRequestStatuses.inProgress:
        return {text: t(`usersList.export.apiStatus.${apiStatus}`), color: '#00374d'}
      case ApiDownloadRequestStatuses.cancelled:
        return {text: t(`usersList.export.apiStatus.${apiStatus}`), color: '#ff4f00'}
      case ApiDownloadRequestStatuses.timedout:
        return {text: t(`usersList.export.apiStatus.${apiStatus}`), color: '#ff4f00'}
      case ApiDownloadRequestStatuses.failed:
      case ApiDownloadRequestStatuses.partiallyFailed:
        return {
          text: t(`usersList.export.apiStatus.${apiStatus}`),
          color: '#ff4f00'
        }
      default:
        return {
          text: '',
          color: '#00374d'
        }
    }
  }

  return (
    <Dialog
      data-test-id="export-user-data-dialog"
      open={showExportUserDataDialog}
      onClose={(event, reason) =>
        (!isLoading || reason !== 'backdropClick') && setShowExportUserDataDialog(false)
      }
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{t('usersList.export.dialogTitle')}</DialogTitle>
        <IconButton
          onClick={() => setShowExportUserDataDialog(false)}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          data-test-id="modal-close-button"
          disabled={isLoading}
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent style={{width: '400px'}}>
        <DialogContentText>{t('usersList.export.dialogMessage')}</DialogContentText>
        <>
          <form onSubmit={formMethods.handleSubmit(handleRequestDownload)}>
            <FormPrompt hasUnsavedChanges={isLoading} />
            <Box style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
              <ExportUserCountryInput
                countriesIds={exportAdminCountries || []}
                formMethods={formMethods}
                isLoading={rolesLoading}
              />
              <Controller
                name="timestamp"
                control={formMethods.control}
                rules={{
                  validate: (value) => value.length > 0
                }}
                render={({field}) => {
                  return (
                    <FormControl>
                      <DatePicker
                        data-test-id="export-data-timestamp"
                        noQuickSelection
                        label={t('usersList.export.datepicker.label')}
                        handleDateChange={(dates) => {
                          field.onChange(moment(dates.startDate)?.local().toISOString() || '')
                        }}
                        availableDateRange={{from: moment(oldestAvailableTimestamp), to: moment()}}
                        singleSelection={true}
                        hideClearButton={true}
                        startDate={moment(field.value)}
                        endDate={moment(field.value)}
                      />
                      {formMethods.formState.errors.timestamp && (
                        <FormHelperText>
                          {t('usersList.export.datepicker.validation')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )
                }}
              />
              <Controller
                name="isActive"
                control={formMethods.control}
                render={({field}) => {
                  return (
                    <FormControl>
                      <SelectDropdown
                        data-test-id={'export-data-active'}
                        label={t('usersList.export.activeUsers.label')}
                        options={options}
                        renderItem={(item) => (
                          <Typography variant="body1" color="textSecondary">
                            {item.label}
                          </Typography>
                        )}
                        className={classes.activeDropdown}
                        showSearch={false}
                        selectedItem={options.find((value) => value.value === field.value)}
                        stringifyItem={(item) => `${item.label}-${item.value}`}
                        onChange={(item) => {
                          field.onChange(item.value)
                        }}
                        renderChosenItem={(item: any) => item.label}
                      />
                      {formMethods.formState.errors.isActive && (
                        <FormHelperText>
                          {t('usersList.export.activeUsers.validation')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )
                }}
              />
              <LoadingButton
                data-test-id="export-data-submit-button"
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                startIcon={<DownloadIcon style={{color: '#FFFFFF'}} fontSize="small" />}
                disabled={!isEmpty(formMethods.formState.errors)}
                classes={{root: classes.saveButton, disabled: classes.saveButtonDisabled}}
              >
                {t('usersList.export.submit')}
              </LoadingButton>
            </Box>
          </form>
          {apiStatus && (
            <Typography
              style={{color: formatApiResponseStatusText(apiStatus).color, margin: '10px 0'}}
            >
              {formatApiResponseStatusText(apiStatus).text}
            </Typography>
          )}
        </>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setShowExportUserDataDialog(false)}
          variant="text"
          color="primary"
          data-test-id="delete-user-dialog-action-cancel"
          disabled={isLoading}
        >
          {t('usersList.export.dialogActionCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
