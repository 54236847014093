import {User} from '@hconnect/apiclient'

export const handleUserData = (user?: User) => {
  let accountNumber: null | string = null
  let extraInfo: null | string = null
  if (user?.requestedCustomerNumber) {
    try {
      const data = JSON.parse(user.requestedCustomerNumber)
      accountNumber = data.accountNumber
      extraInfo = data.extraInfo
    } catch {
      console.error('ManagerUserTsx - JSON.parse(user.requestedCustomerNumber) failed')
    }
  }

  return {accountNumber, extraInfo}
}
