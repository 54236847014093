import {BoxedContainer} from '@hconnect/uikit'
import {Box, Paper, PaperProps, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `0 ${theme.spacing(2)}px`,
    zIndex: 10
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minHeight: theme.spacing(6),
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`
  },
  colorPrimary: {
    color: 'red'
  },
  icon: {
    marginRight: 10
  },
  body1: {
    fontSize: 14
  },
  info: {
    marginRight: 6
  }
}))

interface Props extends PaperProps {
  info: string
  action: string
  extraLines?: Array<{
    title: string
    description: string
  }>
}

export const UserIssueBar: React.FunctionComponent<Props> = ({
  info,
  action,
  extraLines = [],
  ...otherProps
}) => {
  const classes = useStyles()
  const lines = [{title: info, description: action}].concat(extraLines)
  return (
    <Paper className={classes.paper} square elevation={4} {...otherProps}>
      <BoxedContainer>
        <div className={classes.root}>
          <InfoRoundedIcon
            color="primary"
            className={classes.icon}
            classes={{colorPrimary: classes.colorPrimary}}
          />
          <div>
            {lines.map(({title, description}) => (
              <Box display="flex" alignItems="center" key={title}>
                <Typography className={classes.info} variant="subtitle1">
                  {title}
                </Typography>
                <Typography classes={{body1: classes.body1}} variant="body1">
                  {description}
                </Typography>
              </Box>
            ))}
          </div>
        </div>
      </BoxedContainer>
    </Paper>
  )
}
