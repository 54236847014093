import {Product, User} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {useAdminPilotUser} from '../../common/hooks/useAdminPilotUser'
import {selectLoggedInUserPermissions} from '../../modules/LoggedInUser.selectors'
import {RoleAssignment} from '../../modules/ManageUsers.selectors'

import InviteUserButton from './InviteUserButton'

interface InviteUserButtonsProps {
  user?: User | null
  products: Product[]
  refetchUser?: () => void
  variant?: 'text' | 'outlined' | 'contained'
  roles?: RoleAssignment[]
  'data-test-id': string
}

export const hasPassword = (user: User | null): boolean => {
  if (hasLoginDate(user)) return true
  // If the invitation is not required, the user will always have a password
  // If the user is invited, the password would be sent in the invitation
  return !!user?.isInvited
}

export const hasLoginDate = (user: User | null): boolean => {
  return !!user?.lastLoginDate && user?.lastLoginDate !== '0001-01-01'
}

export const InviteUserButtons = ({
  user,
  products,
  refetchUser,
  variant,
  roles,
  'data-test-id': dataTestId
}: InviteUserButtonsProps) => {
  const {t, i18n} = useTranslation()
  const permissions = useSelector(selectLoggedInUserPermissions)
  const isUserAdminPilot = useAdminPilotUser()
  const [isInviting, setIsInviting] = React.useState(false)
  const canCreateDataLinkDeveloper = permissions.find(
    (permission) => permission.permissionType === 'CREATE_DATALINK_DEVELOPER'
  )
  const isUserInternal = roles?.some((role) => role.isInternal)
  const hideProducts = (product: Product) => {
    return (
      (product === Product.Datalink && !canCreateDataLinkDeveloper) ||
      (product === Product.UserManagement && isUserAdminPilot) ||
      (product === Product.HProduce && !isUserInternal)
    )
  }

  if (!user) {
    return null
  }

  const isUserInvited = (): boolean => {
    return user.isInvited
  }

  return (
    <Box display="flex" data-test-id={dataTestId} mt={3}>
      {isUserInvited() ? (
        <Box display="flex" alignItems="top" data-test-id="invite-successful" mr={2}>
          <CheckCircleIcon style={{marginTop: '-3px', color: '#7f9ba6', fontSize: '29px'}} />
          <Box ml={1}>
            <Typography color="secondary" style={{fontWeight: 'bold'}}>
              {`${t('createUser.invitationSent')}`}
            </Typography>
            <Typography color="secondary" variant="subtitle2">
              {user.invitedOn ? new Date(user.invitedOn).toLocaleDateString(i18n.language) : ''}
            </Typography>
          </Box>
        </Box>
      ) : (
        ''
      )}
      {products.map((product, key) => {
        if (hideProducts(product)) {
          return null
        } else {
          return (
            <InviteUserButton
              data-test-id={dataTestId}
              key={key}
              product={product}
              variant={variant}
              user={user}
              refetchUser={refetchUser}
              isInviting={isInviting}
              setIsInviting={setIsInviting}
            />
          )
        }
      })}
    </Box>
  )
}
