import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {useOrgUnits} from '../../../../hooks/useOrgUnit'
import {selectLoggedInUserProfile} from '../../../../modules/LoggedInUser.selectors'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type OrgUnitInputProps = AnnouncementFormProps

export const OrgUnitInput = ({formMethods}: OrgUnitInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const businessLine = formMethods.watch('businessLines')

  const {orgUnits, isLoading} = useOrgUnits(loggedInUserProfile?.country || '', businessLine)

  const formattedData = orgUnits
    ?.map((element) => ({label: element.label, value: element.key}))
    .filter(
      (orgUnit, index, array) => array.findIndex((el) => el.value === orgUnit.value) === index
    )

  return (
    <Box
      data-test-id="announcement-orgUnit-dropdown"
      style={{display: 'flex', flexDirection: 'column'}}
    >
      <Typography style={{fontSize: '18px', fontWeight: 600, marginBottom: '12px'}}>
        {t('announcements.form.orgUnits')}
      </Typography>
      <Controller
        name="orgUnitIds"
        control={formMethods.control}
        render={({field}) => {
          return (
            <SelectDropdown
              data-test-id={`announcement-form-${field.name}`}
              label={t('announcements.form.orgUnits')}
              className={classes.selectDropdown}
              errorRender={() => <ErrorPaper variant="primaryDark" />}
              options={formattedData}
              renderItem={(item: Item) => (
                <Typography variant="body1" color="textSecondary">
                  {item.label}
                </Typography>
              )}
              noSelectionLabel={t('announcements.form.allOrgUnits')}
              loading={isLoading}
              showSearch={true}
              stringifyItem={(item: Item) => `${item.label}-${item.value}`}
              selectedItem={formattedData?.filter((value) => {
                return field.value.includes(value.value)
              })}
              disabled={businessLine.length < 1}
              multiSelect={true}
              onChange={(item: Item) => {
                if (item) {
                  const currentVal = Array.isArray(field.value) ? [...field.value] : []
                  const index = currentVal.findIndex((items) => items === item.value)
                  if (index === -1) {
                    currentVal.push(item.value)
                  } else {
                    currentVal.splice(index, 1)
                  }
                  field.onChange(currentVal)
                } else {
                  field.onChange([])
                }
              }}
              popoverStyle={{left: 0}}
              renderChosenItem={(items: Item | Item[]) => {
                if (Array.isArray(items)) {
                  return items.length > 0
                    ? items.map((item) => item.label).join(', ')
                    : t('announcements.form.allOrgUnits')
                } else {
                  return items.label
                }
              }}
            />
          )
        }}
      />
    </Box>
  )
}
