import {SingleDatePicker, Typography, useBreakPoints} from '@hconnect/uikit'
import {Box, FormControl, FormHelperText} from '@material-ui/core'
import {TextField} from '@mui/material'
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import moment, {Moment} from 'moment/moment'
import React, {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {MessageTypes} from '../../common'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type AnnouncementDatesInputProps = AnnouncementFormProps

export const AnnouncementDatesInput = ({formMethods}: AnnouncementDatesInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const timeFrom = formMethods.formState.defaultValues?.timeFrom
  const timeTo = formMethods.formState.defaultValues?.timeTo

  const [timeFromValue, setTimeFromValue] = useState<Moment | null>(moment(timeFrom))
  const [timeToValue, setTimeToValue] = useState<Moment | null>(moment(timeTo))
  const [dateFromValue, setDateFromValue] = useState<Moment | null>(
    moment(formMethods.formState.defaultValues?.validFrom)
  )
  const [dateToValue, setDateToValue] = useState<Moment | null>(
    moment(formMethods.formState.defaultValues?.validTo)
  )
  const {formState} = formMethods

  const validFromValidate = (validFrom: string | Date) => {
    const selectedMoment = moment(validFrom)
    return selectedMoment.isValid() && selectedMoment.isSameOrAfter(moment())
  }

  const validToValidate = (validTo: string | Date) => {
    const validFrom = formMethods.getValues('validFrom')
    if (!validFrom) {
      return true
    }

    const validFromMoment = moment(validFrom)
    const validToMoment = moment(validTo)

    if (!validFromMoment.isValid() || !validToMoment.isValid()) {
      return true
    }

    return validToMoment.isAfter(validFromMoment)
  }

  const combineDateTime = (date?: Moment | null, time?: Moment | null) => {
    if (!date || !time) {
      return null
    }
    return moment().set({
      year: date.year(),
      month: date.month(),
      date: date.date(),
      hour: time.hour(),
      minute: time.minute()
    })
  }

  useEffect(() => {
    const fullDateFrom = combineDateTime(dateFromValue, timeFromValue)
    if (fullDateFrom) {
      formMethods.setValue('validFrom', fullDateFrom.toISOString(true), {shouldValidate: true})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromValue, timeFromValue])

  useEffect(() => {
    const fullDateTo = combineDateTime(dateToValue, timeToValue)
    if (fullDateTo) {
      formMethods.setValue('validTo', fullDateTo.toISOString(true), {shouldValidate: true})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromValue, timeFromValue, dateToValue, timeToValue])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        data-test-id="announcement-form-top-row"
        style={{display: 'flex', flexDirection: 'column'}}
      >
        <Typography style={{fontSize: '18px', fontWeight: 600, marginBottom: '12px'}}>
          {t('announcements.form.announcementDates', {
            messageType:
              formMethods.getValues().messageType === (MessageTypes.maintenance as string)
                ? formMethods.getValues().messageType
                : t('announcements.form.announcementDatesBase')
          })}
        </Typography>

        <Box data-test-id="announcement-valid-from-picker" style={{marginBottom: '12px'}}>
          <Box style={{display: 'flex', gap: '12px', flexDirection: isMobile ? 'column' : 'row'}}>
            <Controller
              name="validFrom"
              control={formMethods.control}
              rules={{
                validate: (v) => validFromValidate(v)
              }}
              render={({field}) => (
                <FormControl>
                  <SingleDatePicker
                    label={t('announcements.form.validFrom')}
                    onLight
                    noQuickSelection
                    showTooltip={false}
                    handleDateChange={({startDate}) => {
                      field.onChange(startDate)
                      setDateFromValue(startDate)
                    }}
                    date={moment(field.value)}
                    textClasses={classes.datepicker}
                  />
                  {formState.errors.validFrom?.type === 'validate' ? (
                    <FormHelperText
                      style={{marginLeft: 0}}
                      component="div"
                      error={formState.errors.validFrom?.type === 'validate'}
                    >
                      {t('announcements.form.validateDate')}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
            <Controller
              name="timeFrom"
              control={formMethods.control}
              render={({field}) => (
                <FormControl>
                  <TimePicker
                    onChange={(value) => {
                      field.onChange(value)
                      setTimeFromValue(value)
                    }}
                    value={moment(field.value)}
                    inputFormat="HH:mm"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={t('announcements.form.timeFrom')}
                        className={classes.timepicker}
                        focused={false}
                        InputProps={{endAdornment: undefined}}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
        <Box data-test-id="announcement-valid-to-picker">
          <Box style={{display: 'flex', gap: '12px', flexDirection: isMobile ? 'column' : 'row'}}>
            <Controller
              name="validTo"
              control={formMethods.control}
              rules={{
                validate: (v) => validToValidate(v)
              }}
              render={({field}) => (
                <FormControl>
                  <SingleDatePicker
                    label={t('announcements.form.validTo')}
                    onLight
                    noQuickSelection
                    showTooltip={false}
                    handleDateChange={({endDate}) => {
                      field.onChange(endDate)
                      setDateToValue(endDate)
                    }}
                    date={moment(field.value)}
                    textClasses={classes.datepicker}
                  />
                  {formState.errors.validTo?.type === 'validate' ? (
                    <FormHelperText
                      style={{marginLeft: 0}}
                      component="div"
                      error={formState.errors.validTo?.type === 'validate'}
                    >
                      {t('announcements.form.validateDate')}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
            <Controller
              name="timeTo"
              control={formMethods.control}
              render={({field}) => (
                <FormControl>
                  <TimePicker
                    onChange={(value) => {
                      field.onChange(value)
                      setTimeToValue(value)
                    }}
                    value={moment(field.value)}
                    inputFormat="HH:mm"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={t('announcements.form.timeTo')}
                        className={classes.timepicker}
                        focused={false}
                        InputProps={{endAdornment: undefined}}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}
