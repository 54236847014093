import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {IconTextButton} from '../../components/IconTextButton'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'

import {CloneIcon} from './CloneIcon'

const ALLOWED_ROLES = new Set(['GLOBAL_ADMIN', 'SUPER_ADMIN', 'SUPPORT_AGENT'])

type CloneUserButtonProps = {
  isLoading: boolean
  refetch: () => void
}

export const CloneUserButton = ({isLoading, refetch}: CloneUserButtonProps) => {
  const {t} = useTranslation()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const loggedInUserIsAllowedToClone = loggedInUserProfile?.hasRoles.some((role: string) =>
    ALLOWED_ROLES.has(role)
  )

  if (!loggedInUserIsAllowedToClone) {
    return null
  }

  return (
    <IconTextButton
      loading={isLoading}
      label={t('manageUser.cloneUser')}
      IconComponent={CloneIcon}
      onClick={() => refetch()}
      data-test-id="button-clone-user"
    />
  )
}
