import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Typography} from '@hconnect/uikit'
import {Box, Button} from '@material-ui/core'
import {Delete, Sync} from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {IconTextButton} from '../../../components/IconTextButton'
import {UserInformationForm} from '../../../components/UserInformation/UserInformationForm'
import {UserInformationFormData} from '../../../components/UserInformation/UserInformationForm.types'
import {UserInformationFormMode} from '../../../components/UserInformation/utils/utils'
import {selectLoggedInUserPermissions} from '../../../modules/LoggedInUser.selectors'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {CloneUserButton} from '../CloneUserButton'
import {CloneUserDialog} from '../dialogs/CloneUserDialog'
import {useCloneUser} from '../hooks/useCloneUser'
import {ViewAuditButton} from '../ViewAuditButton'

import {useStyles} from './ManageUserHeader.styles'

type ManageUserHeaderProps = {
  isEditing: boolean
  setEditing: (editing: boolean) => void
  setShowDeleteUserDialog: (show: boolean) => void
  setShowResetPasswordDialog: (show: boolean) => void
  handleUpdateUser: (userInformation: UserInformationFormData) => Promise<void>
  formMethods: UseFormReturn<UserInformationFormData, object>
  isLoading: boolean
  isTfaError: boolean
  isPilot?: boolean
  user?: User
  tfaData?: any
  roles?: RoleAssignment[] | null
}
export const ManageUserHeader = ({
  isEditing,
  setEditing,
  setShowDeleteUserDialog,
  setShowResetPasswordDialog,
  formMethods,
  handleUpdateUser,
  user,
  isLoading,
  isPilot = false,
  tfaData,
  isTfaError,
  roles
}: ManageUserHeaderProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const permissions = useSelector(selectLoggedInUserPermissions)
  const [showCloneDialog, setShowCloneDialog] = useState(false)
  const {data, refetch, isError, error: cloneError, isFetching} = useCloneUser(user?.user_id || '')
  const {enqueueSnackbar} = useSnackbar()
  const handleFormMode = (edit: boolean) => {
    setEditing(edit)
  }

  const canResetPassword = permissions.some(
    (permission) => permission.permissionType === 'CHANGE_USER_LOGIN'
  )

  const canDeleteUser = permissions.some(
    (permission) => permission.permissionType === 'DELETE_USERS'
  )

  useEffect(() => {
    if (data && data.length > 0) {
      setShowCloneDialog(true)
    }

    if (data && data.length === 0) {
      enqueueSnackbar(t('manageUser.cloneError'), {variant: 'error'})
    }

    if (isError) {
      const error = cloneError as AxiosError
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'ManageUser.tsx Reset password',
        endpoint: error.response?.config?.url
      })
      enqueueSnackbar(
        error.response?.status === 400 || error.response?.status === 403
          ? t('manageUser.hasNoPermissions')
          : t('manageUser.cloneError'),
        {variant: 'error'}
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, isFetching])

  const renderUserInfo = () => (
    <form onSubmit={formMethods.handleSubmit(handleUpdateUser)}>
      <UserInformationForm
        formMethods={formMethods}
        formMode={UserInformationFormMode.MANAGE}
        readonlyMode={!isEditing}
        user={user}
        isLoading={isLoading}
        tfaData={tfaData}
        isTfaError={isTfaError}
        shouldShowTfaInput={true}
      />
      {isEditing && (
        <Box mt={3}>
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={() => handleFormMode(false)}
            className={classes.discardChangesButton}
            disabled={formMethods.formState.isSubmitting}
          >
            {t('manageUser.discardChanges')}
          </Button>
          <Button
            data-test-id="button-save-changes"
            type="submit"
            color="primary"
            disabled={formMethods.formState.isSubmitting}
          >
            {t('manageUser.saveChanges')}
          </Button>
        </Box>
      )}
    </form>
  )

  return (
    <Box mb={4}>
      <Box className={classes.sectionHeader}>
        <Box display="flex" flexGrow={1} alignItems="center" alignSelf="stretch">
          <Typography variant="h2">
            {isEditing ? t('manageUser.editUserInformation') : t('manageUser.userInformation')}
          </Typography>
          {!isEditing && (
            <Box mx={1}>
              <IconTextButton
                label={t('manageUser.edit')}
                data-test-id="button-edit-userInfo"
                IconComponent={EditIcon}
                onClick={() => handleFormMode(true)}
              />
            </Box>
          )}
        </Box>
        {!isPilot && (
          <Box>
            <ViewAuditButton
              auditType="user"
              userIdentifier={user?.id ?? ''}
              userCountry={user?.country ?? ''}
            />
            <CloneUserButton isLoading={isFetching} refetch={refetch} />
            <IconTextButton
              label={t('manageUser.resetPassword')}
              IconComponent={Sync}
              onClick={() => setShowResetPasswordDialog(true)}
              data-test-id="button-reset-password"
              hide={!canResetPassword}
            />
            <IconTextButton
              label={t('manageUser.deleteUser')}
              IconComponent={Delete}
              onClick={() => setShowDeleteUserDialog(true)}
              data-test-id="button-delete-user"
              hide={!canDeleteUser}
            />
          </Box>
        )}
      </Box>
      {renderUserInfo()}
      {showCloneDialog && data && (
        <CloneUserDialog
          showCloneUserDialog={showCloneDialog}
          setShowCloneUserDialog={setShowCloneDialog}
          roles={roles?.sort((a, b) => data.indexOf(b.id) - data.indexOf(a.id)) || []}
          clonableRoleIds={data}
          userId={user?.user_id || ''}
        />
      )}
    </Box>
  )
}
