import {Country} from '@hconnect/apiclient'
import {Typography, useBreakPoints} from '@hconnect/uikit'
import {Box, Tooltip} from '@mui/material'
import React from 'react'

interface CountriesType {
  countryCodes: string[]
  countries?: Country[]
  showAll?: boolean
}

export const Countries: React.FC<CountriesType> = ({countryCodes, countries, showAll = false}) => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const countryMapping = countries
    ? countryCodes.map(
        (countryCode) =>
          countries.find((country) => country.countryCode === countryCode)?.countryName ||
          countryCode
      )
    : countryCodes
  const sortedCountryMapping = countryMapping.sort((a, b) => a.localeCompare(b))

  if (!showAll && countryCodes.length > 4) {
    return (
      <Tooltip title={sortedCountryMapping.join(', ')} arrow placement="bottom">
        <Box display="flex" flexWrap="nowrap" whiteSpace={`${isMobile ? 'pre-wrap' : 'nowrap'}`}>
          <Typography component="div" style={{fontSize: '14px'}}>
            {sortedCountryMapping.slice(0, 3).join(', ')}
            <Typography component="div" style={{fontSize: '14px'}}>
              +{`${countryCodes.length - 3} more`}
            </Typography>
          </Typography>
        </Box>
      </Tooltip>
    )
  }
  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: isMobile ? 'pre-wrap' : 'nowrap'
      }}
    >
      <Typography component="div" style={{fontSize: '14px'}}>
        {sortedCountryMapping.join(', ')}
      </Typography>
    </Box>
  )
}
