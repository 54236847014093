import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  container: {
    padding: '24px 48px'
  },
  documentContainer: {
    marginTop: '24px',
    padding: '1px 24px'
  }
}))
