import {Content, Page, TitleNav, Typography, useBreakPoints} from '@hconnect/uikit'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {Box, Button} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router'

import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {CompanyFormData} from '../../components/Company/CompanyForm.types'
import {ManageCompanySubscriptions} from '../../components/Company/manageCompanySubscriptions/ManageCompanySubscriptions'
import {CompanyDetailView} from '../../components/Company/ManageCompanyView'
import {ManageCompanyMembers} from '../../components/Company/manageMemberAssignment/ManageCompanyMembers'
import {useFetchCompanyDetail} from '../../hooks/useCompany'

import {useStyles} from './ManageCompany.styles'

export const ManageCompany = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)

  const {t} = useTranslation()
  const history = useHistory()
  const {classes} = useStyles()
  const {companyId} = useParams<{companyId: string}>()
  const {enqueueSnackbar} = useSnackbar()
  const [pageTitle, setPageTitle] = useState<string>(t('company.manage.title'))
  const {data: availableCountries, error: errorAvailableCountries} = useCountriesConfiguration()
  const {data: company, isSuccess, isError} = useFetchCompanyDetail(companyId)
  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      id: '',
      country: '',
      availableCountries: [],
      website: '',
      industry: '',
      name: ''
    },
    mode: 'all',
    reValidateMode: 'onChange'
  })

  if (isError) {
    enqueueSnackbar(t('company.manage.cannotLoadCompanyDetails'), {
      anchorOrigin: {vertical: 'top', horizontal: 'center'},
      variant: 'error'
    })
  }

  useEffect(() => {
    if (company) {
      setPageTitle(`${t('company.manage.title')} | ${company.name}`)

      formMethods.reset({
        id: company.id,
        country: company.country,
        website: company.website,
        industry: company.industry,
        availableCountries: availableCountries,
        name: company.name
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, availableCountries])

  if (errorAvailableCountries) {
    enqueueSnackbar(t('company.manage.cannotLoadAvailableCountries'), {
      anchorOrigin: {vertical: 'top', horizontal: 'center'},
      variant: 'error'
    })
  }

  return (
    <Content data-test-id="page-company-detail-content">
      <Page
        data-test-id="page-company-detail-page"
        title={pageTitle}
        classNameHeader={classes.pageHeader}
        aboveTitle={
          <Box className={classes.backButton}>
            <TitleNav
              title={t('company.manage.goBack')}
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIos />
                    <Typography>{t('company.manage.goBack')}</Typography>
                  </Box>
                )
              }
              data-test-id="back-to-company-overview-button"
              onClick={() => history.push('/companies')}
              className={classes.pageAboveTitle}
            />
          </Box>
        }
        headerActionContent={
          <>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              data-test-id="create-user-button"
              onClick={() => history.push('/createUser')}
              className={classes.addUserButton}
            >
              {t('company.manage.createUserButton')}
            </Button>

            <Button
              variant="contained"
              startIcon={<AddIcon />}
              data-test-id="create-company-button"
              onClick={() => history.push('/companies/create')}
              className={classes.addCompanyButton}
            >
              {t('company.manage.createCompanyButton')}
            </Button>
          </>
        }
        boxed={false}
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        {isSuccess && (
          <>
            <CompanyDetailView formMethods={formMethods} />

            <ManageCompanySubscriptions companyId={companyId}/>

            <ManageCompanyMembers members={company?.companyMembers ?? []} companyId={companyId} />
          </>
        )}
      </Page>
    </Content>
  )
}
