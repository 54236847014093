import {ApiDownloadRequestStatuses} from '@hconnect/hub/src/AsyncJobQueue/AsyncJobQueue.types'
import {useMutation, useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import fileDownload from 'js-file-download'
import {useEffect, useState} from 'react'

import {api} from '../../../App.store'

export type ExportUserParams = {
  countryId: string
  active: boolean | null
  date?: string
}

const INTERVAL_PERIOD = 5000
const TIMEOUT_PERIOD = 300000

export const useExportData = (
  setApiStatus: (apiStatus?: ApiDownloadRequestStatuses) => void,
  apiStatus?: ApiDownloadRequestStatuses
) => {
  const [refetchInterval, setRefetchInterval] = useState(INTERVAL_PERIOD)
  const [documentId, setDocumentId] = useState()
  const [filename, setFilename] = useState('')
  const [isPolling, setIsPolling] = useState(false)

  const [isFileReady, setIsFileReady] = useState(false)

  const exitStatuses = [
    ApiDownloadRequestStatuses.done,
    ApiDownloadRequestStatuses.partiallyFailed,
    ApiDownloadRequestStatuses.cancelled,
    ApiDownloadRequestStatuses.failed
  ]

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined

    if (
      apiStatus === ApiDownloadRequestStatuses.pending ||
      apiStatus === ApiDownloadRequestStatuses.inProgress
    ) {
      timer = setTimeout(() => {
        setApiStatus(ApiDownloadRequestStatuses.timedout)
        setRefetchInterval(0)
        setIsPolling(false)
      }, TIMEOUT_PERIOD)
    }

    if (apiStatus && exitStatuses.includes(apiStatus)) {
      setRefetchInterval(0)
      clearTimeout(timer)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [apiStatus])

  const {
    mutateAsync: mutateDocument,
    isLoading: loadingDocumentId,
    isError: isDownloadRequestError
  } = useMutation(
    async (params?: ExportUserParams) => {
      const response: AxiosResponse = await api.post('/downloadRequests', {
        criteria: {
          ...params
        },
        format: 'xlsx',
        type: 'userExport',
        country: params?.countryId
      })

      return response.data
    },
    {
      onSuccess: (response) => {
        setDocumentId(response.id)
        setFilename(response.name + '.xlsx')
        setIsPolling(true)
        setRefetchInterval(INTERVAL_PERIOD)
      }
    }
  )

  const {isError: isDownloadStatusError} = useQuery(
    ['export-user-data-file-status', documentId],
    async () => {
      const response = await api.get(`/downloadRequests/${documentId}`)
      return response.data
    },
    {
      refetchInterval: refetchInterval,
      refetchIntervalInBackground: true,
      enabled: !!documentId,
      cacheTime: 0,
      onSuccess: (response) => {
        const apiStatus = response.status.toLowerCase()
        setIsPolling(!exitStatuses.includes(apiStatus))
        setIsFileReady(apiStatus === ApiDownloadRequestStatuses.done)
        setApiStatus(apiStatus)
      },
      onError: () => {
        setRefetchInterval(0)
        setIsPolling(false)
      }
    }
  )

  const {isInitialLoading: loadingFile, isError: isDownloadFileError} = useQuery(
    ['export-user-data-file', documentId],
    async () => {
      const response: AxiosResponse<Buffer> = await api(`/downloadRequests/${documentId}/file`, {
        responseType: 'blob'
      })
      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})
      fileDownload(blob, filename)
    },
    {
      enabled: isFileReady,
      cacheTime: 0,
      onSuccess: () => {
        setIsFileReady(false)
        setApiStatus(undefined)
      }
    }
  )

  return {
    mutateDocument,
    isLoading: loadingDocumentId || isPolling || loadingFile,
    isError: isDownloadRequestError || isDownloadStatusError || isDownloadFileError
  }
}
