import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  overlay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: '0px',
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#2d2d2d'
    }
  },
  backdrop: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(20, 26, 31, 0.8)',
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'white'
      }
    }
  },
  downloadButton: {
    textTransform: 'none',
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    backgroundColor: 'rgb(56,56,59)',
    color: '#FFFFFF',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgb(40,40,42)'
    }
  },
  deleteButton: {
    textTransform: 'none',
    padding: '13px 16px',
    whiteSpace: 'nowrap',
    background: '#DA0901',
    color: '#FFFFFF',
    width: '100%',
    '&:hover': {
      backgroundColor: '#A20500'
    }
  }
}))
