import {Content, EnvAppInfoContainer} from '@hconnect/uikit'
import React from 'react'

export const Version: React.FunctionComponent = () => (
  <Content>
    <div style={{textAlign: 'center', paddingTop: '20px'}}>
      <EnvAppInfoContainer />
    </div>
  </Content>
)
