import {Content, Page, ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Button, Grid, Paper} from '@mui/material'
import {useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {api} from '../../App.store'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {useQueryParamState} from '../../common/QueryParamStateHook'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'

import {useStyles} from './Announcements.styles'
import {AnnouncementsListRows} from './AnnouncementsListRows'
import {AnnouncementDetails} from './components'
import {useAnnouncementDetails, useAnnouncements} from './hooks/useAnnouncements'
import {useAnnouncementsFilters} from './hooks/useAnnouncementsFilters'

const deleteAnnouncement = (messageId?: string) => async () => {
  return messageId && (await api.delete(`/announcements/${messageId}`))
}

export type AnnouncementsFilter = {
  rowsPerPage: number
  page: number
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
  [filterField: string]: string | number | undefined
}

export interface Announcement {
  messageId: string
  countries: string[]
  businessLines: ('CEM' | 'RMC' | 'AGG')[]
  products: string[] // enum over products?
  validFrom: string | Date
  validTo: string | Date
  message: string
  createdOn: string | Date
  modifiedOn: string | Date
  messageType: 'information' | 'warning'
  orgUnitIds: string[]
  author: string
  pillarType?: 'hConnect' | 'hProduce'
}

// eslint-disable-next-line complexity
export const Announcements: React.FC = () => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()
  const history = useHistory()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isExtraLarge = ['xl'].includes(screenSizes)
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const loggedInUserId = loggedInUserProfile?.user_id

  const [filter, setFilter] = useQueryParamState<AnnouncementsFilter>({
    rowsPerPage: 10,
    page: 1,
    sortedByKey: 'creationDate',
    sortedByDirection: 'desc'
  })
  const [announcementDetails, setDetails] = useState<Announcement | undefined>(undefined)
  const {data, isFetching, refetch: reloadAnnouncements, error} = useAnnouncements(filter)
  const {data: announcementDetailsData, isLoading: isDetailsLoading} = useAnnouncementDetails(
    announcementDetails?.messageId
  )
  const {data: countries, isFetching: isCountriesFetching} = useCountriesConfiguration()
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortedByKey === sortKey && filter.sortedByDirection === 'asc')
    setFilter({
      ...filter,
      sortedByKey: sortKey,
      sortedByDirection: asc ? 'asc' : 'desc'
    })
  }

  const {
    filterInputValues,
    setFilterInputValues,
    handleDatePickerFilterInputValues,
    handleFilterInputValues,
    setRemovedFilter
  } = useAnnouncementsFilters(filter, setFilter)

  const noResultsMessage = error ? t('announcements.errorOnFetching') : t('announcements.noResults')

  const totalPageCount = data?.total || 0

  const handleClose = () => setDetails(undefined)

  const deleteAnnouncementMutation = useMutation(
    deleteAnnouncement(announcementDetails?.messageId),
    {
      onSuccess: () => {
        enqueueSnackbar(t('announcements.form.successDeleteMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'success'
        })
        reloadAnnouncements()
      },
      onError: () => {
        enqueueSnackbar(t('announcements.form.errorDeleteMsg'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
        reloadAnnouncements()
      }
    }
  )
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>, announcementId: string) => {
    event.preventDefault()
    if (announcementDetails && announcementId) {
      deleteAnnouncementMutation.mutateAsync()
    }
    setDetails(undefined)
  }
  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>, announcement: Announcement) => {
    event.preventDefault()
    history.push(`/announcements/manage/${announcement.messageId}`)
    setDetails(undefined)
  }

  const SnackbarActionButton = () => (
    <Button onClick={() => void reloadAnnouncements()} variant="text" color="primary">
      {t('usersList.retry')}
    </Button>
  )

  if (error) {
    enqueueSnackbar(
      error?.error?.message ? error.error.message : t('announcements.errorOnFetching'),
      {
        action: <SnackbarActionButton />,
        variant: 'error'
      }
    )
  }

  return (
    <Content>
      <Page
        data-test-id="page-announcements"
        title={t('announcements.title')}
        boxed={false}
        headerActionContent={
          <Button
            variant="contained"
            data-test-id="create-user-button"
            onClick={() => history.push('/announcements/create')}
            style={{
              background: 'rgba(255, 255, 255, 0.05)',
              fontWeight: 500,
              border: '1px solid rgba(255, 255, 255, 0.15)',
              marginTop: isMobile ? '12px' : 0
            }}
          >
            <ArrowForwardIcon className={classes.addIcon} fontSize="small" />
            {t('announcements.createNewAnnouncement')}
          </Button>
        }
        classNameHeader={classes.pageHeader}
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid
          container
          style={{columnGap: '16px', marginTop: '22px', alignItems: 'stretch'}}
          wrap="nowrap"
        >
          <Grid
            item
            md={announcementDetails && !isMobile ? 8 : 12}
            sm={12}
            xs={12}
            style={{height: '100%'}}
          >
            <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
              <ResponsiveTable
                keyField="messageId"
                columns={AnnouncementsListRows({countries})}
                rows={data?.announcements ?? []}
                isRowSelectable={isMobile ? undefined : () => true}
                onSort={(e, sortKey) => setSortKey(sortKey)}
                sortedBy={filter.sortedByKey}
                sortingOrder={filter.sortedByDirection}
                onRowClick={(item: Announcement) => {
                  if (item.messageId === announcementDetails?.messageId) {
                    setDetails(undefined)
                  } else {
                    setDetails(item)
                  }
                }}
                emptyMessage={!isFetching && !isCountriesFetching && noResultsMessage}
                loading={isFetching || isCountriesFetching}
                isMobile={isMobile}
                page={filter.page}
                rowsPerPage={filter.rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                count={totalPageCount}
                enableHorizontalScroll={!isExtraLarge}
                mobileRowDetails={
                  announcementDetailsData &&
                  announcementDetails && (
                    <AnnouncementDetails
                      announcement={announcementDetails}
                      t={t}
                      isLoading={isDetailsLoading}
                      countries={countries}
                      handleClose={handleClose}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                    />
                  )
                }
                filterListInputValues={filterInputValues}
                setFilterListInputValues={setFilterInputValues}
                onFilterInputChange={(props: TableFilterItemProps) =>
                  props.filterDatePickerRangeOptions
                    ? handleDatePickerFilterInputValues(props)
                    : handleFilterInputValues(props)
                }
                onFilterRemove={(filter: TableFilterType) => {
                  setRemovedFilter(filter)
                  setFilterInputValues(
                    filterInputValues.filter((inputValue) => {
                      return inputValue.filterType === TableFilterTypeProps.DATEPICKER_RANGE
                        ? inputValue.filterDatePickerRangeOptions?.validTo?.filterField !==
                            filter.filterDatePickerRangeOptions?.validTo?.filterField
                        : inputValue.filterField !== filter.filterField
                    })
                  )
                }}
                extraFilters={[
                  {
                    filterName: 'Message',
                    filterField: 'message',
                    filterType: TableFilterTypeProps.TEXT
                  }
                ]}
                enableColumnSelection={true}
                storeFiltersOptions={
                  loggedInUserId ? {userId: loggedInUserId, tableName: 'announcements'} : undefined
                }
                tableHeight="70vh"
                stickyHeader={true}
                enablePinning={true}
              />
            </Paper>
          </Grid>
          {!isMobile && announcementDetails ? (
            <Grid item md={4} sm={0} xs={0}>
              <Paper elevation={4} style={{padding: '26px 53px 26px 32px', height: '100%'}}>
                <AnnouncementDetails
                  announcement={announcementDetails}
                  author={announcementDetailsData?.author}
                  t={t}
                  isLoading={isDetailsLoading}
                  countries={countries}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Page>
    </Content>
  )
}
