import Add from '@mui/icons-material/Add'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useStyles} from './CompanyList.styles'

export const CompanyHeaderActions: React.FC = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <div>
      <Button
        variant="contained"
        data-test-id="create-user-button"
        onClick={() => history.push('/createUser')}
        className={classes.createButton}
        style={{marginRight: '10px'}}
      >
        <Add className={classes.addIcon} />
        {t('companyList.addUser')}
      </Button>
      <Button
        variant="contained"
        data-test-id="second-action-button"
        onClick={() => history.push('/companies/create')}
        className={classes.createButton}
      >
        <Add className={classes.addIcon} />
        {t('companyList.addCompany')}
      </Button>
    </div>
  )
}
