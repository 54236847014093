import {CreationChannel, User} from '@hconnect/apiclient'
import {trackEvent, trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {Content, Page} from '@hconnect/uikit'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper
} from '@material-ui/core'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {isProd} from '../../common/Constants'
import {generateEventTrackBodyFromUser} from '../../common/EventTracking/utils'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {useUser} from '../../common/hooks/useUser'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {UserInformationForm} from '../../components/UserInformation/UserInformationForm'
import {UserInformationFormData} from '../../components/UserInformation/UserInformationForm.types'
import {
  getLiveProducts,
  UserInformationFormMode
} from '../../components/UserInformation/utils/utils'
import {handleValidationError} from '../../errorHandling'
import {Roles} from '../../layouts/Roles/Roles'
import {createUser} from '../../modules/Users.actions'
import {InviteUserButtons} from '../../pages/ManageUser/InviteUserButtons'

import {useStyles} from './CreateUser.styles'

enum STEPS {
  ADD_USER_INFORMATION = 0,
  ASSIGN_ROLES = 1,
  INVITE_USER = 2
}

export const CreateUser: React.FC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()
  const history = useHistory()
  const [userInformation, setUserInformation] = useState<User>()
  const [createAnotherUser, setCreateAnotherUser] = useState<boolean>()
  const [step, setStep] = useState<STEPS>(STEPS.ADD_USER_INFORMATION)
  const [isUserCreating, setIsUserCreating] = useState<boolean>(false)
  const {
    data: availableCountries,
    isLoading: loadingAvailableCountries,
    error: errorAvailableCountries
  } = useCountriesConfiguration()

  const getCountry = () => {
    const countryIds = availableCountries?.map((country) => country.countryCode)
    return countryIds?.length ? countryIds[0] : ''
  }

  const country = getCountry()

  const formMethods = useForm<UserInformationFormData>({
    defaultValues: {
      country,
      availableCountries: availableCountries ?? [],
      companyName: '',
      defaultBranding: '',
      defaultLocale: '',
      eMail: '',
      isTester: !isProd,
      marketId: '',
      mobileNumber: '',
      name: '',
      primaryOrganisationalUnit: ''
    },
    mode: 'all',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    formMethods.setValue('country', country, {shouldValidate: true})
    formMethods.setValue('availableCountries', availableCountries ?? [], {shouldValidate: true})
  }, [formMethods, country, availableCountries])

  const handleSave = () => {
    if (createAnotherUser) {
      setUserInformation(undefined)
      setStep(STEPS.ADD_USER_INFORMATION)
    } else {
      history.push('/users')
    }
  }

  const userId = userInformation ? userInformation.id : null

  const {data: user, refetch: refetchUser} = useUser(userId)

  const {data: roles, refetch: getRoles} = useUserRoles(userId)

  const handleCreateUser = async (userInformation: Partial<User>): Promise<User> => {
    const payload = {...userInformation}

    Object.keys(payload).forEach(
      (k) => (payload[k] === '' || payload[k] === 'hproduce') && delete payload[k]
    )

    payload.creationProduct = 'HConnect'
    payload.isTermsApprovalRequired = true
    payload.creationChannel = 'portalRegistration' as CreationChannel
    payload.isActive = false
    const user = await createUser(payload)

    return user
  }

  const liveProducts = getLiveProducts(availableCountries ?? null, userInformation)

  const headerText =
    step === STEPS.ADD_USER_INFORMATION
      ? t('createUser.inviteUser')
      : t('createUser.inviteUserPersonalized', {
          name: userInformation?.name || userInformation?.eMail
        })

  // TODO refactor to reactQuery
  const handleSubmitForm = async (userInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {availableCountries, ...cleanUserInformation} = userInformation
    try {
      setIsUserCreating(true)
      const user = await handleCreateUser(cleanUserInformation)
      setUserInformation(user)
      setStep(STEPS.ASSIGN_ROLES)

      trackEventWithBrowserProps('adminUserCreateSuccess', {
        ...generateEventTrackBodyFromUser(user),
        creationChannel: 'adminConsole'
      })

      enqueueSnackbar(
        t('createUser.userWasCreated', {
          name: user.name || user.eMail
        }),
        {anchorOrigin: {vertical: 'top', horizontal: 'center'}, variant: 'success'}
      )
    } catch (err) {
      const error = err as AxiosError
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'CreateUser.tsx',
        endpoint: error.response?.config?.url
      })
      const errorMessage = handleValidationError(error, formMethods)
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    } finally {
      setIsUserCreating(false)
    }
  }

  const renderEditUserInfoForm = () => {
    if (errorAvailableCountries) {
      return <div>{t('roleAssignment.labelFetchError')}</div>
    }

    if (loadingAvailableCountries) {
      return <CircularProgress size={20} />
    }

    return (
      <form id="create-user-form" onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
        <UserInformationForm
          formMethods={formMethods}
          formMode={UserInformationFormMode.CREATE}
          shouldShowTfaInput={false}
        />
        <div className={classes.errorContainer}>
          <Button color="primary" type="submit" disabled={isUserCreating}>
            {t('createUser.nextStep')}
          </Button>
        </div>
      </form>
    )
  }

  return (
    <Content>
      <Page title={t('createUser.title')}>
        <Stepper
          orientation="vertical"
          activeStep={step}
          connector={<StepConnector classes={{lineVertical: classes.stepConnector}} />}
        >
          <Step key="addUserInformation">
            <StepLabel
              classes={{label: classes.stepLabel}}
              StepIconProps={{classes: {root: classes.stepIcon}}}
            >
              <div className={classes.sectionHeader}>{t('createUser.addUserInformation')}</div>
            </StepLabel>
            <StepContent
              className={classes.stepLine}
              TransitionProps={{mountOnEnter: true, unmountOnExit: true}}
            >
              {renderEditUserInfoForm()}
            </StepContent>
          </Step>
          <Step key="assignRoles">
            <StepLabel
              classes={{label: classes.stepLabel}}
              StepIconProps={{classes: {root: classes.stepIcon}}}
            >
              <div className={classes.sectionHeader}>{t('createUser.assignRoles')}</div>
            </StepLabel>
            <StepContent
              className={classes.stepLine}
              TransitionProps={{mountOnEnter: true, unmountOnExit: true}}
            >
              {userInformation && (
                <Roles
                  userId={userInformation.id}
                  roleAssignments={roles ?? []}
                  getRoles={getRoles}
                  showAutoGeneratedRoles={true}
                />
              )}

              <Button
                color="primary"
                type="submit"
                data-test-id="button-next-step"
                disabled={roles?.length === 0}
                onClick={() => setStep(STEPS.INVITE_USER)}
              >
                {t('createUser.nextStep')}
              </Button>
            </StepContent>
          </Step>
          <Step key="inviteUser">
            <StepLabel
              classes={{label: classes.stepLabel}}
              StepIconProps={{classes: {root: classes.stepIcon}}}
            >
              <div className={classes.sectionHeader}>{headerText}</div>
            </StepLabel>
            <StepContent
              className={classes.stepLine}
              TransitionProps={{mountOnEnter: true, unmountOnExit: true}}
            >
              <InviteUserButtons
                data-test-id="invite-buttons-box"
                user={user || userInformation}
                products={liveProducts}
                refetchUser={refetchUser}
                roles={roles || []}
              />
            </StepContent>
          </Step>
        </Stepper>
        <FormGroup row style={{justifyContent: 'end', paddingTop: '10px'}}>
          <FormControlLabel
            classes={{label: classes.checkboxLabel}}
            control={
              <Checkbox
                checked={createAnotherUser}
                onChange={() => setCreateAnotherUser(!createAnotherUser)}
                className={classes.checkbox}
                data-test-id="checkbox-create-another-user"
              />
            }
            label={t('createUser.createAnotherUser')}
          />
          <Button
            onClick={handleSave}
            color="primary"
            disabled={step !== STEPS.INVITE_USER}
            data-test-id="button-done"
          >
            {t('createUser.done')}
          </Button>
        </FormGroup>
      </Page>
    </Content>
  )
}
