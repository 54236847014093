import {useBreakPoints} from '@hconnect/uikit'
import {Close, Check} from '@mui/icons-material'
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material'
import React, {useState} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CreateMessageFormData} from '../CreateMessage/CreateMessage.form'
import {
  ALLOWED_IMAGE_FORMATS,
  ALLOWED_VIDEO_FORMATS,
  ALLOWED_WORKSHEET_FORMATS,
  UploadFileInput
} from '../CreateMessage/inputs/UploadFileInput'

import {useStyles} from './UploadAttachmentDialog.styles'

type UploadAttachmentDialogProps = {
  showAttachmentDialog: boolean
  setShowAttachmentDialog: (showAttachmentDialog: boolean) => void
  files: File[]
  setFiles: (files: File[]) => void
}

export const UploadAttachmentDialog = ({
  showAttachmentDialog,
  setShowAttachmentDialog,
  files,
  setFiles
}: UploadAttachmentDialogProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [currentFiles, setCurrentFiles] = useState<File[]>([])
  const allowedFormats = [...ALLOWED_IMAGE_FORMATS, ...ALLOWED_WORKSHEET_FORMATS]

  return (
    <Dialog
      data-test-id="upload-attachment-dialog"
      open={showAttachmentDialog}
      onClose={() => setShowAttachmentDialog(false)}
      classes={{paper: classes.paper}}
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{t('createMessage.form.attachments.dialog.title')}</DialogTitle>
        <IconButton
          onClick={() => setShowAttachmentDialog(false)}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: isMobile ? '0 8px' : undefined
        }}
      >
        <DialogContentText>
          {t('createMessage.form.attachments.dialog.content', {
            allowedFileExtensions: [...allowedFormats]
          })}
        </DialogContentText>
        <UploadFileInput
          files={currentFiles}
          setFiles={setCurrentFiles}
          error={
            currentFiles.length > 0 &&
            !allowedFormats.some(
              (allowedFormat) =>
                currentFiles.filter((file) => file.name.toLowerCase().includes(allowedFormat))
                  .length > 0
            )
              ? t('createMessage.form.attachments.dialog.fileTypeError')
              : undefined
          }
        />
      </DialogContent>

      <DialogActions>
        <Box style={{display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center'}}>
          <Button
            onClick={() => setShowAttachmentDialog(false)}
            startIcon={<Close />}
            variant="text"
            style={{color: 'rgba(1, 106, 212, 1)'}}
            data-test-id="upload-attachment-dialog-action-cancel"
          >
            {t('createMessage.form.recipients.cancel')}
          </Button>
          <Button
            onClick={() => {
              setFiles([...files, ...currentFiles])
              setShowAttachmentDialog(false)
            }}
            className={classes.confirmButton}
            startIcon={<Check />}
            data-test-id="upload-attachment-dialog-confirm-button"
            variant="contained"
            disabled={
              currentFiles.length > 0 &&
              !allowedFormats.some(
                (allowedFormat) =>
                  currentFiles.filter((file) => file.name.toLowerCase().includes(allowedFormat))
                    .length > 0
              )
            }
          >
            {t('createMessage.form.attachments.dialog.continue')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
