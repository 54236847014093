export enum Products {
  Hub = 'Hub',
  OnSite = 'OnSite'
}

export type ProductsType = keyof typeof Products

export enum MessageTypes {
  information = 'information',
  warning = 'warning',
  maintenance = 'maintenance'
}

export enum ScheduleDays {
  zeroDays = 'zero_days',
  oneDay = 'one_day',
  threeDays = 'three_days',
  fiveDays = 'fiveDays',
  sevenDays = 'sevenDays'
}

export type MessageTypesType = keyof typeof MessageTypes
