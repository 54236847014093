import {TableColumnType, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import {Box} from '@mui/material'
import moment from 'moment/moment'
import React from 'react'

import {Products} from '../../../components/Products/Products'

import {Audience} from './Audiences'

export const AudiencesListRows = () => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isExtraLarge = ['xl'].includes(screenSizes)
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const rowsPool: {[key: string]: TableColumnType} = {
    name: {
      field: 'name',
      mobileRowHeader: true,
      sortable: true,
      filterable: true,
      headerName: t('audiences.name'),
      headerAlign: 'left',
      renderCell: (audience: Audience) => (
        <Box style={{fontSize: '14px', overflowWrap: 'anywhere'}}>{audience.name}</Box>
      )
    },
    filters: {
      field: 'filters',
      headerName: t('audiences.filters'),
      headerAlign: 'left',
      renderCell: (audience: Audience) => {
        const parsedFilters = JSON.parse(audience.filters)
        const formattedFilters = Object.keys(parsedFilters).map(
          (filterKey) => `${filterKey}: ${parsedFilters[filterKey]}`
        )
        return <Products products={formattedFilters} singleColumn={isMobile} />
      },
      filterShowSearch: false
    },
    createdAt: {
      field: 'createdOn',
      headerName: t('audiences.createdAt'),
      headerAlign: 'left',
      filterable: true,
      sortable: true,
      filterType: TableFilterTypeProps.DATEPICKER,
      renderCell: (audience: Audience) => {
        return (
          <Box style={{fontSize: '14px'}}>{moment(audience.createdOn).format('DD/MM/YYYY')}</Box>
        )
      }
    },
    createdBy: {
      field: 'createdBy',
      headerName: t('audiences.createdBy'),
      headerAlign: 'left',
      renderCell: (audience: Audience) => {
        return <Box style={{fontSize: '14px'}}>{audience.createdBy}</Box>
      }
    },
    expander: {
      field: 'expander',
      noColumnSelection: true,
      renderCell: () => {
        return <Box style={{width: '100vw'}} />
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.name,
    rowsPool.filters,
    rowsPool.createdAt,
    rowsPool.createdBy,
    ...(isExtraLarge ? [rowsPool.expander] : [])
  ]
  return rowOrder(rowsPool)
}
