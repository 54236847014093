import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  addIcon: {
    marginRight: theme.spacing(1)
  },
  createButton: {
    marginBottom: '8px',
    background: 'rgba(255, 255, 255, 0.05)',
    fontWeight: 500,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: '12px'
    }
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '22px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
}))
