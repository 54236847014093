import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../../../../App.store'

import {Attachment} from './useMessages'

type AudienceDetails = {
  id: number
  name: string
  numberOfRecipients: number
}

export type MessageDetailsResponse = {
  id: number
  subject: string
  body: string
  type: 'email' | 'sms'
  audiences: AudienceDetails[]
  recipientsNames: string[]
  recipientCount: string
  createdBy: string
  sentDate: string
  attachments?: Attachment[]
}

type MessageDetailsQueryKey = [string, {id: number}]

const messageDetailsFetcher: QueryFunction<MessageDetailsResponse> = async ({queryKey}) => {
  const [base, {id}] = queryKey as MessageDetailsQueryKey
  try {
    const response = await api.get<MessageDetailsResponse>(`/notifications/${base}?id=${id}`)
    return response.data
  } catch (e) {
    throw new Error('Error during fetching message details')
  }
}
export const useMessageDetails = (id?: number, enabled?: boolean) => {
  return useQuery(['message', {id}], messageDetailsFetcher, {enabled})
}
