import {Country} from '@hconnect/apiclient'
import {TableColumnType, Typography, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import {Box} from '@mui/material'
import moment from 'moment'
import React from 'react'

import {countryDataByCountryCode} from '../../common/utils/utils'
import {Products} from '../../components/Products/Products'

import {Announcement} from './Announcements'
import {useStyles} from './AnnouncementsListRows.styles'
import {MessageTypes} from './common/types'
import {BusinessLines, Countries, IsActive, Pillar} from './components'

type AnnouncementsListRowsProps = {
  countries?: Country[]
}

export const AnnouncementsListRows = ({countries}: AnnouncementsListRowsProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isExtraLarge = ['xl'].includes(screenSizes)
  const countriesData = window.intlTelInputGlobals.getCountryData()

  const countryDataFilter = (countryId?: string | null) =>
    countryDataByCountryCode(countriesData, countryId)

  const rowsPool: {[key: string]: TableColumnType} = {
    creationDate: {
      field: 'creationDate',
      mobileRowHeader: true,
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DATEPICKER,
      headerName: t('announcements.createdDate'),
      headerAlign: 'left',
      renderCell: (announcement: Announcement) => (
        <Box style={{fontSize: '14px'}}>{moment(announcement.createdOn).format('DD/MM/YYYY')}</Box>
      )
    },
    type: {
      field: 'messageType',
      sortable: true,
      filterName: t('announcements.type'),
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: t('announcements.information'), value: MessageTypes.information},
        {label: t('announcements.warning'), value: MessageTypes.warning},
        {label: t('announcements.maintenance'), value: MessageTypes.maintenance}
      ],
      headerName: t('announcements.typeAndMessage'),
      headerAlign: 'left',
      renderCell: (announcement: Announcement) => (
        <Box display="flex" flexDirection="column" maxWidth={160}>
          <Typography>{t(`announcements.${announcement.messageType.toLowerCase()}`)}</Typography>
          <Box mx={1} />
          <Typography variant="caption" className={classes.messageOverflow}>
            {announcement.message}
          </Typography>
        </Box>
      ),
      filterShowSearch: false
    },
    validation: {
      field: 'validation',
      headerName: t('announcements.validation'),
      headerAlign: 'left',
      renderCell: (announcement: Announcement) => {
        return (
          <Box display="flex" flexDirection="column">
            <IsActive validFrom={announcement.validFrom} validTo={announcement.validTo} t={t} />
          </Box>
        )
      }
    },
    scheduled: {
      field: 'validFrom',
      filterDatePickerRangeOptions: {validToField: 'validTo', validFromField: 'validFrom'},
      headerName: t('announcements.scheduled'),
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DATEPICKER_RANGE,
      renderCell: (announcement: Announcement) => {
        return (
          <Box style={{whiteSpace: 'nowrap', fontSize: '14px'}}>
            <span>{moment(announcement.validFrom).format('DD/MM/YYYY')}</span> &#8211; <br />
            <span>{moment(announcement.validTo).format('DD/MM/YYYY')}</span>
          </Box>
        )
      }
    },
    pillar: {
      field: 'pillarType',
      headerName: t('announcements.pillar'),
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: 'HConnect', value: 'HConnect'},
        {label: 'HProduce', value: 'HProduce'}
      ],
      filterShowSearch: false,
      renderCell: (announcement: Announcement) => {
        return announcement.pillarType ? <Pillar pillarType={announcement.pillarType} /> : '-'
      }
    },
    product: {
      field: 'product',
      headerName: t('announcements.products'),
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: 'Hub', value: 'Hub'},
        {label: 'OnSite', value: 'OnSite'},
        {label: 'MHC', value: 'MHC'},
        {label: 'Planner', value: 'Planner'},
        {label: 'Cockpit', value: 'Cockpit'},
        {label: 'Predict', value: 'Predict'},
        {label: 'Maintain', value: 'Maintain'}
      ],
      filterMultiSelect: true,
      renderCell: (announcement: Announcement) => {
        return <Products products={announcement.products} />
      }
    },
    countries: {
      field: 'country',
      headerName: t('announcements.countries'),
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: countries
        ? countries.map(({countryName, countryCode}) => ({
            label: countryDataFilter(countryCode)?.name ?? countryName,
            value: countryCode
          }))
        : [],
      renderCell: (announcement: Announcement) => (
        <Countries announcement={announcement} countries={countries} />
      ),
      filterMultiSelect: true
    },
    businessLines: {
      field: 'businessLine',
      headerName: t('announcements.businessLines'),
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: 'RMC', value: 'RMC'},
        {label: 'Cement', value: 'CEM'},
        {label: 'Aggregate', value: 'AGG'}
      ],
      renderCell: (announcement: Announcement) => {
        return <BusinessLines businessLines={announcement.businessLines} t={t} />
      },
      filterMultiSelect: true,
      filterShowSearch: false
    },
    expander: {
      field: 'expander',
      noColumnSelection: true,
      renderCell: () => {
        return <Box style={{width: '100vw'}} />
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.creationDate,
    rowsPool.type,
    rowsPool.validation,
    rowsPool.scheduled,
    rowsPool.pillar,
    rowsPool.product,
    rowsPool.countries,
    rowsPool.businessLines,
    ...(isExtraLarge ? [rowsPool.expander] : [])
  ]
  return rowOrder(rowsPool)
}
