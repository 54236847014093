import {Product, User} from '@hconnect/apiclient'
import {Box} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import React, {useEffect, useState} from 'react'

import LoadingButton from '../../components/LoadingButton'

import {useInviteUser} from './hooks/useInviteUser'

interface Props {
  user?: User | null
  refetchUser?: () => void
  variant?: 'text' | 'outlined' | 'contained'
  'data-test-id': string
  key: number
  product: Product
  isInviting: boolean
  setIsInviting: (inviting: boolean) => void
}

export const hasPassword = (user: User | null): boolean => {
  if (hasLoginDate(user)) return true
  // If the invitation is not required, the user will always have a password
  // If the user is invited, the password would be sent in the invitation
  return !!user?.isInvited
}

export const hasLoginDate = (user: User | null): boolean => {
  return !!user?.lastLoginDate && user?.lastLoginDate !== '0001-01-01'
}

export const InviteUserButton: React.FC<Props> = ({
  user,
  refetchUser,
  variant,
  'data-test-id': dataTestId,
  key,
  product,
  isInviting,
  setIsInviting
}) => {
  const [loading, setLoading] = useState(false)
  const [alreadyInvited, setAlreadyInvited] = useState(false)
  const [isPolling, setIsPolling] = useState(false)
  const {mutate, isLoading: isLoadingInvite} = useInviteUser(
    product,
    setAlreadyInvited,
    setIsPolling,
    user,
    refetchUser
  )

  useEffect(() => {
    setAlreadyInvited(!!user?.invitationProducts?.some((p) => p.product === product))
  }, [product, user?.invitationProducts])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined

    if (isPolling) {
      setLoading(true)
      setIsInviting(true)
      timer = setInterval(() => {
        user && mutate({user, product})
      }, 3000)
    }

    if (!isPolling) {
      setIsInviting(false)
      setLoading(false)
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [isPolling])

  if (!user) {
    return null
  }

  return (
    <Box marginRight="16px" key={key}>
      {
        <LoadingButton
          loading={loading || isLoadingInvite}
          startIcon={
            loading ? (
              ''
            ) : alreadyInvited ? (
              <CheckIcon
                style={{
                  marginLeft: '-10px',
                  marginTop: '-3px',
                  color: 'white',
                  fontSize: '29px'
                }}
              />
            ) : (
              <AddIcon
                style={{
                  marginLeft: '-15px',
                  marginTop: '-3px',
                  color: 'white',
                  fontSize: '29px'
                }}
              />
            )
          }
          variant={variant}
          key={key}
          color="primary"
          type="submit"
          onClick={() => mutate({user, product})}
          data-test-id={`${dataTestId}-button-invite-to-${product}`}
          disabled={alreadyInvited || isInviting}
        >
          {product}
        </LoadingButton>
      }
    </Box>
  )
}

export default InviteUserButton
