import {FilePresent, DeleteOutlined} from '@mui/icons-material'
import {Box, Button, Chip, Typography} from '@mui/material'
import React, {ChangeEvent} from 'react'

import {useStyles} from './UploadFileInput.styles'

export const ALLOWED_IMAGE_FORMATS = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg']
export const ALLOWED_WORKSHEET_FORMATS = ['.xlsx', '.xls', '.csv', '.odt', '.pdf', '.xml']
export const ALLOWED_VIDEO_FORMATS = ['.mp4', '.3gp', '.mov', '.avi']

type UploadFileInputProps = {
  files: File[]
  setFiles: (files: File[]) => void
  error?: string
}
export const UploadFileInput = ({files, setFiles, error}: UploadFileInputProps) => {
  const {classes} = useStyles()
  return (
    <Box
      style={{
        border: '2px dashed #D1D9E0',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        gap: '8px'
      }}
      onDragOver={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
      onDrop={(event) => {
        event.preventDefault()
        event.stopPropagation()
        const droppedFile = Array.from(event.dataTransfer.files)
        setFiles(droppedFile)
      }}
    >
      {files.length === 0 && (
        <>
          <Button component="label" htmlFor="attachmentInput">
            Upload a file
          </Button>
          <Typography>or drag & drop</Typography>
          <Box
            component="input"
            type="file"
            style={{display: 'none'}}
            id="attachmentInput"
            multiple
            accept={[
              ...ALLOWED_IMAGE_FORMATS,
              ...ALLOWED_WORKSHEET_FORMATS,
              ...ALLOWED_VIDEO_FORMATS
            ].join(',')}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const droppedFile = event.target.files ? Array.from(event.target.files) : []
              setFiles(droppedFile)
            }}
          ></Box>
        </>
      )}
      {files.length > 0 && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <Box style={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
            {files.map((file) => (
              <Chip
                key={file.name}
                label={file.name}
                style={{
                  color: '#52667A',
                  fontSize: '12px',
                  fontWeight: 500,
                  border: '1px solid #E8ECF0',
                  backgroundColor: '#F7F9FA',
                  padding: '4px',
                  textOverflow: 'ellipsis'
                }}
                icon={<FilePresent classes={{root: classes.iconRoot}} />}
                onDelete={() =>
                  setFiles(files.filter((selectedFile) => selectedFile.name !== file.name))
                }
                deleteIcon={
                  <DeleteOutlined
                    classes={{root: classes.iconRoot}}
                    style={{color: 'rgba(82, 102, 122, 1)'}}
                  />
                }
              />
            ))}
          </Box>
          {error && (
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
