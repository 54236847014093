import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {useQueryParamState} from '../../../common/QueryParamStateHook'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {AudienceDetails} from '../../UsersList/AudienceDetails'
import {AudienceDetailsResponse} from '../../UsersList/hooks/useAudience'
import {useAudienceListFetcher} from '../../UsersList/hooks/useAudienceListFetcher'
import {Audience} from '../Audiences/Audiences'
import {AudiencesListRows} from '../Audiences/AudiencesListRows'
import {useAudiencesFilter} from '../Audiences/hooks/useAudiencesFilter'

export type AudiencesFilter = {
  rowsPerPage: number
  page: number
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
  name: string
  createdOn: string
}

type AudienceListProps = {
  selectedAudiencesIds: number[]
  setSelectedAudienceIds: (audiences: number[]) => void
  audienceDetailsData?: AudienceDetailsResponse
  selectedAudienceDetails?: Audience
  setSelectedAudienceDetails?: (audienceDetails: Audience | undefined) => void
  shouldEvaluateAudiences?: boolean
  setShouldEvaluateAudiences?: (shouldEvaluate: boolean) => void
  shouldCloseDialog?: boolean
  setShouldCloseDialog?: (shouldClose: boolean) => void
  setShowRecipients?: (showRecipients: boolean) => void
  isLoadingAdditionalDetails?: boolean
  isRecipientsDialog?: boolean
}
export const AudienceList = ({
  audienceDetailsData,
  selectedAudienceDetails,
  setSelectedAudienceDetails,
  selectedAudiencesIds,
  setSelectedAudienceIds,
  shouldEvaluateAudiences,
  setShouldEvaluateAudiences,
  shouldCloseDialog,
  setShouldCloseDialog,
  setShowRecipients,
  isLoadingAdditionalDetails,
  isRecipientsDialog
}: AudienceListProps) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const loggedInUserId = loggedInUserProfile?.user_id
  const location = useLocation<{selectedAudiencesIds: number[]} | undefined>()
  const [shouldSetInitialAudiences, setShouldSetInitialAudiences] = useState(true)
  const [initialTempAudiencesIds, setInitialTempAudiencesIds] = useState<number[]>([])
  const [tempAudiencesIds, setTempAudiencesIds] = useState<number[]>([])
  const [filter, setFilter] = useQueryParamState<AudiencesFilter>({
    rowsPerPage: 10,
    page: 1,
    sortedByKey: 'createdOn',
    sortedByDirection: 'desc'
  })

  useEffect(() => {
    if (location.state?.selectedAudiencesIds) {
      setSelectedAudienceIds(location.state?.selectedAudiencesIds)
      setShouldEvaluateAudiences?.(true)
    }
  }, [location])

  const {
    data: audienceData,
    isLoading: isLoadingAudienceData,
    error: audiencesError
  } = useAudienceListFetcher(filter)

  const handleCloseAudience = () => setSelectedAudienceDetails?.(undefined)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setShouldEvaluateAudiences?.(true)
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldEvaluateAudiences?.(true)
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const setSortKey = (sortKey: string) => {
    setShouldEvaluateAudiences?.(true)
    const asc = !(filter.sortedByKey === sortKey && filter.sortedByDirection === 'asc')
    setFilter({
      ...filter,
      sortedByKey: sortKey,
      sortedByDirection: asc ? 'asc' : 'desc'
    })
  }

  const {filterInputValues, setFilterInputValues, handleFilterInputValues, setRemovedFilter} =
    useAudiencesFilter(filter, setFilter)

  const totalPageCount = audienceData?.total || 0

  const formatErrorMessage = () => {
    if (!isLoadingAudienceData) {
      if (audiencesError?.response?.status === 404) {
        return t('audiences.noResults')
      } else if (audiencesError) {
        return t('audiences.error')
      }
    }
  }

  const formatAudienceFilters = () => {
    if (audienceDetailsData?.filters) {
      const parsedFilters = JSON.parse(audienceDetailsData.filters)
      return Object.keys(parsedFilters).map((filterKey) => ({
        name: filterKey || '',
        value: parsedFilters[filterKey] || '',
        filterName: filterKey || ''
      }))
    } else return []
  }

  useEffect(() => {
    if (shouldEvaluateAudiences) {
      const deselectedElements = initialTempAudiencesIds.filter(
        (audienceId) => !tempAudiencesIds.includes(audienceId)
      )
      const mergedAudiencesIds = selectedAudiencesIds.concat(tempAudiencesIds)
      const uniqueAudiences = Array.from(new Set(mergedAudiencesIds))
        .map((audienceId) =>
          mergedAudiencesIds.find((mergedAudienceId) => mergedAudienceId === audienceId)
        )
        .filter(
          (audienceId) => audienceId !== undefined && !deselectedElements.includes(audienceId)
        ) as number[]

      setSelectedAudienceIds(uniqueAudiences)
      setShouldEvaluateAudiences?.(false)
      setShouldSetInitialAudiences(true)
      shouldCloseDialog && setShowRecipients?.(false)
    }
  }, [shouldEvaluateAudiences])

  useEffect(() => {
    if (!isLoadingAudienceData && shouldSetInitialAudiences && tempAudiencesIds.length > 0) {
      setInitialTempAudiencesIds(tempAudiencesIds)
      setShouldSetInitialAudiences(false)
    }
  }, [tempAudiencesIds])

  useEffect(() => {
    setInitialTempAudiencesIds([])
  }, [filter.page])

  return (
    <ResponsiveTable
      keyField="id"
      columns={AudiencesListRows()}
      rows={audienceData?.audiences || []}
      onSort={(e, sortKey) => setSortKey(sortKey)}
      sortedBy={filter.sortedByKey}
      sortingOrder={filter.sortedByDirection}
      emptyMessage={formatErrorMessage()}
      loading={isLoadingAudienceData}
      isMobile={isMobile}
      mobileRowDetails={
        selectedAudienceDetails && (
          <AudienceDetails
            filters={formatAudienceFilters()}
            handleClose={handleCloseAudience}
            audience={selectedAudienceDetails}
            isLoadingAdditionalDetails={isLoadingAdditionalDetails}
            additionalDetailsData={audienceDetailsData}
            setShouldEvaluateAudiences={setShouldEvaluateAudiences}
            setShouldCloseDialog={setShouldCloseDialog}
          />
        )
      }
      selectionEnabled={true}
      preselectedRows={{
        rows:
          audienceData?.audiences.filter((audience) =>
            selectedAudiencesIds.includes(audience.id)
          ) || []
      }}
      isRowSelectable={() => true}
      getSelectedRows={(rows) => {
        const rowIds = rows.map((row) => row.id)
        setTempAudiencesIds([...rowIds])
      }}
      onRowClick={(audience) => {
        if (audience.id === selectedAudienceDetails?.id) {
          setSelectedAudienceDetails?.(undefined)
        } else {
          setSelectedAudienceDetails?.(audience)
        }
      }}
      page={filter.page}
      rowsPerPage={filter.rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangePage={handleChangePage}
      count={totalPageCount}
      enableHorizontalScroll={true}
      enableColumnSelection={true}
      rowDetailsAvailable={true}
      tableHeight={isRecipientsDialog ? '50vh' : 'auto'}
      filterListInputValues={filterInputValues}
      setFilterListInputValues={setFilterInputValues}
      onFilterInputChange={(props: TableFilterItemProps) => handleFilterInputValues(props)}
      onFilterRemove={(filter: TableFilterType) => {
        setRemovedFilter(filter)
        setFilterInputValues(
          filterInputValues.filter((inputValue) => {
            return inputValue.filterField !== filter.filterField
          })
        )
      }}
      storeFiltersOptions={
        loggedInUserId ? {userId: loggedInUserId, tableName: 'audiences'} : undefined
      }
      stickyHeader={true}
      enablePinning={true}
    />
  )
}
