import {Product, User} from '@hconnect/apiclient'
import {QueryObserverResult} from '@tanstack/react-query'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {issuesForUser, UserIssue} from '../../../components/UserInformation/utils/utils'
import {UserIssueBar} from '../UserIssueBar'
import {UserIssueButtonBar} from '../UserIssueButtonBar'

type ManageUserIssuesProps = {
  user: User
  accountNumber: string | null
  extraInfo: string | null
  liveProducts: Product[]
  refetchUser: () => Promise<QueryObserverResult<User | undefined>>
}
export const ManageUserIssues = ({
  user,
  accountNumber,
  extraInfo,
  liveProducts,
  refetchUser
}: ManageUserIssuesProps) => {
  const {t} = useTranslation()
  return (
    <>
      {issuesForUser(user) &&
        issuesForUser(user).map((issueKey) => {
          const extraLines: Array<{title: string; description: string}> = []
          if (issueKey === UserIssue.USER_HAS_NO_ROLES) {
            if (accountNumber) {
              extraLines.push({
                title: t('manageUser.issuesBar.userHasNoRoles.accountNumber'),
                description: accountNumber
              })
            }
            if (extraInfo) {
              extraLines.push({
                title: t('manageUser.issuesBar.userHasNoRoles.extraInfo'),
                description: extraInfo
              })
            }
            if (user.creationProduct) {
              extraLines.push({
                title: t('manageUser.issuesBar.requestedAccessTo'),
                description: user.creationProduct
              })
            }
          }
          if (issueKey === UserIssue.USER_HAS_NEVER_RECEIVED_PASSWORD) {
            return (
              <>
                <UserIssueBar
                  key={issueKey}
                  action={t('manageUser.inviteNow')}
                  info={t('manageUser.noInviteYet')}
                  extraLines={extraLines}
                />
                <UserIssueButtonBar user={user} products={liveProducts} refetchUser={refetchUser} />
              </>
            )
          }
          return (
            <UserIssueBar
              key={issueKey}
              action={t(`manageUser.issuesBar.${issueKey}.action`)}
              info={t(`manageUser.issuesBar.${issueKey}.info`)}
              extraLines={extraLines}
            />
          )
        })}
    </>
  )
}
