import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'

type WidgetUserListDetailInputProps = {
  label: string
  value: string | number
}
export const WidgetUserListDetailInput = ({label, value}: WidgetUserListDetailInputProps) => {
  return (
    <Box style={{display: 'flex', gap: '6px'}}>
      <Typography style={{fontSize: '14px', fontWeight: 500, lineHeight: '18px', color: '#4E565E'}}>
        {label}
      </Typography>
      <Typography style={{fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#000000'}}>
        {value}
      </Typography>
    </Box>
  )
}
