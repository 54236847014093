import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  accordionSummary: {
    paddingLeft: '8px',
    marginLeft: '-10px',
    marginRight: '-10px',
    marginBottom: '2px',
    '& .Mui-expanded': {
      margin: '12px 0 !important'
    }
  },
  constraintTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#D9D9D9',
    width: '24px',
    height: '24px',
    fontWeight: 500
  },
  constraintDeleteButton: {
    boxShadow: 'none',
    color: '#016AD4',
    outline: 'none',
    border: 'none',
    fontSize: '16px',
    fontWeight: 500,
    marginRight: '-24px'
  },
  autocomplete: {
    paddingTop: '10px',
    borderRadius: '4px',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    '.MuiIconButton-root': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none'
    },
    '.MuiOutlinedInput-input': {
      padding: '0 16px'
    }
  }
}))
