import {LoadingButton} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CreateMessageFormData} from '../CreateMessage/CreateMessage.form'

type SendMessageConfirmationDialogProps = {
  isLoading: boolean
  handleSendMessage: (data: CreateMessageFormData) => void
  channelType: 'Email' | 'sms'
  isTestMessage: boolean
  setShowDialog: (show?: CreateMessageFormData) => void
  showDialog?: CreateMessageFormData
  totalCost?: number
}

export const SendMessageConfirmationDialog = ({
  showDialog,
  setShowDialog,
  handleSendMessage,
  isLoading,
  channelType,
  totalCost,
  isTestMessage
}: SendMessageConfirmationDialogProps) => {
  const {t} = useTranslation()

  return (
    <Dialog
      data-test-id="send-message-confirmation-dialog"
      open={!!showDialog}
      onClose={() => setShowDialog(undefined)}
    >
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{t('createMessage.summary.confirmation.dialogTitle')}</DialogTitle>
        <IconButton
          onClick={() => setShowDialog(undefined)}
          style={{border: 'none', boxShadow: 'none', marginRight: '10px'}}
          data-test-id="modal-close-button"
        >
          <Close fontSize="medium" />
        </IconButton>
      </Box>
      <DialogContent style={{width: '400px'}}>
        <DialogContentText>
          {channelType === 'Email'
            ? isTestMessage
              ? t('createMessage.summary.confirmation.emailDialogTestMessage')
              : t('createMessage.summary.confirmation.emailDialogMessage')
            : isTestMessage
              ? t('createMessage.summary.confirmation.smsDialogTestMessage')
              : t('createMessage.summary.confirmation.smsDialogMessage', {totalCost})}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setShowDialog(undefined)}
          variant="text"
          color="primary"
          style={{color: '#016AD4'}}
          data-test-id="send-message-confirmation-action-cancel"
        >
          {t('createMessage.summary.confirmation.dialogActionCancel')}
        </Button>
        <LoadingButton
          onClick={() => showDialog && handleSendMessage(showDialog)}
          loading={isLoading}
          variant="contained"
          color="primary"
          style={{
            textTransform: 'none',
            padding: '13px 16px',
            whiteSpace: 'nowrap',
            background: '#016AD4',
            color: '#FFFFFF',
            width: '100%'
          }}
          data-test-id="send-message-confirmation-action-confirm"
        >
          {isTestMessage
            ? t('createMessage.summary.confirmation.dialogActionConfirmTest')
            : t('createMessage.summary.confirmation.dialogActionConfirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
