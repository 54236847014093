import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  addUserButton: {
    background: 'rgba(255, 255, 255, 0.05)',
    fontWeight: 500,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    marginRight: '5px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '12px'
    }
  },

  addCompanyButton: {
    background: 'rgba(255, 255, 255, 0.05)',
    fontWeight: 500,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '12px'
    }
  },

  backButton: {
    flex: '1 0 100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  pageNav: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.6,
    marginBottom: '-12px',
    marginLeft: '-12px'
  },
  pageAboveTitle: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '24px'
    }
  }
}))
